import { injectable } from "inversify";
import BaseMapper, { MapperBase } from "../../mappers/BaseMapper";
import ArkSurvivorSheetMapper from "../../mappers/survivorSheets/ArkSurvivorSheetMapper";
import CoreSurvivorSheetMapper from "../../mappers/survivorSheets/CoreSurvivorSheetMapper";
import { SurvivorType } from "../../models/survivors/SurvivorType";
import { ISurvivorModelMapperService } from "../ISurvivorModelMapperService";
import PotStarSurvivorSheetMapper from "../../mappers/survivorSheets/PoTStarSurvivorSheetMapper";
import PoTSunSurvivorSheetMapper from "../../mappers/survivorSheets/PoTSunSurvivorSheetMapper";

@injectable()
export default class SurvivorModelMapperService implements ISurvivorModelMapperService {
    private mappers : Map<SurvivorType, MapperBase>;

    constructor(){
        this.mappers = new Map<SurvivorType,MapperBase>();
        this.mappers.set(SurvivorType.Core, new CoreSurvivorSheetMapper());
        this.mappers.set(SurvivorType.Ark, new ArkSurvivorSheetMapper());
        this.mappers.set(SurvivorType.PoTStar, new PotStarSurvivorSheetMapper())
        this.mappers.set(SurvivorType.PoTSun, new PoTSunSurvivorSheetMapper())
    }

    mapFromModel<Model,State>(from : any, type:SurvivorType) : State | null{
        const mapper =  this.mappers.get(type)
        if(mapper){
            return (mapper as BaseMapper<Model,State>).mapToState(from as Model);
        }
        return null;
    }

    mapFromState<Model,State>(from : State, type:SurvivorType) : Model | null{
        const mapper =  this.mappers.get(type)
        if(mapper){
            return (mapper as BaseMapper<Model,State>).mapFromState(from);
        }
        return null;
    }
} 