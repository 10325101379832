import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useSearchParams, useNavigate } from 'react-router-dom';
import { RootState } from '../../redux/store';
import { connect } from 'react-redux';
import ProtectedPage from '../../components/protected-page/protected-page';
import CustomerCPPage from '../customer-cp-page/CustomerCPPage';
import { resolve, useInjection } from 'inversify-react';
import type { IAuthService } from '../../services/IAuthService';
import IUIService from '../../services/IUIService';
import ITransService from '../../services/ITransService';
import InvalidQRPage from '../invalid-qr-page/InvalidQRPage';
import ShopifyAuthorizeCode from '../../components/customer-cp/customer-account/account-linking/shopify/shopify-authorize-code-component';

interface PageWithProps {
    isAuthorized: boolean;
    accessLevel: number[];
}

class PageWithHeader extends React.Component<PageWithProps, {}> {
    @resolve('IAuthService')
    private authService!: IAuthService;

    componentDidMount() {
        this.authService.initFromCookies();
    }

    render() {
        const { isAuthorized } = this.props;
        return (
            <React.Fragment>
                <BrowserRouter>
                    <RoutesComponent isAuthorized={isAuthorized}/>
                </BrowserRouter>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state: RootState) => ({
    isAuthorized: state.auth.jwtToken !== null,
    accessLevel: state.auth.dataAccess,
});

function RoutesComponent({ isAuthorized }: { isAuthorized: boolean }) {
    
    let [searchParams] = useSearchParams();
    const uiService = useInjection<IUIService>('IUIService')
    const transService = useInjection<ITransService>('ITransService')
    const authService = useInjection<IAuthService>('IAuthService');
    const navigate = useNavigate();

    const key = searchParams.get('invite');

    useEffect(()=>{

        if(!!key){
            uiService.showLoadingWithText('Processing...');
    
            transService.validateProductKey(key).then(valid=>{
                if(!!valid){
                    if(!!isAuthorized || authService.initFromCookies()){
                        uiService.showConfirmationDialog('Do you want to activate the product key?').then((confirm)=>{
                            console.log(confirm);
                            if(!!confirm){
                                transService.processProductKey(key).then(x=>{
                                    if(!!x){
                                        uiService.hideLoading();
                                        navigate('/account?from=qrCode#account');
                                    }
                                })
                            }
                            else{
                                navigate('/account#account');
                            }
                           
                        })
                        
                    } else{
                        window.location.replace(`https://auth.kingdomdeath.com/register?invite=${key}`);
                        uiService.showLoadingWithText('Redirecting...');
                    }
                }
                else{
                    console.log('Invalid QR Code');
                    uiService.hideLoading();
                    navigate('/invalid-qr-code')
                }
                
            });
        }
    },[])

    

    return (
        <Routes>
            <Route
                element={
                    <ProtectedPage
                        children={<CustomerCPPage productKey={null}/>}
                        isLoggedIn={isAuthorized}
                        requireLogin
                    />
                }
                path="/account"
            />
            <Route element={<InvalidQRPage/>} path='/invalid-qr-code'/>
            <Route element={<ShopifyAuthorizeCode/>} path='/shopifyAuthorizeCode'/>
            <Route index element={<Navigate to="/account" replace />} />
        </Routes>
    );
}

export default connect(mapStateToProps)(PageWithHeader);
