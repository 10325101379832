import axios, { AxiosError } from 'axios';
import { inject, injectable } from 'inversify';
import { CoreSettlementSheetModel } from '../../models/settlements/CoreSettlementSheetModel';
import CoreSurvivor from '../../models/survivors/CoreSurvivor';
import SurvivorList from '../../models/survivors/SurvivorList';
import SettlementList from '../../models/settlements/SettlementList';
import { ErrorMessage } from '../../../../models/ErrorMessage';
import BaseSettlementSheetModel from '../../models/settlements/BaseSettlementSheetModel';
import BaseSurvivor from '../../models/survivors/BaseSurvivor';
import { SettlementType } from '../../models/settlements/SettlementType';
import { SurvivorType } from '../../models/survivors/SurvivorType';
import { RESTClient } from '../../../../rest-clients/Implementations/RESTClient';
import { IRecordSheetRESTClient } from '../IRecordSheetRESTClient';
import type { IAuthService } from '../../../../services/IAuthService';
import IDPayload from '../../../../models/IDPayload';
import ClientOnly from '../../utils/clientOnly';

@injectable()
export default class RecordSheetRESTClient
    extends RESTClient
    implements IRecordSheetRESTClient
{
    @inject('IAuthService')
    private readonly authService!: IAuthService;

    private readonly base2Url =
        'https://wod-dod-uux56memxa-uc.a.run.app/api/v2/';

    // private readonly base2Url =
    //     'https://localhost:44384/api/v2/';

    constructor() {
        super();
        this.setBaseURL('https://wod-dod-uux56memxa-uc.a.run.app/api/v1/');
        //this.setBaseURL('https://localhost:44384/api/v1/');

    }

    async getSettlementList(page: number): Promise<SettlementList | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const dodToken = await this.getToken(token);

        const { clientOnly } = ClientOnly();

        let path = '';
        if (clientOnly) {
            const iss = await this.authService.getISS();

            if (!iss) return null;

            path = this.getFullURL(`${iss}/settlement?page=${page}`);
        } else {
            path = this.getFullURL(`settlement?page=${page}`);
        }

        try {
            const response = await axios.get(path, this.getHeader(dodToken));
            return response.data as SettlementList;
        } catch {
            return null;
        }
    }

    async getSettlementSheet(
        id: number
    ): Promise<BaseSettlementSheetModel | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const dodToken = await this.getToken(token);

        const path = this.getFullURLV2(`settlement/${id}`);
        console.log(path);

        try {
            const response = await axios.get(path, this.getHeader(dodToken));
            return response.data as BaseSettlementSheetModel;
        } catch {
            return null;
        }
    }

    async createNewSettlement(
        name: string,
        type: SettlementType
    ): Promise<number | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const dodToken = await this.getToken(token);

        const path = this.getFullURLV2(`settlement`);

        const settlement: BaseSettlementSheetModel = {
            settlement_name: name,
            settlement_type: type,
        };

        try {
            const response = await axios.post(
                path,
                JSON.stringify(settlement),
                this.getHeaderJson(this.getHeader(dodToken))
            );

            const responsePayload = response.data as IDPayload;

            return responsePayload.id;
        } catch {
            return null;
        }
    }

    async deleteSettlement(id: number): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const dodToken = await this.getToken(token);

        const path = this.getFullURL(`settlement/${id}`);

        try {
            await axios.delete(path, this.getHeader(dodToken));
            return true;
        } catch {
            return false;
        }
    }

    async updateSettlementSheet(
        sheetModel: CoreSettlementSheetModel
    ): Promise<ErrorMessage> {
        const token = await this.authService.getToken();
        if (token === null) return { value: 'Unable to authorize the user!' };

        const dodToken = await this.getToken(token);

        const path = this.getFullURLV2(`settlement`);

        try {
            const json = JSON.stringify(sheetModel);

            await axios.post(
                path,
                json,
                this.getHeaderJson(this.getHeader(dodToken))
            );

            return null;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                if (axiosError.code === '409')
                    return {
                        value: 'Someone updated the settlement sheet!',
                        statusCode: 409,
                    };
            }
            return {
                value: 'Failed to update the settlement sheet!',
                statusCode: -1,
            };
        }
    }

    async getSurvivorList(
        sheetId: number,
        page: number,
        from: number,
        to: number,
        itemsPerPage: number
    ): Promise<SurvivorList | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const dodToken = await this.getToken(token);

        const path = this.getFullURLV2(
            `settlement/${sheetId}/survivor`,
            new Map<string, string>([
                ['page', page.toString()],
                ['from', from.toString()],
                ['to', to.toString()],
                ['itemsPerPage', itemsPerPage.toString()],
            ])
        );

        try {
            const response = await axios.get(path, this.getHeader(dodToken));
            return response.data as SurvivorList;
        } catch {
            return null;
        }
    }

    async getSurvivorSheet(survivorId: number): Promise<CoreSurvivor | null> {
        const token = await this.authService.getToken();
        if (token === null) return null;

        const dodToken = await this.getToken(token);
        const path = this.getFullURLV2(`survivor/${survivorId}`);

        try {
            const response = await axios.get(path, this.getHeader(dodToken));
            return response.data as CoreSurvivor;
        } catch {
            return null;
        }
    }

    async createSurvivor(
        settlementId: number,
        name: string,
        type: SurvivorType
    ): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const dodToken = await this.getToken(token);

        const path = this.getFullURLV2(`settlement/${settlementId}/survivor`);
        let json = '';

        const body = {
            settlement_id: settlementId,
            survivor_name: name,
            survivor_type: type,
        };

        json = JSON.stringify(body);

        try {
            await axios.post(
                path,
                json,
                this.getHeaderJson(this.getHeader(dodToken))
            );

            return true;
        } catch {
            return false;
        }
    }

    async deleteSurvivor(survivorId: number): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const dodToken = await this.getToken(token);

        const path = this.getFullURLV2(`survivor/${survivorId}`);

        try {
            await axios.delete(path, this.getHeader(dodToken));
            return true;
        } catch {
            return false;
        }
    }

    async updateSurvivor(survivor: BaseSurvivor): Promise<ErrorMessage> {
        const token = await this.authService.getToken();
        if (token === null) return { value: 'Unable to authorize the user!' };

        const dodToken = await this.getToken(token);

        const path = this.getFullURLV2(
            `settlement/${survivor.settlement_id}/survivor`
        );

        try {
            const json = JSON.stringify(survivor);

            await axios.post(
                path,
                json,
                this.getHeaderJson(this.getHeader(dodToken))
            );

            return null;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                if (axiosError.code === '409')
                    return {
                        value: 'Someone updated the survivor sheet!',
                        statusCode: 409,
                    };
            }

            return {
                value: 'Failed to update the survivor sheet!',
                statusCode: -1,
            };
        }
    }

    async getSurvivorNeighbours(
        settlementId: number,
        survivorId: number,
        from: number,
        to: number
    ): Promise<Array<number>> {
        const token = await this.authService.getToken();
        let numbers: number[] = [];
        if (token === null) return numbers;

        const dodToken = await this.getToken(token);

        const path = this.getFullURL(
            `settlement/${settlementId}/survivor/${survivorId}/neighbors?from=${from}&to=${to}`
        );

        try {
            const response = await axios.get(path, this.getHeader(dodToken));
            numbers = response.data as number[];
            return numbers;
        } catch {
            return numbers;
        }
    }

    protected getFullURLV2(
        url: string,
        params: Map<string, string> = new Map<string, string>()
    ): string {
        let targetUrl = this.base2Url + url;
        let currentParam = 0;
        params.forEach((value: string, key: string) => {
            if (currentParam === 0) {
                targetUrl += '?';
            } else targetUrl += '&';

            targetUrl += key;
            targetUrl += '=';
            targetUrl += value;

            currentParam++;
        });
        return targetUrl;
    }
}
