import { useInjection } from 'inversify-react';
import React from 'react';
import { IAuthService } from '../../../../../../services/IAuthService';
import { IoPrintSharp } from 'react-icons/io5';
import { SheetSide } from '../../../../../../models/SheetSide';
import recordStyles from '../../record-styles.module.scss';
import Toolbar, {
    ToolbarSeparator,
} from '../../../toolbar/Toolbar.component';
import AvailabilityOfEditing from '../../../../utils/availabilityOfEdit';
import { SettlementType } from '../../../../models/settlements/SettlementType';
import SettlementList from '../../../../models/settlements/SettlementList';

interface SettlementHeaderProps {
    onPrintSettlement(): void;
    onRefreshSettlement(): void;
    onSaveSettlement(): void;
    onDeleteSettlement(): void;
    onFlipSettlement(newSide: number): void;
    onInfoSettlement(): void;
    saveEnabled: boolean;
    showPrintBtn: boolean;
    sheetId: SettlementType;
    currentSide: SheetSide;
    settlementList: SettlementList | null;
    onSettlementSelectionChanged: (id: number) => void;
    selectedSettlement: number;
}

export default function SettlementHeader(props: SettlementHeaderProps) {
    const authService: IAuthService = useInjection('IAuthService');

    const dropdown = (function () {
        const {
            settlementList,
            onSettlementSelectionChanged,
            selectedSettlement,
        } = props;

        const options = !!settlementList ? (
            settlementList.getItems().map((x) => {
                return (
                    <option key={x.id} value={x.id}>
                        {x.settlement_name}
                    </option>
                );
            })
        ) : (
            <option>No Settlements</option>
        );
        return (
            <select
                className={recordStyles.settlementSelect}
                onChange={(e) => {
                    e.preventDefault();
                    onSettlementSelectionChanged(parseInt(e.target.value));
                }}
                value={selectedSettlement}
            >
                {options}
            </select>
        );
    })();

    const availabilityOfEditing = AvailabilityOfEditing();

    const availableEditing = availabilityOfEditing.edit;
    const availableDelete = availabilityOfEditing.delete;

    return (
        <Toolbar>
            <Toolbar.Left>
                <button
                    title="Refresh Settlement"
                    type="button"
                    id="refresh_settlement_button"
                    className="refresh_button nav_buttons"
                    onClick={(e) => {
                        e.preventDefault();
                        props.onRefreshSettlement();
                    }}
                >
                    {' '}
                </button>

                {!!availableEditing ? (
                    <React.Fragment>
                        <ToolbarSeparator />
                        <button
                            title="Save Settlement"
                            type="button"
                            id="save_settlement_button"
                            className="save_button nav_buttons"
                            onClick={(e) => {
                                e.preventDefault();
                                props.onSaveSettlement();
                            }}
                            disabled={!props.saveEnabled}
                        >
                            {' '}
                        </button>
                    </React.Fragment>
                ) : null}
                {!!availableDelete &&
                authService.getAccessLevel('data') >= 3 ? (
                    <React.Fragment>
                        <ToolbarSeparator />
                        <button
                            title="Delete Settlement"
                            type="button"
                            id="delete_settlement_button"
                            className="delete_button nav_buttons"
                            onClick={(e) => {
                                e.preventDefault();
                                props.onDeleteSettlement();
                            }}
                        >
                            {' '}
                        </button>
                    </React.Fragment>
                ) : null}

                <ToolbarSeparator />
                {dropdown}
            </Toolbar.Left>

            <Toolbar.Right>
                {/* <button
                    title="Info"
                    type="button"
                    id="settlement_info_button"
                    className="info_button"
                    onClick={(e) => {
                        e.preventDefault();
                        props.onInfoSettlement();
                    }}
                ></button> */}
                {props.showPrintBtn && (
                    <button
                        title="Print Settlement"
                        type="button"
                        className="print_button nav_buttons"
                        onClick={(e) => {
                            e.preventDefault();
                            props.onPrintSettlement();
                        }}
                    >
                        <IoPrintSharp color="white" size={22} />
                    </button>
                )}
                <div className="toolbar_divider right"></div>
                {props.sheetId === SettlementType.Ark ? (
                    <select
                        className="sheet_dropdown"
                        onChange={(e) =>
                            props.onFlipSettlement(parseInt(e.target.value))
                        }
                        value={props.currentSide}
                    >
                        <option value={0}>1st Page</option>
                        <option value={1}>2nd Page</option>
                        <option value={2}>3rd Page</option>
                        <option value={3}>4th Page</option>
                    </select>
                ) : (
                    <button
                        title="Flip"
                        type="button"
                        id="flip_settlement_button"
                        className="flip_button"
                        onClick={(e) => {
                            e.preventDefault();
                            props.onFlipSettlement((props.currentSide + 1) % 2);
                        }}
                    ></button>
                )}
            </Toolbar.Right>
        </Toolbar>
    );
}
