import { CSSProperties } from 'react';
import KDHTMLInput, { KDHTMLInputProps } from './KDHtmlInput.component';
import classes from '../../../utils/classes';
import styles from './kdbuttoncheckbox.module.scss'

interface KDHTMLCustomCheckboxProps extends KDHTMLInputProps {
    containerClass: string;
    containerStyle?: CSSProperties | undefined;
    isBold? : boolean;
}

export default function KDHTMLCustomCheckbox(props: KDHTMLCustomCheckboxProps) {
    const containerClass = props.containerClass;
    const containerStyle = props.containerStyle;

    const isBold = !!props.isBold;

    let { containerClass: _, containerStyle: __, ...rest } = props;

    const propsInput = {
        ...rest,
    };

    return (
        <label className={classes(containerClass, isBold ? styles.bold : undefined)} style={containerStyle}>
            <KDHTMLInput type="checkbox" {...propsInput} />
            <span></span>
        </label>
    );
}
