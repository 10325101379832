import { useInjection } from 'inversify-react';
import React, { MouseEvent } from 'react';

import POTSunSettlementFrontPage from './potsun-settlement/potsun-front-page.component';
import POTSunSettlementBackPage from './potsun-settlement/potsun-back-page.component';
import StateValueWrapper from '../../../models/stateFragments/StateValueWrapper';
import { SheetSide } from '../../../../../models/SheetSide';
import { IAuthService } from '../../../../../services/IAuthService';
import classes from '../../../../../utils/classes';
import renderFor from '../../../../../utils/renderFor';
import { StateValueWrapperProvider } from '../../../contexts/StateValueWrapperContext';
import ClientOnly from '../../../utils/clientOnly';

interface POTSunSettlementProps {
    stateValueWrapper: StateValueWrapper;
    sheetSide: SheetSide;
    createSurvivor: (name: string) => Promise<any>;
    getInputValue: (id: string) => string;
    getInputValueWithDefault: (id: string, defaultValue: string) => string;
    getCheckboxValue: (id: string) => boolean;
    setInputValue: (id: string, value: string) => void;
    setCheckboxValue: (id: string, value: boolean) => void;
    setCheckboxBulk: (checkmarkValues: Map<string, boolean>) => void;
    openSurvivor: (id: number) => void;
}

const POTSunSettlementSheet = (props: POTSunSettlementProps) => {
    const authService = useInjection<IAuthService>('IAuthService');
    const [newCharacterName, setNewCharacterName] = React.useState('');
    const [newSurvivorCount, setNewSurvivorCount] = React.useState(0);

    const getInputValue = (id: string): string => {
        return props.getInputValue(id);
    };

    const getInputValueWithDefault = (
        id: string,
        defaultValue: string
    ): string => {
        return props.getInputValueWithDefault(id, defaultValue);
    };
    const setInputValue = (id: string, value: string) => {
        props.setInputValue(id, value);
    };

    const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { id, value } = event.target;

        setInputValue(id, value);
    };

    const filterSurvivors = (event: MouseEvent<HTMLButtonElement>) => {};

    const onCreateSurvivor = (event: MouseEvent<HTMLButtonElement>) => {
        if (newCharacterName === null || newCharacterName === '') return;
        onSurvivorCreation(newCharacterName);
    };

    const onSurvivorNameInput = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        event.preventDefault();
        const { value } = event.target;
        if (value === '') {
            setNewCharacterName('');
        } else {
            setNewCharacterName(value);
        }
    };

    const onCheckCreateSurvivor = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (event.key !== 'Enter' && event.key !== 'Return') return;
        if (newCharacterName === null || newCharacterName === '') return;
        onSurvivorCreation(newCharacterName);
    };

    const onSurvivorCreation = async (name: string) => {
        await props.createSurvivor(name);
        setNewCharacterName('');
        setNewSurvivorCount(newSurvivorCount + 1);
    };

 
    const renderFront = () => {
        const { sheetSide } = props;

        return (
            <div
                className={classes(
                    'record_sheet_front print_sheet',
                    sheetSide === SheetSide.First ? 'show-page' : 'hide-page'
                )}
            >
                <POTSunSettlementFrontPage />
            </div>
        );
    };

    const dataAccess = authService.getAccessLevel('data');
    const { sheetSide } = props;

    const renderBack = () => {
        return (
            <div
                className={classes(
                    'record_sheet_back core_record_sheet_back print_sheet',
                    sheetSide === SheetSide.Second ? 'show-page' : 'hide-page'
                )}
            >
                <POTSunSettlementBackPage
                    fromYear={parseInt(
                        getInputValueWithDefault('from_year', '1')
                    )}
                    toYear={parseInt(getInputValueWithDefault('to_year', '36'))}
                    sheetId={parseInt(getInputValue('id'))}
                    openSurvivor={props.openSurvivor}
                    newSurvivorCount={newSurvivorCount}
                />
                {!ClientOnly().clientOnly && (
                    <div id="survivor-list-filters">
                        <span>Year: </span>
                        <label htmlFor="from_year"> From </label>
                        <select
                            name="from_year"
                            id="from_year"
                            value={getInputValue('from_year')}
                            onChange={selectChange}
                        >
                            {renderFor(1, 37, (index) => {
                                return (
                                    <option key={index} value={index}>
                                        {index}
                                    </option>
                                );
                            })}
                        </select>
                        <label htmlFor="to_year">To</label>
                        <select
                            name="to_year"
                            id="to_year"
                            value={getInputValueWithDefault('to_year', '36')}
                            onChange={selectChange}
                        >
                            {renderFor(1, 37, (index) => {
                                return (
                                    <option key={index} value={index}>
                                        {index}
                                    </option>
                                );
                            })}
                        </select>
                        <button
                            type="button"
                            id="filter_survivors_button"
                            onClick={filterSurvivors}
                        >
                            Filter
                        </button>
                    </div>
                )}
                {dataAccess >= 1 && !ClientOnly().clientOnly ? (
                    <div id="create_survivor">
                        <input
                            type="text"
                            id="create_survivor_name"
                            name="create_survivor_name"
                            placeholder="New survivor name..."
                            onInput={onSurvivorNameInput}
                            onKeyDown={onCheckCreateSurvivor}
                            value={newCharacterName!}
                        />
                        <button
                            type="button"
                            id="confirm_survivor_creation"
                            onClick={onCreateSurvivor}
                            disabled={
                                newCharacterName === null ||
                                newCharacterName === ''
                            }
                        ></button>
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    };

    return (
        <StateValueWrapperProvider value={props.stateValueWrapper}>
            {renderFront()}
            {renderBack()}
        </StateValueWrapperProvider>
    );
};

export default POTSunSettlementSheet;
