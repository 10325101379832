import styles from './sheet-timeline.module.scss';
import sheetStyles from '../styles/sheet-styles.module.scss';
import { timeLineLabels } from './sheet-timeline-data';
import KDHTMLCustomCheckbox from '../../../../../../components/general/kd-html-input/KDHtmlCustomCheckbox';
import KDHTMLInput from '../../../../../../components/general/kd-html-input/KDHtmlInput.component';
import classes from '../../../../../../utils/classes';
import { TimeLineIcon } from '../../../../../../components/icons';
import KDHTMLProgress from '../../../../../../components/general/kd-html-input/KDHtmlProgress.component';

interface TimeLineTableProps {
    sheetId: string;
    columns: [string, string];
    layout?: 'full' | 'small';
    numberOfColumns?: number;
    title?: string;
}

const SheetTimeLineTable = ({
    sheetId,
    columns,
    layout,
    numberOfColumns = 2,
    title = 'Timeline',
}: TimeLineTableProps) => {
    const data = timeLineLabels.find((item) => item.sheetId === sheetId)?.list;

    if (!data) return null;
    const columnHeight = Math.floor(data.length / 2 - 1);

    const renderTimelineEvent = (
        index: number,
        change: (
            index: number,
            event: React.ChangeEvent<HTMLInputElement>
        ) => void,
        id: string
    ) => {
        return (
            <div key={index + id} className={styles.timeLineEventContainer}>
                <div
                    className={
                        numberOfColumns === 2 && index > columnHeight
                            ? styles.verticalSeparator
                            : ''
                    }
                />
                <KDHTMLCustomCheckbox
                    containerClass={classes(sheetStyles.checkboxContainer)}
                    id={id}
                    name={id}
                    onChange={(e) => change(index, e)}
                />
                {index + 1}
                <div className={styles.timeLineEventLabelContainer}>
                    <div className={styles.timeLineEventLabel}>
                        {data[index].label}
                    </div>
                    {data[index].id && (
                        <KDHTMLInput
                            className={styles.timeLineEventInput}
                            type="text"
                            id={data[index].id!}
                            name={data[index].id!}
                        />
                    )}
                </div>
            </div>
        );
    };

    return (
        <div
            className={classes(
                styles.timeline,
                sheetId && styles[sheetId],
                layout === 'small' ? styles.timelineSmall : styles.timelineFull
            )}
        >
            <div className={styles.timeLineHeader}>
                <TimeLineIcon />
                <span className={styles.timeLineLabel}>{title}</span>
            </div>

            <div className={styles.timelineTableHeader}>
                <div className={styles.timelineTableHeaderLabels}>
                    <span className={styles.timeLineYearLabel}>
                        {columns[0]}
                    </span>
                    <span className={styles.timeLineEventsLabel}>
                        {columns[1]}
                    </span>
                </div>
                <div className={styles.timeLineBoldLine} />
            </div>

            <div className={styles.timeLineContainer}>
                {sheetId === 'ark' && (
                    <div className={styles.timeLinePrologue}>
                        <span>Prologue:</span>
                        <span>Crimson Crocodile</span>
                    </div>
                )}
                <div
                    className={styles.timeLineEventWrapper}
                    style={{
                        gridTemplateColumns: `repeat(${numberOfColumns}, 1fr)`,
                        gridTemplateRows:
                            numberOfColumns === 1
                                ? `repeat(${data.length}, max-content)`
                                : `repeat(${columnHeight + 1}, max-content)`,
                    }}
                >
                    <KDHTMLProgress
                        count={data.length}
                        prefix="time_check_"
                        item={renderTimelineEvent}
                    />
                </div>
            </div>
        </div>
    );
};

export default SheetTimeLineTable;
