import { SurvivorListComponent } from '../../survivor-list/survivor-list.component';

import styles from './potsun-back-page.module.scss';
import baseStyles from '../styles/potsun-settlement.module.scss';
import SheetParagraph from '../../../elements/sheets/sheet-paragraphs/sheet-paragraph.component';
import SheetNemesisMonsters from '../../../elements/sheets/sheet-nemesis/sheet-nemesis.component';
import SheetPopulation from '../../../elements/sheets/sheet-population/sheet-population.component';
import SheetLostSettlement from '../../../elements/sheets/sheet-lost-settlements/sheet-lost-settlements.component';
import { SettlementType } from '../../../../models/settlements/SettlementType';

interface IPOTSunSettlementProps {
    fromYear: number;
    toYear: number;
    sheetId: number;
    openSurvivor: (survivorId: number) => void;
    newSurvivorCount: number;
}

export default function POTSunSettlementBackPage(
    props: IPOTSunSettlementProps
) {
    const nemesisMonsters = ['Butcher', "King's Man", 'The Hand'];

    return (
        <div className={baseStyles.pageContainer}>
            <div className={baseStyles.innerContainer}>
                <div className={styles.upperHalf}>
                    <div>
                        <div className={styles.header}>
                            <div className={styles.headerTitle}>Storage</div>
                            <div className={styles.headerHelper}>
                                Gear & Resources may be stored without limit.
                            </div>
                        </div>
                        <SheetParagraph
                            idPrefix="resource_"
                            linesPerColumn={10}
                            totalLines={39}
                            helperText="( If you run out of room, use scrap paper )"
                        />
                    </div>

                    <div className={styles.monsterRow}>
                        <SheetNemesisMonsters
                            nemesisMonsters={nemesisMonsters}
                        />
                        <div className={styles.monsterRowRight}>
                            <div>
                                <div className={styles.header}>
                                    <div className={styles.headerTitle}>
                                        Defeated Monsters
                                    </div>
                                    <div className={styles.headerHelper}>
                                        A list of defeated monsters and their
                                        level.
                                    </div>
                                </div>
                                <SheetParagraph
                                    idPrefix="defeated_"
                                    totalLines={20}
                                    linesPerColumn={10}
                                    gap={16}
                                />
                            </div>
                            <div className={styles.notesContainer}>
                                <div className={styles.header}>
                                    <div className={styles.headerTitle}>
                                        Notes
                                    </div>
                                    <div className={styles.headerHelper}></div>
                                </div>
                                <SheetParagraph
                                    idPrefix="note_"
                                    totalLines={10}
                                    linesPerColumn={10}
                                    gap={16}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={baseStyles.dottedLine}></div>
                <div className={styles.thirdRow}>
                    <SheetPopulation />
                    <SheetLostSettlement idPrefix="set_check_" />
                </div>

                <SurvivorListComponent
                    {...props}
                    settlementType={SettlementType.POTSun}
                />
            </div>
        </div>
    );
}
