import { useInjection } from 'inversify-react';
import React from 'react';
import { IoPrintSharp } from 'react-icons/io5';
import { SettlementType } from '../../../models/settlements/SettlementType';
import { SheetSide } from '../../../../../models/SheetSide';
import { IAuthService } from '../../../../../services/IAuthService';

interface SettlementHeaderProps {
    onPrintSettlement(): void;
    onRefreshSettlement(): void;
    onSaveSettlement(): void;
    onDeleteSettlement(): void;
    onFlipSettlement(newSide: number): void;
    onInfoSettlement(): void;
    saveEnabled: boolean;
    showPrintBtn: boolean;
    sheetId: SettlementType;
    currentSide: SheetSide;
}

export default function SettlementHeader(props: SettlementHeaderProps) {
    const authService: IAuthService = useInjection('IAuthService');
    return (
        <div className="toolbar">
            <button
                title="Refresh Settlement"
                type="button"
                id="refresh_settlement_button"
                className="refresh_button nav_buttons"
                onClick={(e) => {
                    e.preventDefault();
                    props.onRefreshSettlement();
                }}
            >
                {' '}
            </button>
            {authService.getAccessLevel('data') >= 2 ? (
                <React.Fragment>
                    <div className="toolbar_divider left"></div>
                    <button
                        title="Save Settlement"
                        type="button"
                        id="save_settlement_button"
                        className="save_button nav_buttons"
                        onClick={(e) => {
                            e.preventDefault();
                            props.onSaveSettlement();
                        }}
                        disabled={!props.saveEnabled}
                    >
                        {' '}
                    </button>
                </React.Fragment>
            ) : null}
            {authService.getAccessLevel('data') >= 3 ? (
                <React.Fragment>
                    <div className="toolbar_divider left"></div>
                    <button
                        title="Delete Settlement"
                        type="button"
                        id="delete_settlement_button"
                        className="delete_button nav_buttons"
                        onClick={(e) => {
                            e.preventDefault();
                            props.onDeleteSettlement();
                        }}
                    >
                        {' '}
                    </button>
                </React.Fragment>
            ) : null}

            <div className="header-right">
                <button
                    title="Info"
                    type="button"
                    id="settlement_info_button"
                    className="info_button"
                    onClick={(e) => {
                        e.preventDefault();
                        props.onInfoSettlement();
                    }}
                ></button>
                <div className="toolbar_divider right"></div>
                {props.showPrintBtn && (
                    <button
                        title="Print Settlement"
                        type="button"
                        className="print_button nav_buttons"
                        onClick={(e) => {
                            e.preventDefault();
                            props.onPrintSettlement();
                        }}
                    >
                        <IoPrintSharp color="white" size={22} />
                    </button>
                )}
                {props.sheetId === SettlementType.Ark ? (
                    <select
                        className="sheet_dropdown"
                        onChange={(e) =>
                            props.onFlipSettlement(parseInt(e.target.value))
                        }
                        value={props.currentSide}
                    >
                        <option value={0}>1st Page</option>
                        <option value={1}>2nd Page</option>
                        <option value={2}>3rd Page</option>
                        <option value={3}>4th Page</option>
                    </select>
                ) : (
                    <button
                        title="Flip"
                        type="button"
                        id="flip_settlement_button"
                        className="flip_button"
                        onClick={(e) => {
                            e.preventDefault();
                            props.onFlipSettlement((props.currentSide + 1) % 2);
                        }}
                    ></button>
                )}
            </div>
        </div>
    );
}
