import { useInjection } from 'inversify-react';
import { useSelector } from 'react-redux';
import CoreSurvivorSheet from '../survivor-sheet/core-survivor-sheet.component';
import SurvivorHeader from '../survivor-sheet-header/survivor-sheet-header.component';
import ArkSurvivorSheetComponent from '../survivor-sheet/ark-survivor-sheet.component';
import { useEffect, useRef, useState } from 'react';
import SurvivorInfo from '../survivor-info/survivor-info.component';
import SurvivorFooter from '../survivor-footer/survivor-footer.component';
import { useReactToPrint } from 'react-to-print';
import POTStarSurvivorSheet from '../survivor-sheet/potstar-survivor-sheet.component';
import POTSunSurvivorSheet from '../survivor-sheet/potsun-survivor-sheet.component';
import StateValueWrapper from '../../../../models/stateFragments/StateValueWrapper';
import { SurvivorType } from '../../../../models/survivors/SurvivorType';
import IUIService from '../../../../../../services/IUIService';
import { RootState } from '../../../../../../redux/store';
import IRecordSheetService from '../../../../services/IRecordSheetService';
import GearGrid from '../survivor-sheet/gear-grid/gear-grid.component';
import ITextureRegistry from '../../../../../../services/ITextureRegistry';

interface SurvivorSheetContainerProps {
    stateValueWrapper: StateValueWrapper;
    refresh: () => void;
    close: () => void;
    update: () => void;
    lanternYearChanged: (year: number) => void;
    availableYears : number[]
    copy: () => void;
    paste: () => void;
    saveEnabled: boolean;
    id: number;
    neighbours: number[];
    openSurvivor: (id: number) => void;
    survivorType: SurvivorType;
}

export default function SurvivorSheetContainerDefault(
    props: SurvivorSheetContainerProps
) {
    const recordSheetService = useInjection<IRecordSheetService>(
        'IRecordSheetService'
    );

    const availableYears = props.availableYears;

    const textureRegistry = useInjection<ITextureRegistry>('ITextureRegistry')

    const uiService = useInjection<IUIService>('IUIService');

    const [infoVisible, setInfoVisible] = useState(false);
    
    const [canPrint, setCanPrint] = useState(false);

    useEffect(()=>{
        setCanPrint(false);

        setTimeout(()=>{
            setCanPrint(true)
        }, 600);

    }, [props.stateValueWrapper])

    const pageSize = useRef('946px 670px');

    const printRef = useRef<HTMLElement>(null);

    const handlePrint = useReactToPrint({
        content: () => printRef.current as any,
        removeAfterPrint: true,
        onBeforePrint:()=>{
        },
        onAfterPrint:()=>{
            
            uiService.hideLoading();
            if(!!printRef.current){
                printRef.current.style.display = 'none'; 
            }
        },
        //pageStyle : ()=> "@page { size : landscape; }"
    });

    const copiedSurvivor = useSelector(
        (state: RootState) => state.copiedSurvivor.survivor
    );

    const onRefreshSurvivor = () => {
        props.refresh();
    };

    const onUpdateSurvivor = async () => {
        props.update();
    };

    const onCloseSurvivor = () => {
        props.close();
    };

    const onCopySurvivor = () => {
        props.copy();
    };

    const onPasteSurvivor = () => {
        props.paste();
    };

    const isArkSurvivor =
        props.stateValueWrapper.getInputValueInt('survivor_type') ===
        SurvivorType.Ark;

    const isInfoButtonVisible = () => {
        return isArkSurvivor;
    };

    const onInfoClicked = () => {
        if (!isArkSurvivor) {
            return;
        }
        setInfoVisible(!infoVisible);
    };

    const onDeleteSurvivor = async () => {
        const confirm = await uiService.showConfirmationDialog(
            `Are you sure you want to delete survivor ${props.stateValueWrapper.getInputValue(
                'name'
            )}?`,
            'No',
            'Yes'
        );
        if (!confirm) return;
        const response = await recordSheetService.deleteSurvivor(
            props.stateValueWrapper.getInputValueInt('id')
        );
        if (response) {
            uiService.showSuccessNotification('Survivor deleted successfuly');
            onCloseSurvivor();
        } else {
            uiService.showErrorNotification('Failed to remove the survivor!');
        }
    };

    const renderCoreSurvivor = ({print} : {print? : boolean}) => {
        return (
            <CoreSurvivorSheet
                availableYears={availableYears}
                stateValueWrapper={props.stateValueWrapper}
                lanternYearChanged={props.lanternYearChanged}
                id={props.id}
                neighbours={props.neighbours}
                openSurvivor={props.openSurvivor}
                print={print}
            />
        );
    };

    const renderArkSurvivor = ({print} : {print? : boolean}) => {
        return (
            <ArkSurvivorSheetComponent
                stateValueWrapper={props.stateValueWrapper}
                lanternYearChanged={props.lanternYearChanged}
                id={props.id}
                neighbours={props.neighbours}
                openSurvivor={props.openSurvivor}
                print={print}
            />
        );
    };

    const renderPOTStarSurvivor = ({print} : {print? : boolean}) => {
        return (
            <POTStarSurvivorSheet
                stateValueWrapper={props.stateValueWrapper}
                lanternYearChanged={props.lanternYearChanged}
                id={props.id}
                neighbours={props.neighbours}
                openSurvivor={props.openSurvivor}
                print={print}
            />
        );
    };

    const renderPOTSunSurvivor = ({print} : {print? : boolean}) => {
        return (
            <POTSunSurvivorSheet
                stateValueWrapper={props.stateValueWrapper}
                lanternYearChanged={props.lanternYearChanged}
                id={props.id}
                neighbours={props.neighbours}
                openSurvivor={props.openSurvivor}
                print={print}
            />
        );
    };

    const renderSurvivor = ({print} : {print? : boolean}) => {

        pageSize.current = 'landscape';
        if (props.survivorType === SurvivorType.Core) {
            //pageSize.current = '700px 700px';
            return renderCoreSurvivor({print});
        }
        if (props.survivorType === SurvivorType.Ark) {
            //pageSize.current = '946px 670px';
            return renderArkSurvivor({print});
        }
        if (props.survivorType === SurvivorType.PoTStar) {
            //pageSize.current = '700px 670px';
            return renderPOTStarSurvivor({print});
        }
        if (props.survivorType === SurvivorType.PoTSun) {
            //pageSize.current = '700px 700px';
            return renderPOTSunSurvivor({print});
        }
    };

    const renderFooter = () => {
        if (props.survivorType !== SurvivorType.Ark) return '';
        return (
            <SurvivorFooter
                neighbours={props.neighbours}
                id={props.id}
                openSurvivor={props.openSurvivor}
            />
        );
    };

    return (
        <div>
            <form>
                <div
                    style={{
                        textAlign: 'center',
                        width: 'fit-content',
                        display : 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                >
                    <h3>Survivor Record Sheet</h3>
                    <SurvivorHeader
                        onPrintSurvivorSheet={async ()=>{
                            if(!!printRef.current){
                                printRef.current.style.display = 'block'; 
                            }
                            uiService.showLoading(0.95);
                            await textureRegistry.hasPendingImageRequests();
                            handlePrint();

                        }}
                        printEnabled = {canPrint}
                        refreshSurvivor={onRefreshSurvivor}
                        updateSurvivorRequested={onUpdateSurvivor}
                        closeSurvivorRequested={onCloseSurvivor}
                        copySurvivorRequested={onCopySurvivor}
                        pasteSurvivorRequested={onPasteSurvivor}
                        deleteSurvivorRequested={onDeleteSurvivor}
                        pasteEnabled={copiedSurvivor !== null}
                        saveEnabled={props.saveEnabled}
                        infoVisible={isInfoButtonVisible()}
                        infoClicked={onInfoClicked}
                    />
                    <SurvivorInfo
                        stateValueWrapper={props.stateValueWrapper}
                        lanternYearChanged={props.lanternYearChanged}
                        isVisible={infoVisible}
                    />
                    <div>{renderSurvivor({})}</div>

                    <div
                        ref={printRef as React.RefObject<HTMLDivElement>} 
                        style={{display:'none'}} 
                        data-print="true">
                        {renderSurvivor({print : true})}
                    </div>
                    {renderFooter()}
                </div>
            </form>
        </div>
    );
}
