import KDHTMLInput from '../../../../../../components/general/kd-html-input/KDHtmlInput.component';
import classes from '../../../../../../utils/classes';
import renderFor from '../../../../../../utils/renderFor';
import styles from './sheet-paragraph.module.scss';

interface SheetParagraphProps {
    helperText?: string;
    totalLines: number;
    linesPerColumn: number;
    idPrefix: string;
    lineHeight?: number;
    gap?: number;
}

const SheetParagraph = ({
    helperText,
    totalLines,
    linesPerColumn,
    idPrefix,
    lineHeight = 17,
    gap = 25,
}: SheetParagraphProps) => {
    return (
        <div className={classes(styles.paragraphContainer)}>
            {/* <div className={styles.paragraphHeader}> */}
            {/*     <p className={styles.headerTitle}>{title}</p> */}
            {/*     {subtitle && ( */}
            {/*         <p className={styles.headerSubtitle}>{subtitle}</p> */}
            {/*     )} */}
            {/* </div> */}

            <div
                className={styles.innerContainer}
                style={{
                    columnGap: `${gap}px`,
                    gridTemplateRows: `repeat(${linesPerColumn}, max-content)`,
                }}
            >
                {renderFor(0, totalLines, (index) => {
                    const id = `${idPrefix}${index}`;
                    return (
                        <div
                            key={index}
                            style={{ height: `${lineHeight}px` }}
                            className={styles.horizontalLine}
                        >
                            <KDHTMLInput
                                key={id}
                                type="text"
                                id={id}
                                className={styles.input}
                                name={id}
                            />
                        </div>
                    );
                })}
                {helperText && (
                    <p
                        style={{ height: `${lineHeight}px` }}
                        className={styles.info}
                    >
                        {helperText}
                    </p>
                )}
            </div>
        </div>
    );
};

export default SheetParagraph;
