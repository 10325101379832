import { ArkSettlementSheetModel } from '../../models/settlements/ArkSettlementSheetModel';
import BaseSettlementSheetState from '../../models/stateFragments/sheets/BaseSettlementSheetState';
import BaseMapper from '../BaseMapper';

export default class ArkSettlementSheetMapper extends BaseMapper<
    ArkSettlementSheetModel,
    BaseSettlementSheetState
> {
    public mapToState(from: ArkSettlementSheetModel): BaseSettlementSheetState {
        const state: BaseSettlementSheetState = {
            values: new Map<string, string>(),
            checkmarks: new Map<string, boolean>(),
        };

        state.values?.set('id', from.id!.toString());

        this.setExpansionCheckmarksFromString(
            state.checkmarks,
            'expansion_',
            from.expansion_ids!,
            18
        );

        this.setText(state.values, 'description', from.description);
        this.setText(state.values, 'settlement_name', from.settlement_name);

        state.values?.set('survival_limit', !!from.survival_limit?from.survival_limit.toString():'0');
        this.setBooleanCountToMap(
            state.checkmarks,
            'time_check_',
            from.lantern_year,
            39,
            0
        );

        this.setBooleanCountToMap(
            state.checkmarks,
            'set_check_',
            20,
            from.lost_settlements,
            0
        );

        this.setTextSubArrayToMap(state.values, 'time_', from.timeline_events);

        this.setBooleanIntSubArrayToMap(
            state.checkmarks,
            'quarry_check_',
            from.quarry_checks
        );
        this.setTextSubArrayToMap(state.values, 'quarry_', from.quarries);

        this.setBooleanIntSubArrayToMap(
            state.checkmarks,
            'nemesis_check_',
            from.nemesis_checks
        );
        this.setTextSubArrayToMap(state.values, 'nemesis_', from.nemesis);

        this.setBooleanIntSubArrayToMap(
            state.checkmarks,
            'mile_check_',
            from.milestone_checks
        );
        this.setTextSubArrayToMap(state.values, 'milestones_', from.milestones);

        this.setTextSubArrayToMap(state.values, 'survivor_bonuses_', from.survivor_bonuses);

        this.setBooleanIntSubArrayToMap(
            state.checkmarks,
            'principle_check_',
            from.principle_checks
        );
        this.setTextSubArrayToMap(state.values, 'principle_', from.principles);

        this.setTextSubArrayToMap(
            state.values,
            'seed_pattern_',
            from.seed_patterns
        );

        this.setTextSubArrayToMap(
            state.values,
            'innovations_',
            from.innovations
        );

        this.setBooleanIntSubArrayToMap(
            state.checkmarks,
            'settlement_checks_',
            from.settlement_checks
        );
        this.setTextSubArrayToMap(
            state.values,
            'settlements_',
            from.settlements
        );

        this.setTextSubArrayToMap(state.values, 'resources_', from.resources);

        this.setTextSubArrayToMap(state.values, 'gears_', from.gears);
        this.setTextSubArrayToMap(state.values, 'patterns_', from.patterns);

        this.setText(state.values, 'population', from.population.toString());

        this.setBooleanCountToMap(
            state.checkmarks,
            'death_',
            from.death_count,
            56,
            0
        );

        this.setText(
            state.values,
            'collective_cognition',
            from.collective_cognition.toString()
        );

        this.setTextSubArrayToMap(
            state.values,
            'victory_quarry_',
            from.victory_quarry
        );
        this.setBooleanIntSubArrayToMap(
            state.checkmarks,
            'victory_quarry_check_',
            from.victory_quarry_checks
        );
        this.setBooleanIntSubArrayToMap(
            state.checkmarks,
            'victory_nemesis_checks_',
            from.victory_nemesis_checks
        );
        this.setTextSubArrayToMap(
            state.values,
            'victory_nemesis_',
            from.victory_nemesis
        );

        this.setBooleanIntSubArrayToMap(
            state.checkmarks,
            'rewards_checks_',
            from.rewards_checks
        );

        this.setTextSubArrayToMap(
            state.values,
            'philosophy_',
            from.philosophy
        );

        this.setTextSubArrayToMap(
            state.values,
            'knowledge_',
            from.knowledge
        );

        this.setBooleanCountToMap(
            state.checkmarks,
            'bone_eater_level_',
            4,
            from.bone_eaters_level,
            0
        );

        this.setText(state.values, 'from_year', '0');
        this.setText(state.values, 'to_year', '36');

        this.setText(state.values, 'last_updated', from.last_updated);

        state.values?.set('settlement_type', from.settlement_type.toString());
        return state;
    }
    public mapFromState(
        from: BaseSettlementSheetState
    ): ArkSettlementSheetModel {
        let model: ArkSettlementSheetModel = {
            id: parseInt(this.getValue(from.values, 'id')),
            expansion_ids: this.getExpansionString(
                from.checkmarks,
                'expansion_'
            ),            

            settlement_name: this.getValue(from.values, 'settlement_name'),
            survival_limit : parseInt(this.getValue(from.values, "survival_limit")),
            lantern_year : this.getBoolCount(from.checkmarks, "time_check_", true),
            lost_settlements : this.getBoolCount(from.checkmarks, "set_check_", true),
            timeline_events: this.getTextSubArrayPrefix(from.values, 'time_'),
            quarry_checks : this.getBooleanSubArrayPrefix(from.checkmarks, 'quarry_check_'),
            quarries : this.getTextSubArrayPrefix(from.values, 'quarry_'),
            nemesis_checks: this.getBooleanSubArrayPrefix(from.checkmarks, 'nemesis_check_'),
            nemesis: this.getTextSubArrayPrefix(from.values, 'nemesis_'),
            milestone_checks: this.getBooleanSubArrayPrefix(from.checkmarks, 'mile_check_'),
            milestones: this.getTextSubArrayPrefix(from.values, 'milestones_'),
            survivor_bonuses: this.getTextSubArrayPrefix(from.values, 'survivor_bonuses_'),
            principle_checks: this.getBooleanSubArrayPrefix(from.checkmarks, "principle_check_"),
            principles: this.getTextSubArrayPrefix(from.values, 'principle_'),
            seed_patterns: this.getTextSubArrayPrefix(from.values, 'seed_pattern_'),
            innovations: this.getTextSubArrayPrefix(from.values, 'innovations_'),
            settlement_checks: this.getBooleanSubArrayPrefix(from.checkmarks, 'settlement_checks_'),
            settlements: this.getTextSubArrayPrefix(from.values, 'settlements_'),
            resources: this.getTextSubArrayPrefix(from.values, 'resources_'),
            gears: this.getTextSubArrayPrefix(from.values, 'gears_'),
            patterns: this.getTextSubArrayPrefix(from.values, 'patterns_'),
            
            population: this.getValueInt(from.values, 'population'),
            death_count: this.getBoolCount(from.checkmarks, 'death_', true),

            collective_cognition: this.getValueInt(from.values, 'collective_cognition'),
            victory_quarry: this.getTextSubArrayPrefix(from.values, 'victory_quarry_'),
            victory_quarry_checks: this.getBooleanSubArrayPrefix(from.checkmarks, 'victory_quarry_check_'),
            victory_nemesis_checks: this.getBooleanSubArrayPrefix(from.checkmarks, 'victory_nemesis_checks_'),

            victory_nemesis : this.getTextSubArrayPrefix(from.values, 'victory_nemesis_'),
            rewards_checks: this.getBooleanSubArrayPrefix(from.checkmarks, 'rewards_checks_'),
            bone_eaters_level: this.getBoolCount(from.checkmarks, 'bone_eater_level_', true),

            philosophy: this.getTextSubArrayPrefix(from.values, 'philosophy_'),
            knowledge: this.getTextSubArrayPrefix(from.values, 'knowledge_'),
            settlement_type: this.getValueInt(from.values, 'settlement_type'),
            description: this.getValue(from.values, 'description'),
            last_updated: this.getValue(from.values, 'last_updated'),
        };
        return model;
    }
}
