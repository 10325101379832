import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface SheetRefreshState {
    refresh : boolean
}

const initialState: SheetRefreshState = {
    refresh : false
}

export const sheetRefreshSlice = createSlice({
    name: 'sheetRefresh',
    initialState,
    reducers: {
        refresh: (state, action: PayloadAction<boolean>) => {
            state.refresh = action.payload
        },
    },
})

export const { refresh } = sheetRefreshSlice.actions

export const selectRefresh = (state: RootState) => state.sheetRefresh.refresh

export default sheetRefreshSlice.reducer
