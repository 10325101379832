import styles from './sheet-settlements.module.scss';
import sheetStyles from '../styles/sheet-styles.module.scss';
import KDHTMLCustomCheckbox from '../../../../../../components/general/kd-html-input/KDHtmlCustomCheckbox';
import renderFor from '../../../../../../utils/renderFor';
import classes from '../../../../../../utils/classes';
import KDHTMLInput from '../../../../../../components/general/kd-html-input/KDHtmlInput.component';

interface SheetSettlementProps {
    locationPrefilled: string[];
    locationTextboxCount?: number
    startTextBoxIndex? : number
    idPrefix: string;
    extraFieldIDPrefix: string;
    layout?: 'default' | 'ark';
    offsetIndex? : number;
    extraTextBoxCount? : number;
}

export const SheetSettlements = ({
    locationPrefilled,
    locationTextboxCount,
    startTextBoxIndex,
    extraFieldIDPrefix,
    idPrefix,
    offsetIndex,
    extraTextBoxCount,
    layout = 'default',
}: SheetSettlementProps) => {
    const numberOfSettlements = layout === 'ark' ? 22 : 11;

    const startTextBoxIndexUnwrapped = !!startTextBoxIndex?startTextBoxIndex: 1000000;
    const locationTextboxCountUnwrapped = !!locationTextboxCount?locationTextboxCount: 0;

    const offsetIndexUnwrapped = !!offsetIndex?offsetIndex: (layout === 'ark'?0:-1);

    const extraTextBoxCountUnwrapped = !!extraTextBoxCount?extraTextBoxCount:6;

    const settlements = renderFor(offsetIndexUnwrapped, numberOfSettlements, (index) => {
        const id = `${idPrefix}${index}`;
        const label = locationPrefilled[index-offsetIndexUnwrapped] || '';
        const isTextBox = index>=startTextBoxIndexUnwrapped && locationTextboxCountUnwrapped>0;
        const textBoxId = `${extraFieldIDPrefix}${index-startTextBoxIndexUnwrapped}`
        return (
            <div key={id} className={styles.settlementLocationsHorizontalLine}>
                <KDHTMLCustomCheckbox
                    containerClass={classes(
                        sheetStyles.checkboxContainer,
                        styles.smallCheckbox
                    )}
                    key={`label_${id}`}
                    id={id}
                    checked={index <= -1}
                />
                {!isTextBox && <span className={styles.settlementLocation}>{label}</span>}
                {!!isTextBox && <KDHTMLInput id={textBoxId} type='text' />}
            </div>
        );
    });

    const extraSettlements = renderFor(0, extraTextBoxCountUnwrapped, (index) => {
        const id = `${extraFieldIDPrefix}${index}`;
        return (
            <div key={id} className={styles.settlementLocationsHorizontalLine}>
                <KDHTMLInput type="text" id={id} name={id} key={id} />
            </div>
        );
    });

    return (
        <div
            className={classes(
                styles.settlementLocationsContainer,
                styles[layout]
            )}
        >
            <div className={styles.settlementLocationInnerContainer}>
                {settlements}
                {layout === 'default' && extraSettlements}
            </div>
        </div>
    );
};

export default SheetSettlements;
