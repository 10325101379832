interface SurvivorFooterProps {
    id: number;
    neighbours: number[];
    openSurvivor: (id: number) => void;
}

export default function SurvivorFooter(props: SurvivorFooterProps) {
    const getPreviousButtonDisable = () => {
        if (props.neighbours.length === 0) return true;
        if (props.neighbours[props.neighbours.length - 1] === props.id)
            return true;
        return false;
    };

    const getNextButtonDisable = () => {
        if (props.neighbours.length === 0) return true;
        if (props.neighbours[0] === props.id) return true;
        return false;
    };

    const previousButtonClick = (event: React.MouseEvent<HTMLInputElement>) => {
        event.preventDefault();
        props.openSurvivor(props.neighbours[props.neighbours.length - 1]);
    };

    const nextButtonClick = (event: React.MouseEvent<HTMLInputElement>) => {
        event.preventDefault();
        props.openSurvivor(props.neighbours[0]);
    };

    return (
        <div
            className="survivor-filtered-pagination"
            id="survivor-filtered-pagination"
        >
            <input
                type="button"
                value="Previous"
                id="previous-survivor"
                className="previous-survivor"
                disabled={getPreviousButtonDisable()}
                onClick={previousButtonClick}
            />
            <input
                type="button"
                value="Next"
                id="next-survivor"
                className="next-survivor"
                disabled={getNextButtonDisable()}
                onClick={nextButtonClick}
            />
        </div>
    );
}
