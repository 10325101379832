export const SunIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            version="1.1"
            viewBox="0 0 33.867 33.867"
            xmlSpace="preserve"
        >
            <g>
                <path
                    fill="#000"
                    strokeWidth="0.265"
                    d="M14.446 4.153c-2.848.42-2.932 1.774-3.085 3.562.045.137.218.075.34.136l.3.006c.426-.241.203-1.394.862-1.812 2.536-.907 3.598.868 3.032 2.739-.696 1.125-.453 1.411-2.041 1.795-.185.03-.717-.074-.818-.017a2.515 2.515 0 01-.559-.149c-2.03-1.269-3.683-2.827-6.354-.843-1.763 2.276-.886 3.312.241 4.707.128.067.21-.097.34-.138l.22-.203c.14-.469-.818-1.146-.633-1.903 1.202-2.411 3.197-1.866 4.085-.124.19.6.331 1.137.3 1.652-.255.97-.876 1.504-1.44 1.757-2.325.57-4.594.667-5.034 3.966.4 2.85 1.754 2.944 3.54 3.11.138-.045.077-.218.139-.34l.008-.3c-.238-.428-1.392-.212-1.805-.874-.89-2.542.892-3.592 2.759-3.013 1.334.642 1.65 1.145 1.76 2.874.016.36-.047.688-.147.964-1.208 2.067-2.717 3.765-.654 6.376 2.326 1.696 3.335.788 4.697-.38.063-.13-.104-.207-.148-.336l-.21-.215c-.472-.126-1.121.853-1.884.69-2.445-1.13-1.958-3.14-.244-4.08.594-.208 1.127-.364 1.643-.347.977.225 1.528.83 1.798 1.385.639 2.308.804 4.574 4.114 4.916 2.837-.485 2.89-1.841 3.003-3.632-.049-.136-.22-.07-.343-.128l-.3.001c-.421.25-.172 1.398-.82 1.83-2.516.966-3.617-.785-3.094-2.668.24-.545.162-.587.487-.98.369-.265.478-.44 1.411-.836 1.447-.403 1.299-.031 1.934.184 2.092 1.165 3.82 2.64 6.388.523 1.647-2.361.719-3.352-.477-4.689-.131-.06-.204.108-.332.155l-.21.214c-.117.475.875 1.104.727 1.87-1.079 2.468-3.1 2.023-4.073.328-.22-.59-.387-1.12-.382-1.636.205-.981.799-1.545 1.349-1.826 2.294-.686 4.555-.897 4.83-4.213-.544-2.828-1.901-2.853-3.694-2.928-.135.051-.065.221-.12.345l.006.301c.26.415 1.402.142 1.848.781 1.016 2.495-.711 3.633-2.605 3.149-1.155-.646-1.622-.984-1.746-2.364a1.29 1.29 0 00-.145-.4c.02-.268.08-.514.162-.727 1.256-2.04 2.803-3.702.8-6.36-2.287-1.748-3.316-.864-4.704.273-.067.129.099.21.14.339l.204.22c.47.136 1.14-.827 1.9-.647 2.419 1.185 1.886 3.185.151 4.084-.598.195-1.135.338-1.65.31-.972-.247-1.51-.865-1.767-1.426-.586-2.322-.698-4.59-4-5.008z"
                ></path>
            </g>
        </svg>
    );
};
