import { CSSProperties } from 'react';
import KDHTMLInput from '../../../../../../components/general/kd-html-input/KDHtmlInput.component';
import renderFor from '../../../../../../utils/renderFor';
import { SurvivorSheetProps } from './core-survivor-sheet.component';
import GearGrid from './gear-grid/gear-grid.component';
import styles from './styles/core-survivor-sheet.module.scss';

interface SurvivorInfoSideBarProps extends SurvivorSheetProps {
    gearGrid?: boolean;
    style? : CSSProperties
}

export const SurvivorInfoSideBar = (props: SurvivorInfoSideBarProps) => {
    const lanternYearChanged = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        props.lanternYearChanged(parseInt(event.target.value));
    };

    const getPreviousButtonDisable = () => {
        if (props.neighbours.length === 0) return true;
        if (props.neighbours[props.neighbours.length - 1] === props.id)
            return true;
        return false;
    };

    const getNextButtonDisable = () => {
        if (props.neighbours.length === 0) return true;
        if (props.neighbours[0] === props.id) return true;
        return false;
    };

    const previousButtonClick = (event: React.MouseEvent<HTMLInputElement>) => {
        event.preventDefault();
        props.openSurvivor(props.neighbours[props.neighbours.length - 1]);
    };

    const nextButtonClick = (event: React.MouseEvent<HTMLInputElement>) => {
        event.preventDefault();
        props.openSurvivor(props.neighbours[0]);
    };

    const { stateValueWrapper } = props;

    if (!!props.gearGrid) {
        return (
            <div style={props.style}>
                <GearGrid stateValueWrapper={stateValueWrapper} />
            </div>
        );
    }

    return (
        <div id="survivor_info">
            <div id="survivor_year_field">
                <label htmlFor="survivor_year">Year</label>
                <select
                    name="survivor_year"
                    id="survivor_year"
                    onChange={lanternYearChanged}
                    value={stateValueWrapper.getInputValue('lantern_year')}
                >
                    {renderFor(1, 37, (index) => {
                        return (
                            <option key={index} value={index}>
                                {index}
                            </option>
                        );
                    })}
                </select>
            </div>
            {renderFor(0, 9, (index) => {
                const id = `survivor_gear_${index}`;
                const gearId = `gear_entry_${index}`;
                return (
                    <div className="survivor_gear" id={id} key={id}>
                        <label htmlFor={gearId}>{`Gear ${index + 1}`}</label>
                        <KDHTMLInput type="text" id={gearId} name={gearId} />
                    </div>
                );
            })}

            <div id="survivor_notes_field">
                <label htmlFor="survivor_notes">Notes</label>
                <textarea
                    id="survivor_notes"
                    name="survivor_notes"
                    onChange={stateValueWrapper.textAreaChange}
                    value={stateValueWrapper.getInputValue('survivor_notes')}
                ></textarea>
            </div>
            <div className={styles.survivorPagination}>
                <input
                    type="button"
                    value="<< Previous"
                    id="previous-survivor"
                    className={styles.button}
                    disabled={getPreviousButtonDisable()}
                    onClick={previousButtonClick}
                />
                <input
                    type="button"
                    value="Next >>"
                    id="next-survivor"
                    className={styles.button}
                    disabled={getNextButtonDisable()}
                    onClick={nextButtonClick}
                />
            </div>
        </div>
    );
};
