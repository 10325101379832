import React from 'react'
import './list-view-pagination.styles.scss'

interface ListViewPaginationProps {
    onChangePage(page: number): void
    maxCount: number | undefined
    currentPage: number
}

export default function ListViewPagination(props: ListViewPaginationProps) {
    const { maxCount, currentPage, onChangePage } = props
    if(!maxCount)
        return null;
    const totalPages = Math.ceil(maxCount / 40)

    if (totalPages <= 1) {
        return null;
    }

    let pages = []
    if (totalPages <= 8) {
        pages = Array.from(Array(totalPages + 1).keys())
        pages.shift()
    } else {
        pages.push(1, 2)
        if (!pages.includes(currentPage - 1) && currentPage > 1) {
            pages.push(currentPage - 1)
        }
        if (!pages.includes(currentPage)) {
            pages.push(currentPage)
        }
        if (!pages.includes(currentPage + 1) && currentPage < totalPages) {
            pages.push(currentPage + 1)
        }
        if (!pages.includes(totalPages - 1)) {
            pages.push(totalPages - 1)
        }
        if (!pages.includes(totalPages)) {
            pages.push(totalPages)
        }
        for (var i = pages.length - 1; i > 0; i--) {
            let targetPage = pages[i]
            var previous = targetPage - 1
            if (pages[i - 1] !== previous) {
                pages.splice(i, 0, 0)
                i--
            }
        }
    }

    const backClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        onChangePage(currentPage - 1)
    }

    const nextClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        onChangePage(currentPage + 1)
    }

    const settlementPage = (pageNumber:number, isActive:boolean)=>{
        const pageChangeClick = (event: React.MouseEvent<HTMLElement>)=>{
            event.preventDefault();
            onChangePage(pageNumber);
        }

        if(isActive){
            return (
                <a href='self' key={pageNumber} className='active' style={{pointerEvents:'none', cursor:'pointer'}}>{pageNumber}</a>
            )
        }
        return (
            <a href='self' key={pageNumber} onClick={pageChangeClick} style={{cursor:'pointer'}}>{pageNumber}</a>
        )
    }

    return (
        <div id="settlement_pagination">
            <div className="settlement_pagination">
                <a href="self" onClick={backClick} style={{cursor:'pointer', pointerEvents:currentPage<=1?'none':undefined, opacity:currentPage<=1?0.6:undefined}}>
                    &laquo;
                </a>

                {pages.map(page=>page?settlementPage(page, page===currentPage):(<span>...</span>))}

                <a href="self" onClick={nextClick} style={{cursor:'pointer', pointerEvents:currentPage>=totalPages?'none':undefined, opacity:currentPage>=totalPages?0.6:undefined}}>
                    &raquo;
                </a>
            </div>
        </div>
    )
}
