import React, { useContext } from 'react';
import KDHTMLInput from '../../../../../../components/general/kd-html-input/KDHtmlInput.component';
import renderFor from '../../../../../../utils/renderFor';
import styles from './survivor-bottom-gear-grid.module.scss';
import { StateValueWrapperContext } from '../../../../contexts/StateValueWrapperContext';

export default function SurvivorBottomGearGrid({ w }: { w?: string }) {
    return (
        <div className={styles.sideBySideContainer}>
            <SurvivorGearGrid w={w} />
            <SurvivorNotes/>
        </div>
    );
}

export function SurvivorNotes() {
    const context = useContext(StateValueWrapperContext);
    return (
        <div className={styles.notesContainer}>
            <p className={styles.title}>Notes</p>
            <textarea
                id="survivor_notes"
                name="survivor_notes"
                onChange={context!.textAreaChange}
                value={context!.getInputValue('survivor_notes')}
            ></textarea>
        </div>
    );
}

export function SurvivorGearGrid({ w }: { w?: string }) {
    return (
        <div className={styles.mainParent}>
            <p className={styles.title}>Departing Gear Grid</p>
            <div
                className={styles.gearContainer}
                style={{
                    width: w,
                }}
            >
                {renderFor(0, 9, (index) => {
                    const id = `survivor_gear_${index}`;
                    const gearId = `gear_entry_${index}`;
                    return (
                        <div className={styles.child} id={id} key={id}>
                            <label htmlFor={gearId}>{`Gear ${
                                index + 1
                            }`}</label>
                            <KDHTMLInput
                                type="text"
                                id={gearId}
                                name={gearId}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
