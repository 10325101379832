import { useInjection } from "inversify-react";
import { IAuthService } from "../../services/IAuthService";

export interface AdminOnlyProps{
    children? : React.ReactNode
}

export default function AdminOnly({children} : AdminOnlyProps){

    const authService = useInjection<IAuthService>('IAuthService')

    const isVisible = () => {
        return (
            authService.getAccessLevel('devops') >= 3 ||
            authService.getAccessLevel('data') >= 3 ||
            authService.getAccessLevel('auth') >= 3
        );
    };

    if(isVisible()){
        return <>{children}</>
    }

    return <></>
}