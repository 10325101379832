import IList from '../../../../models/IList';
import SettlementEntry from './SettlementEntry'

export default class SettlementList implements IList<SettlementEntry> {
    max_results!: number;
    settlements!: SettlementEntry[];

    getItems(): SettlementEntry[] {
        return this.settlements;
    }
}
