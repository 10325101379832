import { useInjection } from 'inversify-react';
import React from 'react';
import { IoPrintSharp } from 'react-icons/io5';
import { IAuthService } from '../../../../../../services/IAuthService';

interface SurvivorHeaderProps {
    onPrintSurvivorSheet: () => void;
    refreshSurvivor: () => void;
    updateSurvivorRequested: () => void;
    copySurvivorRequested: () => void;
    pasteSurvivorRequested: () => void;
    closeSurvivorRequested: () => void;
    deleteSurvivorRequested: () => void;
    pasteEnabled: boolean;
    saveEnabled: boolean;
    printEnabled : boolean;
    infoVisible?: boolean;
    infoClicked?: () => void;
}

export default function SurvivorHeader(props: SurvivorHeaderProps) {
    const authService = useInjection<IAuthService>('IAuthService');
    const dataAccess = authService.getAccessLevel('data');

    const onRefreshSurvivorClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        props.refreshSurvivor();
    };

    const onUpdateSurvivorClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        props.updateSurvivorRequested();
    };

    const onCopySurvivorClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        props.copySurvivorRequested();
    };

    const onPasteSurvivorClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        props.pasteSurvivorRequested();
    };

    const onDeleteSurvivorClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        props.deleteSurvivorRequested();
    };

    const onCloseSurvivorClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        props.closeSurvivorRequested();
    };

    return (
        <div className="toolbar toolbar-survival">
            <button
                title="Refresh Survivor"
                type="button"
                id="refresh_survivor_button"
                className="refresh_button nav_buttons"
                onClick={onRefreshSurvivorClick}
            >
                {' '}
            </button>
            {dataAccess >= 2 ? (
                <React.Fragment>
                    <div className="toolbar_divider left"></div>
                    <button
                        title="Save Survivor"
                        type="button"
                        id="save_survivor_button"
                        className="save_button nav_buttons"
                        onClick={onUpdateSurvivorClick}
                        disabled={!props.saveEnabled}
                    >
                        {' '}
                    </button>

                    <div className="toolbar_divider left"></div>
                    <button
                        title="Copy Survivor"
                        type="button"
                        id="copy_survivor_button"
                        className="copy_button nav_buttons"
                        onClick={onCopySurvivorClick}
                    >
                        {' '}
                    </button>

                    <div className="toolbar_divider left"></div>
                    <button
                        title="Paste Survivor"
                        type="button"
                        id="paste_survivor_button"
                        className="paste_button nav_buttons"
                        onClick={onPasteSurvivorClick}
                        disabled={!props.pasteEnabled}
                    >
                        {' '}
                    </button>
                </React.Fragment>
            ) : null}
            {dataAccess >= 3 ? (
                <React.Fragment>
                    <div className="toolbar_divider left"></div>
                    <button
                        title="Delete Survivor"
                        type="button"
                        id="delete_survivor_button"
                        className="delete_button nav_buttons"
                        onClick={onDeleteSurvivorClick}
                    >
                        {' '}
                    </button>
                </React.Fragment>
            ) : null}

            <div className="header-right">
                {props.infoVisible ? (
                    <React.Fragment>
                        <button
                            title="Info"
                            type="button"
                            id="settlement_info_button"
                            className="info_button"
                            onClick={(e) => {
                                e.preventDefault();
                                if (props.infoClicked) props.infoClicked();
                            }}
                        ></button>
                        <div className="toolbar_divider right"></div>
                    </React.Fragment>
                ) : (
                    ''
                )}
                <button
                    title="Print Settlement"
                    type="button"
                    className="print_button nav_buttons"
                    disabled={!props.printEnabled}
                    onClick={(e) => {
                        e.preventDefault();
                        props.onPrintSurvivorSheet();
                    }}
                >
                    <IoPrintSharp color="white" size={22} />
                </button>
                <button
                    title="Settlement"
                    type="button"
                    id="home_button"
                    className="home_button"
                    onClick={onCloseSurvivorClick}
                ></button>
            </div>
        </div>
    );
}
