import classes from 'utils/classes';
import styles from './HorizontalLine.module.scss'
import CSS from 'csstype';

export interface HorizontalLineProps extends CSS.Properties{
    classes? : string
}

export default function HorizontalLine(props: HorizontalLineProps){
    return <div style={props} className={classes(styles.line, props.classes)}></div>
}