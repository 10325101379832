import { useContext, useEffect, useState } from 'react';
import gearGridStyles from './gear-grid-styles.module.scss';
import { useInjection } from 'inversify-react';
import IDoDRESTClient from '../../../../../../../rest-clients/IDoDRESTClient';
import ITextureRegistry from '../../../../../../../services/ITextureRegistry';
import StateValueWrapper from '../../../../../models/stateFragments/StateValueWrapper';

export default function GearCard({ id, stateValueWrapper }: { id: string, stateValueWrapper : StateValueWrapper}) {
    const dodRESTClient = useInjection<IDoDRESTClient>('IDoDRESTClient');
    const textureRegistry = useInjection<ITextureRegistry>('ITextureRegistry');
    const [data, setData] = useState<string>();
    const context = stateValueWrapper;
    const [localValue, setLocalValue] = useState('')

    useEffect(()=>{
        const localValueUpdate = (id:string,value:string)=>{
            setLocalValue(value);
            console.log('new local value');
        }
        if(!!context){
            setLocalValue(context.getInputValue(id));
            context.subscribeInput(id, localValueUpdate)
        }

        return function cleanup(){
            context?.unSubscribeInput(id, localValueUpdate);
        }
    }, [stateValueWrapper])

    useEffect(()=>{
        setData('');
        dodRESTClient.searchForCard(localValue).then((x) => {
            if (!!x) {
                if (x.items.length > 0 && !!x.items[0] && !!x.items[0].card) {
                    let url = x.items[0].card.card_url;
                    url = url.replace(
                        'https://www.googleapis.com/storage/v1/b/database-of-death-bucket/o/',
                        ''
                    );
                    url = window.decodeURIComponent(url);
                    textureRegistry.getTextureDataCallbackSingleBuffer(
                        url,
                        (data) => {
                            if (!!data) {
                                setData(data);
                            }
                        }
                    );
                }
            }
        });
    },[localValue])


    useEffect(() => {
        const localValueUpdate = (id:string,value:string)=>{
            setLocalValue(value);
            console.log('new local value');
        }
        if(!!context){
            setLocalValue(context.getInputValue(id));
            context.subscribeInput(id, localValueUpdate)
        }

        return function cleanup(){
            console.log('clean up');
            context?.unSubscribeInput(id, localValueUpdate);
        }
    }, []);

    useEffect(() => {}, [data]);

    return (
        <div className={gearGridStyles.container}>
            <div
                className={gearGridStyles.item}
                style={{
                    backgroundImage: `url('data:image/png;base64,${data}')`,
                }}
            />
        </div>
    );
}
