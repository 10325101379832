import { useInjection } from 'inversify-react';
import { IAuthService } from '../../services/IAuthService';
import { CSSProperties } from 'react';

export interface LogOutProps {
    style? : CSSProperties,
    className? : string;
}

export default function LogOut({style, className} : LogOutProps) {
    const authService = useInjection<IAuthService>('IAuthService');

    return (
        <a
            href="#out"
            className={className}
            style={style}
            onClick={(e) => {
                e.preventDefault();
                authService.logout();
                window.location.replace('https://auth.kingdomdeath.com/login');
            }}
        >
            Log Out
        </a>
    );
}
