import { useEffect, useState } from 'react';
import styles from './account-linking.module.scss';
import ShopifyLinking from './shopify/shopify-linking.component';
import CustomerLinkedAccounts from '../../../../models/linkedAccount/CustomerLinkedAccount';
import { useInjection } from 'inversify-react';
import IAuthManagementRESTClient from '../../../../rest-clients/IAuthManagementRESTClient';
import CustomerModal from '../../modal/customer-modal.component';
import IUIService from '../../../../services/IUIService';

export default function AccountLinking() {
    const [linkDetails, setLinkDetails] =
        useState<CustomerLinkedAccounts | null>(null);

    const [localLoading, setLocalLoading] = useState<boolean>(false)
    const authManagementRESTClient = useInjection<IAuthManagementRESTClient>(
        'IAuthManagementRESTClient'
    );
    const uiService = useInjection<IUIService>('IUIService')

    useEffect(() => {
        setLocalLoading(true);
        authManagementRESTClient.getLinkedAccounts().then((x) => {
            setLinkDetails(x);
        }).finally(()=>{
            setLocalLoading(false);
        });
    }, []);

    useEffect(() => {}, [linkDetails]);

    const removeLinking = async (strategy: string) => {
        uiService.showLoading();
        setLocalLoading(true);

        const response = await authManagementRESTClient.removeLinkedAccount(strategy);

        if(!!response){
            uiService.showSuccessNotification('Removed Shopify account!');
            const details = await authManagementRESTClient.getLinkedAccounts();
            setLinkDetails(details);
        }
        else{
            uiService.showErrorNotification('Failed to remove Shopify account!');
        }
        setLocalLoading(false);
        uiService.hideLoading();
    };

    return (
        <CustomerModal header="Linked Accounts" loading={localLoading}>
            <div className={styles.baseContainer}>
                <div className={styles.linkingContainer}>
                    <ShopifyLinking link={()=>setLocalLoading(true)} linkEmail={linkDetails?.shopify_email} unlink={() => removeLinking('shopify')} />
                </div>
            </div>
        </CustomerModal>
    );
}
