import React from 'react';
import SurvivorPage from './survivor-page.component';

interface SurvivorPaginationProps {
    page: number;
    maxCount: number;
    updatePage: (page: number) => void;
    itemsPerPage?:number
}

export default function SurvivorPagination(props: SurvivorPaginationProps) {
    let {itemsPerPage} = props;

    if(!itemsPerPage){
        itemsPerPage = 24;
    }

    const totalPages = Math.ceil(props.maxCount / itemsPerPage);
    const getPages = () => {
        if (totalPages <= 1) return;
        let pages = [];
        if (totalPages <= 8) {
            pages = Array.from(Array(totalPages + 1).keys());
            pages.shift();
        } else {
            pages.push(1, 2);
            if (!pages.includes(props.page - 1) && props.page > 1) {
                pages.push(props.page - 1);
            }
            if (!pages.includes(props.page)) {
                pages.push(props.page);
            }
            if (!pages.includes(props.page + 1) && props.page < totalPages) {
                pages.push(props.page + 1);
            }
            if (!pages.includes(totalPages - 1)) {
                pages.push(totalPages - 1);
            }
            if (!pages.includes(totalPages)) {
                pages.push(totalPages);
            }
            for (let i = pages.length - 1; i > 0; i--) {
                const currentPage = pages[i];
                var previous = currentPage - 1;
                if (pages[i - 1] !== previous) {
                    pages.splice(i, 0, 0);
                    i--;
                }
            }
        }
        return pages;
    };

    const pageChange = (newPage: number) => {
        props.updatePage(newPage);
    };

    const getBackButton = () => {
        if (totalPages <= 1) return null;
        return (
            <a
                href="self"
                onClick={(e) => {
                    e.preventDefault();
                    pageChange(props.page - 1);
                }}
                style={{
                    cursor: 'pointer',
                    pointerEvents: props.page <= 1 ? 'none' : undefined,
                }}
            >
                &laquo;
            </a>
        );
    };

    const getNextButton = () => {
        if (totalPages <= 1) return null;
        return (
            <a
                href="self"
                onClick={(e) => {
                    e.preventDefault();
                    pageChange(props.page + 1);
                }}
                style={{
                    cursor: 'pointer',
                    pointerEvents: props.page <= 1 ? 'none' : undefined,
                }}
            >
                &raquo;
            </a>
        );
    };

    const pages = getPages();

    return (
        <div id="survivor_pagination">
            <div className="pagination">
                <React.Fragment>
                    {getBackButton()}
                    {pages === undefined
                        ? ''
                        : pages.map((page) =>
                              page ? (
                                  <SurvivorPage
                                      key={page}
                                      page={page}
                                      isActive={page === props.page}
                                      pageChange={pageChange}
                                  />
                              ) : (
                                  <span>...</span>
                              )
                          )}
                    {getNextButton()}
                </React.Fragment>
            </div>
        </div>
    );
}
