import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { inject, injectable } from 'inversify';
import IDPayload from '../../models/IDPayload';
import type { IAuthService } from '../../services/IAuthService';
import IDoDRESTClient from '../IDoDRESTClient';
import { RESTClient } from './RESTClient';
import { ErrorMessage } from '../../models/ErrorMessage';

@injectable()
export default class DoDRESTClient
    extends RESTClient
    implements IDoDRESTClient
{
    @inject('IAuthService')
    private readonly authService!: IAuthService;

    private readonly base2Url =
        'https://wod-dod-uux56memxa-uc.a.run.app/api/v2/';

    constructor() {
        super();
        this.setBaseURL('https://wod-dod-uux56memxa-uc.a.run.app/api/v1/');
    }
    
    
    async searchForCard(value: string): Promise<any> {
        return {}
    }

   

    
    protected getFullURLV2(
        url: string,
        params: Map<string, string> = new Map<string, string>()
    ): string {
        let targetUrl = this.base2Url + url;
        let currentParam = 0;
        params.forEach((value: string, key: string) => {
            if (currentParam === 0) {
                targetUrl += '?';
            } else targetUrl += '&';

            targetUrl += key;
            targetUrl += '=';
            targetUrl += value;

            currentParam++;
        });
        return targetUrl;
    }
}
