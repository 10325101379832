import { inject, injectable } from "inversify";
import Nullable from "../../models/Nullable";
import Pledge from "../../models/treasury/Pledge";
import type { IAuthService } from "../../services/IAuthService";
import ITreasuryRESTClient from "../ITreasuryRESTClient";
import { RESTClient } from "./RESTClient";
import axios from "axios";
import DiscountRequestPayload from "../../models/treasury/discount/DiscountRequestPayload";

@injectable()
export default class TreasuryRESTClient extends RESTClient implements ITreasuryRESTClient{
    @inject('IAuthService')
    private authService!: IAuthService;

    constructor() {
        super();

        this.setBaseURL('https://wod-treasury-uux56memxa-uc.a.run.app/api/v1/');
        //this.setBaseURL('https://localhost:44342/api/v1/');
    }

    async getPledges() : Promise<Nullable<Pledge[]>> {
        const token = await this.authService.getToken();
        if (token === null) return [];

        const transToken = await this.getToken(token);

        if (!transToken) return [];

        const iss = await this.authService.getISS();

        if (!iss) return [];

        const url = this.getFullURL(`customer/${iss}/pledges`);

        try {
            const response = await axios.get(url, this.getHeader(transToken));
            return response.data as Pledge[];
        } catch {
            return []
        }
    }

    async getDiscounts(payload : DiscountRequestPayload) : Promise<string[]>{
        const token = await this.authService.getToken();
        if (token === null) return [];

        const treasuryToken = await this.getToken(token);

        if (!treasuryToken) return [];

        const url = this.getFullURL(`discount`);

        try {
            const response = await axios.post(url, JSON.stringify(payload), this.getHeaderJson(this.getHeader(treasuryToken)));
            return response.data as string[];
        } catch {
            return []
        }
    }
}