import axios from 'axios';
import { inject, injectable } from 'inversify';
import ProductKeyTitle from '../../models/trans/ProductKeyNameModel';
import type { IAuthService } from '../../services/IAuthService';
import ITransRESTClient from '../ITransRESTClient';
import { RESTClient } from './RESTClient';

@injectable()
export default class TransRESTClient
    extends RESTClient
    implements ITransRESTClient
{
    @inject('IAuthService')
    private authService!: IAuthService;

    constructor() {
        super();

        this.setBaseURL('https://wod-trans-uux56memxa-uc.a.run.app/api/v1/');
        //this.setBaseURL('https://localhost:44342/api/v1/');
    }
    

    async processProductKey(key: string): Promise<boolean> {
        const token = await this.authService.getToken();
        if (token === null) return false;

        const transToken = await this.getToken(token);

        if (!transToken) return false;

        const form = new FormData();
        form.set('productKey', key);

        const iss = await this.authService.getISS();

        if (!iss) return false;

        const url = this.getFullURL(`customer/${iss}/product-key`);

        try {
            await axios.put(url, form, this.getHeader(transToken));
            return true;
        } catch {
            return false;
        }
    }

    async validateProductKey(key: string): Promise<boolean> {
        const url = this.getFullURL(`product-key/valid`);

        try {
            const body = {
                product_key : key
            };
            const response = await axios.post(url, body, this.getHeaderJson());
            const responseParsed =  response.data as {
                isValid : boolean
            };
            return responseParsed.isValid;
        } catch {
            return false;
        }
    }

    async getActiveProducts(): Promise<string[]> {
        const token = await this.authService.getToken();
        if (token === null) return [];

        const transToken = await this.getToken(token);

        if (!transToken) return [];

        const iss = await this.authService.getISS();

        if (!iss) return [];

        const url = this.getFullURL(`customer/${iss}/purchases`);

        try {
            const response = await axios.get(url, this.getHeader(transToken));
            return response.data as string[];
        } catch {
            return [];
        }
    }

    async getProductKeys(): Promise<ProductKeyTitle[]> {
        const token = await this.authService.getToken();
        if (token === null) return [];

        const transToken = await this.getToken(token);

        if (!transToken) return [];

        const iss = await this.authService.getISS();

        if (!iss) return [];

        const url = this.getFullURL(`customer/${iss}/product-key`);

        try {
            const response = await axios.get(url, this.getHeader(transToken));
            return response.data as ProductKeyTitle[];
        } catch {
            return [];
        }
    }
}
