import styles from './ark-first-page.module.scss';
import baseStyles from '../styles/core-settlement.module.scss';
import classes from '../../../../../../utils/classes';
import renderFor, { renderForEach } from '../../../../../../utils/renderFor';
import KDHTMLInput from '../../../../../../components/general/kd-html-input/KDHtmlInput.component';
import rowCol from '../../../../../../utils/rowCol';
import KDHTMLCustomCheckbox from '../../../../../../components/general/kd-html-input/KDHtmlCustomCheckbox';
import FrontSheetHeader from '../../../elements/sheets/sheet-header/front-sheet-header.component';
import SheetTimeLineTable from '../../../elements/sheets/sheet-timeline/sheet-timeline.component';
import SheetQuarries from '../../../elements/sheets/sheet-quarries/sheet-quarries.component';
import SheetMileStones from '../../../elements/sheets/sheet-milestones/sheet-milestones.component';
import SheetParagraph from '../../../elements/sheets/sheet-paragraphs/sheet-paragraph.component';

interface INemesisMonster{
    label? : string,
    id? : string
}

interface INemesisMonstersProps {
    nemesisMonsters: INemesisMonster[];
    layout?: 'default' | 'ark';
}

const SheetNemesisMonsters = ({
    nemesisMonsters,
    layout = 'default',
}: INemesisMonstersProps) => {
    const numberOfLines = layout === 'ark' ? 6 : 5;
    return (
        <div>
            <div
                className={classes(
                    styles.nemesisMonstersContainer,
                    styles[layout]
                )}
            >
                <div className={styles.header}>
                    <p className={styles.headerTitle}>Nemesis Monsters</p>
                    <p className={styles.headerHelper}>
                        The available nemesis encounter monsters.
                    </p>
                </div>

                {renderFor(0, numberOfLines, (index) => {
                    const top = `${index * 22.8 + 73}px`;
                    return (
                        <div
                            key={index}
                            className={styles.horizontalLine}
                            style={{ top: top }}
                        ></div>
                    );
                })}

                {renderForEach(nemesisMonsters, (element, index) => {
                    const top = `${52 + index * 23}px`;
                    return (
                        <div
                            key={index}
                            className={styles.nemesisMonsterRow}
                            style={{ top: top }}
                        >
                            {!element.id && <p className={styles.nemesisMonster}>{element.label}</p>}
                            {!!element.id && <KDHTMLInput className={styles.nemesisInput} type='text' id={element.id}/>}

                            {renderFor(0, 3, (index) => {
                                const left = `${135 + index * 38}px`;
                                return (
                                    <p
                                        key={index}
                                        className={styles.nemesisLvl}
                                        style={{
                                            left: left,
                                        }}
                                    >
                                        Lvl {index + 1}
                                    </p>
                                );
                            })}
                        </div>
                    );
                })}

                {renderFor(0, 24, (index) => {
                    const id = `nemesis_check_${index}`;
                    let currentClass = classes(
                        baseStyles.checkboxContainer,
                        styles.nemesisCheckboxContainer
                    );
                    let top = '';
                    let left = '';
                    const { row, col } = rowCol(index, 3);
                    top = `${13 + row * 23}px`;
                    if (index < 18 && index >= 0) {
                        currentClass = classes(currentClass, 'small');
                        left = `${125 + col * 38}px`;
                    } else {
                        top = `${
                            13 + (index >= 18 ? index - 18 : index) * 23
                        }px`;
                        currentClass = classes(currentClass, 'large');
                    }

                    return (
                        <KDHTMLCustomCheckbox
                            containerClass={currentClass}
                            key={`label_${id}`}
                            id={id}
                            name={id}
                            containerStyle={{ top: top, left: left }}
                            checked={index === -1}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default function ArkFirstPage() {
    const quarries = [
        { id: 0, label: 'Crimson Crocodile' },
        { id: 1, label: 'Smog Singers' },
        { id: 2, label: 'Phoenix' },
        { id: 3, label: 'The King' },
    ];

    const mileStones = [
        {
            id: 0,
            label: 'Population reaches 0',
            principle: 'Game Over',
        },
        {
            id: 1,
            label: 'Population reaches 15',
            principle: 'Principle: Society',
        },
        {
            id: 2,
            label: 'First child is born',
            principle: 'Principle: New Life',
        },
        {
            id: 3,
            label: 'First time death count is updated',
            principle: 'Principle: Death',
        },
        {
            id: 4,
            label: 'First survivor to reach 3 understanding',
            principle: 'Designs & Dandelions',
        },
    ];

    const nemesisMonsters = [
        {
            label : 'Butcher'
        },
        {
            label : 'Atnas'
        },
        {
            label : 'The Hand'
        },
        {
            id: 'nemesis_0'
        },
        {
            id: 'nemesis_1'
        },
        {
            id: 'nemesis_2'
        }
    ]

    return (
        <div className={classes(baseStyles.pageContainer, styles.ark)}>
            <div className={baseStyles.innerContainer}>
                <FrontSheetHeader layout="ark" />
                <div className={styles.twoCol}>
                    <SheetTimeLineTable
                        sheetId="ark"
                        layout="small"
                        numberOfColumns={1}
                        columns={['Year', 'Events']}
                    />
                    <div className={styles.secondCol}>
                        <SheetQuarries
                            layout="ark"
                            quarries={quarries}
                            idPrefix="quarry_check_"
                            emptyLines={renderFor(0, 2, (index) => {
                                const id = `quarry_${index}`;
                                const checkboxId = `quarry_check_${index+4}`;

                                return (
                                    <div
                                        key={index}
                                        className={classes(
                                            styles.emptyLine,
                                            styles.quarryEmptyLine
                                        )}
                                    >
                                        <KDHTMLCustomCheckbox
                                            id={checkboxId}
                                            containerClass={
                                                baseStyles.checkboxContainer
                                            }
                                        />
                                        <KDHTMLInput
                                            key={index}
                                            className={styles.emptyLineInput}
                                            type="text"
                                            id={id}
                                            name={id}
                                        />
                                    </div>
                                );
                            })}
                        />
                        <SheetNemesisMonsters
                            nemesisMonsters={nemesisMonsters}
                            layout="ark"
                        />
                        <div className={styles.sheetMileStonesContaineer}>
                            <SheetMileStones
                                milestones={mileStones}
                                idPrefix="mile_check_"
                                layout="vertical"
                                title="Campaign Milestones"
                                emptyLines={renderFor(0, 9, (index) => {
                                    const idText = `milestones_${index}`;
                                    return (
                                        <div
                                            key={index}
                                            className={styles.emptyLine}
                                        >
                                            <KDHTMLCustomCheckbox
                                            id={`mile_check_${index+5}`}
                                                containerClass={
                                                    baseStyles.checkboxContainer
                                                }
                                            />
                                            <KDHTMLInput
                                                key={index}
                                                className={
                                                    styles.emptyLineInput
                                                }
                                                type="text"
                                                id={idText}
                                                name={idText}
                                            />
                                        </div>
                                    );
                                })}
                            />
                        </div>
                        <div className={styles.arkBonuses}>
                            <div className={styles.bonusesTitle}>
                                Departing Survivor Bonuses
                            </div>
                            <p className={styles.bonusesSubTitle}>
                                Departing survivors gain these bonuses
                            </p>
                            <div className={styles.bonusesHorizontalLine}></div>
                            <SheetParagraph
                                idPrefix="survivor_bonuses_"
                                helperText=""
                                totalLines={4}
                                linesPerColumn={4}
                                lineHeight={20}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
