import React, { useState } from 'react';
import styles from '../survivor-list.module.scss';
import baseStyles from '../../sheets/styles/core-settlement.module.scss';
import thirdPageStyles from '../../sheets/ark-settlement/ark-third-page.module.scss';
import SurvivorListEntry, { SurvivorListArkEntry } from '../../../../models/survivors/SurvivorListEntry';
import classes from '../../../../../../utils/classes';
import conditionalClass from '../../../../../../utils/conditional-class';
import KDHTMLInput from '../../../../../../components/general/kd-html-input/KDHtmlInput.component';
import KDHTMLCustomCheckbox from '../../../../../../components/general/kd-html-input/KDHtmlCustomCheckbox';


interface SurvivorListArkEntryProps {
    data?: SurvivorListEntry;
    openSurvivor: (id: number) => void;
}

export default function SurvivorListArkEntryComponent(
    props: SurvivorListArkEntryProps
) {
    const { data } = props;

    const dataArk = data as SurvivorListArkEntry;

    const [hovered, setHovered] = useState(false);

    const buttonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (!data) return;
        props.openSurvivor(data.id);
    };

    const mouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (!data) return;
        setHovered(true);
    };

    const mouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (!data) return;
        setHovered(false);
    };

    const huntXPText = !!dataArk ? `${dataArk.hunt_xp}` : '';
    const rankText = !!dataArk ? `${dataArk.rank}` : '';

    const isDead = data?.dead ===1;

    return (
        <div className={thirdPageStyles.rowContainer}>
            <div
                className={classes(
                    thirdPageStyles.row,
                    conditionalClass(
                        data?.dead === 1,
                        styles.deadSurvivor,
                        undefined
                    )
                )}
                onMouseEnter={mouseEnter}
                onMouseLeave={mouseLeave}
            >
                <div
                    className={classes(
                        thirdPageStyles.survivorName,
                        thirdPageStyles.rowItem
                    )}
                >
                    {!!data && (
                        <button
                            onClick={buttonClick}
                            className={styles.view_survivor_button}
                            style={{
                                visibility: hovered ? 'visible' : 'hidden',
                            }}
                        />
                    )}
                    <KDHTMLInput
                        value={!!data?`${data?.survivor_name} (${data?.lantern_year})`:undefined}
                        type="text"
                        disabled
                    />
                </div>
                <div
                    className={classes(
                        thirdPageStyles.survivorSex,
                        thirdPageStyles.rowItem,
                        thirdPageStyles.smallLabel
                    )}
                >
                    <KDHTMLInput
                        type="text"
                        value={
                            !!data ? (data.survivor_sex === 1 ? 'F' : 'M') : ''
                        }
                        disabled
                    />
                </div>
                <div
                    className={classes(
                        thirdPageStyles.survivorHuntXP,
                        thirdPageStyles.rowItem,
                        thirdPageStyles.smallLabel
                    )}
                >
                    <KDHTMLInput type="text" value={huntXPText} disabled />
                </div>
                <div
                    className={classes(
                        thirdPageStyles.survivorPhilosophy,
                        thirdPageStyles.rowItem
                    )}
                >
                    <KDHTMLInput
                        type="text"
                        value={dataArk?.philosophy}
                        disabled
                    />
                </div>
                <div
                    className={classes(
                        thirdPageStyles.survivorRank,
                        thirdPageStyles.rowItem,
                        thirdPageStyles.smallLabel
                    )}
                >
                    <KDHTMLInput type="text" value={rankText} disabled />
                </div>
                <div
                    className={classes(
                        thirdPageStyles.survivorNotes,
                        thirdPageStyles.rowItem
                    )}
                >
                    <KDHTMLInput type="text" value={data?.notes} disabled />
                </div>
                <div
                    className={classes(
                        thirdPageStyles.survivorRetired,
                        thirdPageStyles.rowItem
                    )}
                >
                    <KDHTMLCustomCheckbox
                        containerClass={classes(
                            baseStyles.checkboxContainer,
                            styles.survivorCheckbox
                        )}
                        disabled
                        checked={dataArk?.retired === 1}
                    />
                </div>
                <div
                    className={classes(
                        thirdPageStyles.survivorDead,
                        thirdPageStyles.rowItem
                    )}
                >
                    <KDHTMLCustomCheckbox
                        containerClass={classes(
                            baseStyles.checkboxContainer,
                            styles.survivorCheckbox
                        )}
                        disabled
                        checked={data?.dead === 1}
                    />
                </div>
            </div>
            {isDead && <div className={thirdPageStyles.strikeLine}/>}
        </div>
    );
}
