import CustomerCPNavigation from './customer-cp-navigation/customer-cp-navigation.component';
import styles from './customer-cp.module.scss';
import { useLocation } from 'react-router';
import CustomerPurchases from './customer-purchases/customer-purchases.component';
import CustomerAccount from './customer-account/customer-account.component';
import CustomerRecords from './customer-records/customer-records.component';
import classes from '../../utils/classes';

import { CustomerCPPagesProps } from '../../pages/customer-cp-page/CustomerCPPage';
import { useInjection } from 'inversify-react';
import INavigationDataService from '../../services/INavigationDataService';
import CustomerRewards from './customer-rewards/CustomerRewards';

export default function CustomerCPComponent({
    productKey,
}: CustomerCPPagesProps) {
    const { hash } = useLocation();
    const navigationDataService = useInjection<INavigationDataService>('INavigationDataService')
    

    const content = (function () {
        const route = hash.substring(1);
        const component = navigationDataService.getRouteComponent(route);

        const componentName = !!component?component.component:'CustomerAccount';

        switch (componentName) {
            case 'CustomerAccount':
                return <CustomerAccount />;
            case 'CustomerRecords':
                return <CustomerRecords />;
            case 'CustomerRewards':
                return <CustomerRewards/>;
            default:
                return <CustomerPurchases productKey={productKey} />;
        }
    })();

    return (
        <div className={styles.main}>
            <div
                className={classes(
                    styles.sideNavContainer
                )}
            >
                <CustomerCPNavigation hash={hash} />
            </div>
            <div className={styles.content}>{content}</div>
        </div>
    );
}
