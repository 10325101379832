import { useInjection } from 'inversify-react';
import React from 'react';
import { IAuthService } from '../../../../../../services/IAuthService';
import Toolbar, {
    ToolbarSeparator,
} from '../../../toolbar/Toolbar.component';
import AvailabilityOfEditing from '../../../../utils/availabilityOfEdit';
import { IoPrintSharp } from 'react-icons/io5';

interface SurvivorHeaderProps {
    onPrintSurvivorSheet(): void;
    refreshSurvivor: () => void;
    updateSurvivorRequested: () => void;
    copySurvivorRequested: () => void;
    pasteSurvivorRequested: () => void;
    closeSurvivorRequested: () => void;
    deleteSurvivorRequested: () => void;
    pasteEnabled: boolean;
    saveEnabled: boolean;
    infoVisible?: boolean;
    infoClicked?: () => void;
}

export default function SurvivorHeaderClient(props: SurvivorHeaderProps) {
    const authService = useInjection<IAuthService>('IAuthService');
    const dataAccess = authService.getAccessLevel('data');

    const onRefreshSurvivorClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        props.refreshSurvivor();
    };

    const onUpdateSurvivorClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        props.updateSurvivorRequested();
    };

    const onCopySurvivorClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        props.copySurvivorRequested();
    };

    const onPasteSurvivorClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        props.pasteSurvivorRequested();
    };

    const onDeleteSurvivorClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        props.deleteSurvivorRequested();
    };

    const onCloseSurvivorClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        props.closeSurvivorRequested();
    };

    const availabilityOfEditing = AvailabilityOfEditing();

    const availableEditing = availabilityOfEditing.edit;
    const availableDelete = availabilityOfEditing.delete;

    return (
        <Toolbar style={{ float: 'none', width: 'auto' }}>
            <Toolbar.Left>
                <button
                    title="Refresh Survivor"
                    type="button"
                    id="refresh_survivor_button"
                    className="refresh_button nav_buttons"
                    onClick={onRefreshSurvivorClick}
                >
                    {' '}
                </button>
                {availableEditing ? (
                    <React.Fragment>
                        <ToolbarSeparator />
                        <button
                            title="Save Survivor"
                            type="button"
                            id="save_survivor_button"
                            className="save_button nav_buttons"
                            onClick={onUpdateSurvivorClick}
                            disabled={!props.saveEnabled}
                        >
                            {' '}
                        </button>

                        <ToolbarSeparator />
                        <button
                            title="Copy Survivor"
                            type="button"
                            id="copy_survivor_button"
                            className="copy_button nav_buttons"
                            onClick={onCopySurvivorClick}
                        >
                            {' '}
                        </button>

                        <ToolbarSeparator />
                        <button
                            title="Paste Survivor"
                            type="button"
                            id="paste_survivor_button"
                            className="paste_button nav_buttons"
                            onClick={onPasteSurvivorClick}
                            disabled={!props.pasteEnabled}
                        >
                            {' '}
                        </button>
                    </React.Fragment>
                ) : null}
                {availableDelete && dataAccess >= 3 ? (
                    <React.Fragment>
                        <ToolbarSeparator />
                        <button
                            title="Delete Survivor"
                            type="button"
                            id="delete_survivor_button"
                            className="delete_button nav_buttons"
                            onClick={onDeleteSurvivorClick}
                        >
                            {' '}
                        </button>
                    </React.Fragment>
                ) : null}
            </Toolbar.Left>
            <Toolbar.Right>
                {!!props.infoVisible && (
                    <React.Fragment>
                        <button
                            title="Info"
                            type="button"
                            id="settlement_info_button"
                            className="info_button"
                            onClick={(e) => {
                                e.preventDefault();
                                if (props.infoClicked) props.infoClicked();
                            }}
                        ></button>
                        <ToolbarSeparator />
                    </React.Fragment>
                )}
                <button
                    title="Print Settlement"
                    type="button"
                    className="nav_buttons"
                    id="print_button"
                    onClick={(e) => {
                        e.preventDefault();
                        props.onPrintSurvivorSheet();
                    }}
                >
                    <IoPrintSharp style={{marginLeft:'-2px'}} color="white" size={22} />
                </button>
                <ToolbarSeparator />
                <button
                    title="Settlement"
                    type="button"
                    id="home_button"
                    className="home_button"
                    onClick={onCloseSurvivorClick}
                ></button>
            </Toolbar.Right>
        </Toolbar>
    );
}
