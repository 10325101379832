import { injectable } from "inversify";
import BaseMapper, { MapperBase } from "../../mappers/BaseMapper";
import ArkSettlementSheetMapper from "../../mappers/settlementSheets/ArkSettlementSheetMapper";
import CoreSettlementSheetMapper from "../../mappers/settlementSheets/CoreSettlementSheetMapper";
import { SettlementType } from "../../models/settlements/SettlementType";
import { ISettlementModelMapperService } from "../ISettlementModelMapperService";
import PoTStarSettlementSheetMapper from "../../mappers/settlementSheets/PoTStarSettlementSheetMapper";
import PoTSunSettlementSheetMapper from "../../mappers/settlementSheets/PoTSunSettlementSheetMapper";

@injectable()
export default class SettlementModelMapperService implements ISettlementModelMapperService {
    private mappers : Map<SettlementType, MapperBase>;

    constructor(){
        this.mappers = new Map<SettlementType,MapperBase>();
        this.mappers.set(SettlementType.Core, new CoreSettlementSheetMapper());
        this.mappers.set(SettlementType.Ark, new ArkSettlementSheetMapper());
        this.mappers.set(SettlementType.POTStar, new PoTStarSettlementSheetMapper());
        this.mappers.set(SettlementType.POTSun, new PoTSunSettlementSheetMapper());
    }

    mapFromModel<Model,State>(from : any, type:SettlementType) : State | null{
        console.log(type);
        const mapper =  this.mappers.get(type)
        if(mapper){
            return (mapper as BaseMapper<Model,State>).mapToState(from as Model);
        }
        return null;
    }

    mapFromState<Model,State>(from : State, type:SettlementType) : Model | null{
        console.log(type);
        const mapper =  this.mappers.get(type)
        if(mapper){
            return (mapper as BaseMapper<Model,State>).mapFromState(from);
        }
        return null;
    }
} 
