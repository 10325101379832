import classes from '../../utils/classes';
import styles from './login-panel-header.module.scss'

export default function LoginPanelHeader() {
    const secondRowClasses = classes(styles.headerText, styles.second);
    return (
        <div className={styles.panelHeader}>
            <div className={styles.headerText}>KINGDOM DEATH</div>
            <div className={secondRowClasses}>-SIMULATOR-</div>
        </div>
    );
}
