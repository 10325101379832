import 'reflect-metadata';
import React from 'react';
import './App.scss';
import { Container } from 'inversify';
import { Provider } from 'inversify-react';
import { Provider as ReduxProvider } from 'react-redux';
import PageWithHeader from './pages/page-with-header/PageWithHeader';
import store from './redux/store';
import LoadingOverlay from './components/loading-container/loading-container.component';
import ConfirmDialog from './components/confirm-dialog/confirm-dialog.component';
import BootStrapRecordSheets from './modules/record-sheets-module/bootstrapRecordSheet';
import { installServices, installRESTClients } from './pages/bootstrapper';

class App extends React.Component {
    private readonly container: Container = new Container();

    constructor(props: {}) {
        super(props);
        installRESTClients(this.container);
        installServices(this.container);
        BootStrapRecordSheets(this.container);
    }

    render() {
        return (
            <>
                <ReduxProvider store={store}>
                    <Provider container={this.container}>
                        <React.Fragment>
                            <PageWithHeader />
                            <LoadingOverlay />
                            <ConfirmDialog />
                        </React.Fragment>
                    </Provider>
                </ReduxProvider>
            </>
        );
    }
}

export default App;
