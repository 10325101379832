import { injectable } from "inversify";
import IRefreshHandlerService from "../IRefreshHandlerService";

@injectable()
export default class RefreshHandlerService implements IRefreshHandlerService{
    private refreshCallback? : ()=>void;
    
    registerRefreshCallback = (refreshCallback: () => void) => {
       this.refreshCallback = refreshCallback;
    }
    emitRefresh = () => {
        if(!!this.refreshCallback){
            this.refreshCallback();
        }
    }

}