import classes from '../../../../utils/classes';
import styles from './ShopifyButton.module.scss';

export interface ShopifyButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>{
    children : React.ReactNode;
    noIcon? : boolean;
    fakeDisable? : boolean;
}

export default function ShopifyButton(props: ShopifyButtonProps) {
    const children = props.children;
    const className = props.className;
    delete props.className;
    //delete props.children;
    return (
        <button {...props} className={classes(styles.shopifyButton, className, !!props.fakeDisable ? styles.fakeDisable : undefined)}>
            <div className={styles.flex}>
                {!props.noIcon && <div className={styles.shopifyIcon}></div>}
                {children}
            </div>
        </button>
    );
}
