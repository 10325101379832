import { DKIcon } from '../../../../../../components/icons';
import classes from '../../../../../../utils/classes';
import styles from './sheet-timeline.module.scss';

export interface TimeLineLabels {
    sheetId: string;
    list: {
        id: string | null;
        label: React.ReactNode | null;
        editable? : boolean
    }[];
}

export const timeLineLabels: TimeLineLabels[] = [
    {
        sheetId: 'core',
        list: [
            {
                id: 'time_0',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        First Day. <span className={styles.icon}>s</span>{' '}
                        Returning Survivors
                    </>
                ),
            },
            {
                id: 'time_1',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.icon}>s</span> Endless Screams
                    </>
                ),
            },
            {
                id: 'time_2',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_3',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.icon}>n</span> Nemesis Encounter
                        - Butcher Lvl 1
                    </>
                ),
            },
            {
                id: 'time_4',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.icon}>s</span> Hands of Heat
                    </>
                ),
            },
            {
                id: 'time_5',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.icon}>s</span> Armored Strangers
                    </>
                ),
            },
            {
                id: 'time_6',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.icon}>s</span> Phoenix Feather
                    </>
                ),
            },
            {
                id: 'time_7',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_8',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.icon}>n</span> Nemesis Encounter
                        - King's Man Lvl 1
                    </>
                ),
            },
            {
                id: 'time_9',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_10',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.icon}>s</span> Regal Visit
                    </>
                ),
            },
            {
                id: 'time_11',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.icon}>s</span> Principle
                        Conviction
                    </>
                ),
            },
            {
                id: 'time_12',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.icon}>n</span> Nemesis Encounter
                        - The Hand Lvl 1
                    </>
                ),
            },
            {
                id: 'time_13',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_14',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_15',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.icon}>n</span> Nemesis Encounter
                        - Butcher Lvl 2
                    </>
                ),
            },
            {
                id: 'time_16',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_17',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_18',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.icon}>n</span> Nemesis Encounter
                        - King's Man Lvl 2
                    </>
                ),
            },
            {
                id: 'time_19',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.icon}>s</span> Watched
                    </>
                ),
            },
            {
                id: 'time_20',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_21',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_22',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.icon}>n</span> Nemesis Encounter
                        - Butcher Lvl 3
                    </>
                ),
            },
            {
                id: 'time_23',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_24',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_25',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.icon}>n</span> Nemesis Encounter
                        - Watcher
                    </>
                ),
            },
            {
                id: 'time_26',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_27',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_28',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.icon}>n</span> Nemesis Encounter
                        - King's Man Lvl 3
                    </>
                ),
            },
            {
                id: 'time_29',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_30',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.icon}>n</span> Nemesis Encounter
                        - Gold Smoke Knight
                    </>
                ),
            },
            {
                id: 'time_31',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_32',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_33',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_34',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_35',
                label: <span className={styles.seIcon}>v</span>,
            },
            // {
            //     id: 'time_36',
            //     label: <span className={styles.seIcon}>v</span>,
            // },
        ],
    },
    {
        sheetId: 'potstar',
        list: [
            {
                id: 'time_0',
                label: (
                    <>
                        <DKIcon />
                        <span className={styles.icon}>s</span> Foundlings
                    </>
                ),
            },
            {
                id: 'time_1',
                label: (
                    <>
                        <DKIcon />
                        <span className={styles.icon}>s</span> Endless Screams
                    </>
                ),
            },
            {
                id: 'time_2',
                label: null,
            },
            {
                id: 'time_3',
                label: (
                    <>
                        <DKIcon />
                        <span className={styles.icon}>s</span> Nemesis Encounter
                        -Dragon King Tyrant Lvl 1
                    </>
                ),
            },
            {
                id: 'time_4',
                label: (
                    <>
                        <DKIcon />
                        <span className={styles.icon}>s</span> Midnight's
                        Children
                    </>
                ),
            },
            {
                id: 'time_5',
                label: null,
            },
            {
                id: 'time_6',
                label: (
                    <>
                        <span className={styles.icon}>s</span> Phoenix Feather
                    </>
                ),
            },
            {
                id: 'time_7',
                label: null,
            },
            {
                id: 'time_8',
                label: (
                    <>
                        <DKIcon />
                        <span className={styles.icon}>s</span> Nemesis Encounter
                        - Dragon King Tyrant Lvl 2
                    </>
                ),
            },
            {
                id: 'time_9',
                label: (
                    <>
                        <DKIcon />
                        <span className={styles.icon}>s</span> Unveil the sky
                    </>
                ),
            },
            {
                id: 'time_10',
                label: null,
            },
            {
                id: 'time_11',
                label: (
                    <>
                        <span className={styles.icon}>s</span> Principle
                        Conviction
                    </>
                ),
            },
            {
                id: 'time_12',
                label: (
                    <>
                        <span className={styles.icon}>n</span> Nemesis Encounter
                        - Butcher Lvl 2
                    </>
                ),
            },
            {
                id: 'time_13',
                label: null,
            },
            {
                id: 'time_14',
                label: null,
            },
            {
                id: 'time_15',
                label: (
                    <>
                        <span className={styles.icon}>n</span> Nemesis Encounter
                        - Lvl 2
                    </>
                ),
            },
            {
                id: 'time_16',
                label: null,
            },
            {
                id: 'time_17',
                label: null,
            },
            {
                id: 'time_18',
                label: (
                    <>
                        <DKIcon />
                        <span className={styles.icon}>n</span> Nemesis Encounter
                        - Dragon King Tyrant Lvl 3
                    </>
                ),
            },
            {
                id: 'time_19',
                label: (
                    <>
                        <DKIcon />
                        <span className={styles.icon}>s</span> The Dragon's Tomb
                    </>
                ),
            },
            {
                id: 'time_20',
                label: null,
            },
            {
                id: 'time_21',
                label: null,
            },
            {
                id: 'time_22',
                label: (
                    <>
                        <span className={styles.icon}>n</span> Nemesis Encounter
                        - Lvl 3
                    </>
                ),
            },
            {
                id: 'time_23',
                label: null,
            },
            {
                id: 'time_24',
                label: (
                    <>
                        <DKIcon />
                        <span className={styles.icon}>n</span> Nemesis Encounter
                        - Death of the Dragon King
                    </>
                ),
            },
            {
                id: 'time_25',
                label: null,
            },
            {
                id: 'time_26',
                label: null,
            },
            {
                id: 'time_27',
                label: null,
            },
            {
                id: 'time_28',
                label: null,
            },
            {
                id: 'time_29',
                label: null,
            },
            {
                id: 'time_30',
                label: null,
            },
            {
                id: 'time_31',
                label: null,
            },
            {
                id: 'time_32',
                label: null,
            },
            {
                id: 'time_33',
                label: null,
            },
            {
                id: 'time_34',
                label: null,
            },
            {
                id: 'time_35',
                label: null,
            }
        ],
    },
    {
        sheetId: 'potsun',
        list: [
            {
                id: 'time_0',
                label: (
                    <>
                        <span className={styles.icon}>s</span>
                        The Pool and the Sun
                    </>
                ),
            },
            {
                id: 'time_1',
                label: (
                    <>
                        <span className={styles.icon}>s</span>
                        Endless Screams
                    </>
                ),
            },
            {
                id: 'time_2',
                label: null,
            },
            {
                id: 'time_3',
                label: (
                    <>
                        <span className={styles.icon}>s</span>
                        Sun Dipping
                    </>
                ),
            },
            {
                id: 'time_4',
                label: (
                    <>
                        <span className={styles.icon}>s</span>
                        The Great Sky Gift
                    </>
                ),
            },
            {
                id: 'time_5',
                label: null,
            },
            {
                id: 'time_6',
                label: (
                    <>
                        <span className={styles.icon}>s</span>
                        Phoenix Feather
                    </>
                ),
            },
            {
                id: 'time_7',
                label: null,
            },
            {
                id: 'time_8',
                label: null,
            },
            {
                id: 'time_9',
                label: (
                    <>
                        <span className={styles.icon}>s</span>
                        Birth of Color
                    </>
                ),
            },
            {
                id: 'time_10',
                label: (
                    <>
                        <span className={styles.icon}>s</span>
                        Principle: Conviction
                    </>
                ),
            },
            {
                id: 'time_11',
                label: (
                    <>
                        <span className={styles.icon}>s</span>
                        Sun Dipping
                    </>
                ),
            },
            {
                id: 'time_12',
                label: (
                    <>
                        <span className={styles.icon}>s</span>
                        The Great Sky Gift
                    </>
                ),
            },
            {
                id: 'time_13',
                label: null,
            },
            {
                id: 'time_14',
                label: null,
            },
            {
                id: 'time_15',
                label: null,
            },
            {
                id: 'time_16',
                label: null,
            },
            {
                id: 'time_17',
                label: null,
            },
            {
                id: 'time_18',
                label: (
                    <>
                        <span className={styles.icon}>s</span>
                        Sun Dipping
                    </>
                ),
            },
            {
                id: 'time_19',
                label: (
                    <>
                        <span className={styles.icon}>s</span>
                        Final Gift
                    </>
                ),
            },
            {
                id: 'time_20',
                label: (
                    <>
                        <span className={styles.seIcon}>n</span>
                        Nemesis Encounter - Kings Man Level 2
                    </>
                ),
            },
            {
                id: 'time_21',
                label: (
                    <>
                        <span className={styles.seIcon}>n</span>
                        Nemesis Encounter - Butcher Level 3
                    </>
                ),
            },
            {
                id: 'time_22',
                label: (
                    <>
                        <span className={styles.seIcon}>n</span>
                        Nemesis Encounter - Kings Man Level 3
                    </>
                ),
            },
            {
                id: 'time_23',
                label: (
                    <>
                        <span className={styles.seIcon}>n</span>
                        Nemesis Encounter - The Hand Level 3
                    </>
                ),
            },
            {
                id: 'time_24',
                label: (
                    <>
                        <span className={styles.seIcon}>n</span>
                        Nemesis Encounter - The Great Devourer
                    </>
                ),
            },
            {
                id: 'time_25',
                label: null,
            },
            {
                id: 'time_26',
                label: null,
            },
            {
                id: 'time_27',
                label: null,
            },
            {
                id: 'time_28',
                label: null,
            },
            {
                id: 'time_29',
                label: null,
            },
            {
                id: 'time_30',
                label: null,
            },
            {
                id: 'time_31',
                label: null,
            },
            {
                id: 'time_32',
                label: null,
            },
            {
                id: 'time_33',
                label: null,
            },
            {
                id: 'time_34',
                label: null,
            },
            {
                id: 'time_35',
                label: null,
            }
        ],
    },
    {
        sheetId: 'ark',
        list: [
            {
                id: null,
                label: (
                    <>
                        <span className={classes(styles.seIcon, styles.underTen)}>v</span>
                        First Crimson Day,
                        <span className={styles.bookIcon}>s</span> Dreamless
                        Respite, <span className={styles.bookIcon}>s</span> First
                        Meal, <span className={styles.bookIcon}>s</span>{' '}
                        Extinguished Guidepost
                    </>
                ),
                editable : false
            },
            {
                id: 'time_0',
                label: (
                    <>
                        <span className={classes(styles.seIcon, styles.underTen)}>v</span>
                        <span className={styles.bookIcon}>s</span> Death of Song
                    </>
                ),
            },
            {
                id: 'time_1',
                label: (
                    <>
                        <span className={classes(styles.seIcon, styles.underTen)}>v</span>
                        <span className={styles.bookIcon}>s</span> Missing Statue
                    </>
                ),
            },
            {
                id: 'time_2',
                label: (
                    <>
                        <span className={classes(styles.seIcon, styles.underTen)}>v</span>
                        <span className={styles.icon}>n</span> Nemesis Encounter
                        - Butcher Lvl 1
                    </>
                ),
            },
            {
                id: 'time_3',
                label: (
                    <>
                        <span className={classes(styles.seIcon, styles.underTen)}>v</span>
                        <span className={styles.bookIcon}>s</span> Stained
                    </>
                ),
            },
            {
                id: 'time_4',
                label: (
                    <>
                        <span className={classes(styles.seIcon, styles.underTen)}>v</span>
                        <span className={styles.bookIcon}>s</span> Unwanted Gifts
                    </>
                ),
            },
            {
                id: 'time_5',
                label: (
                    <>
                        <span className={classes(styles.seIcon, styles.underTen)}>v</span>
                        <span className={styles.bookIcon}>s</span> Phoenix Feather
                    </>
                ),
            },
            {
                id: 'time_6',
                label: <span className={classes(styles.seIcon, styles.underTen)}>v</span>,
            },
            {
                id: 'time_7',
                label: (
                    <>
                        <span className={classes(styles.seIcon, styles.underTen)}>v</span>
                        <span className={styles.icon}>n</span>
                        Nemesis Encounter - Atnas Lvl 1
                    </>
                ),
            },
            {
                id: 'time_8',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_9',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.bookIcon}>s</span> The Game
                    </>
                ),
            },
            {
                id: 'time_10',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.bookIcon}>s</span> Principle:
                        Conviction
                    </>
                ),
            },
            {
                id: 'time_11',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.icon}>n</span> Nemesis Encounter
                        - The Hand Lvl 1
                    </>
                ),
            },
            {
                id: 'time_12',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_13',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.bookIcon}>s</span>
                        Wondrous Design
                    </>
                ),
            },
            {
                id: 'time_14',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.icon}>n</span> Nemesis Encounter
                        - Butcher Lvl 2
                    </>
                ),
            },
            {
                id: 'time_15',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_16',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.icon}>n</span> Nemesis Encounter
                        - Atnas Lvl 2
                    </>
                ),
            },
            {
                id: 'time_17',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                    </>
                ),
            },
            {
                id: 'time_18',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.bookIcon}>s</span> Perfect Punt,{' '}
                        <span className={styles.icon}>n</span> Nemesis Encounter
                        - The Gambler
                    </>
                ),
            },
            {
                id: 'time_19',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.bookIcon}>s</span>
                        Latern Festival
                    </>
                ),
            },
            {
                id: 'time_20',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.bookIcon}>s</span>
                        The Awaited
                    </>
                ),
            },
            {
                id: 'time_21',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        Wanderer - Luck, <span className={styles.icon}>
                            n
                        </span>{' '}
                        Nemesis Encounter - Butcher Lvl 3
                    </>
                ),
            },
            {
                id: 'time_22',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_23',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_24',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_25',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_26',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.icon}>n</span>
                        Nemesis Encounter - Atnas Lvl3
                    </>
                ),
            },
            {
                id: 'time_27',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_28',
                label: (
                    <>
                        <span className={styles.seIcon}>v</span>
                        <span className={styles.icon}>n</span>
                        Nemesis Encounter - Godhand
                    </>
                ),
            },
            {
                id: 'time_29',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_30',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_31',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_32',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_33',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_34',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_35',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_36',
                label: <span className={styles.seIcon}>v</span>,
            },
            {
                id: 'time_37',
                label: <span className={styles.seIcon}>v</span>,
            },
        ],
    },
];
