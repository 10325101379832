import KDHTMLInput from '../../../../../../components/general/kd-html-input/KDHtmlInput.component';
import styles from './sheet-population.module.scss';

const SheetPopulation = () => {
    return (
        <div className={styles.populationContainer}>
            <div className={styles.inputContainer}>
                <KDHTMLInput
                    className={styles.inputField}
                    type="number"
                    id="population"
                    name="population"
                    min="0"
                    max="99"
                />
            </div>
            <div className={styles.header}>
                <p className={styles.headerTitle}>Population</p>
                <p className={styles.headerHelper}>
                    Update the population number as survivors are added and
                    lost.
                </p>
            </div>
        </div>
    );
};

export default SheetPopulation;
