import { useContext } from "react";
import AvailabilityOfEditing from "../../../modules/record-sheets-module/utils/availabilityOfEdit";
import { StateValueWrapperContext } from "../../../modules/record-sheets-module/contexts/StateValueWrapperContext";

export interface KDHTMLTextAreaProps
    extends React.DetailedHTMLProps<
        React.TextareaHTMLAttributes<HTMLTextAreaElement>,
        HTMLTextAreaElement
    > {
}

export default function KDHTMLTextArea(props : KDHTMLTextAreaProps){
    const {
        id
    } = props;

    const context = useContext(StateValueWrapperContext);

    let disabled = false;

    const availability = AvailabilityOfEditing();

    if(!!props.disabled){
        disabled = true;
    } else{
        disabled = !availability.edit;
    }

    const getInputValue = (id: string | undefined) => {
        if (context !== null) {
            if (id) {
                return context.getInputValue(id);
            }
        }

        return '';
    };

    const changeInputValue = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault();
        const { id, value } = event.target;
        context?.setInputValue(id, value);
    };
    
    return <textarea disabled={disabled} {...props} value={getInputValue(id)} onChange={changeInputValue} ></textarea>
}