import React from 'react';
import BaseValueCheckmarkState from './BaseValueCheckmarkState';

export type UpdateCallback = (fragment: BaseValueCheckmarkState) => void;

export type InputUpdateCallback = (id: string, value: string) => void;

export type NewValueWrapperCreated = (valueWrapper : StateValueWrapper) => void;



export default class StateValueWrapper {
    public name? : string;
    private readonly stateFragment: BaseValueCheckmarkState | null;
    private readonly updateCallback: UpdateCallback;

    private static callbackMap: Map<string, InputUpdateCallback[]> = new Map<string, InputUpdateCallback[]>();
    private static newValueWrapperCreated: Map<string, NewValueWrapperCreated[]> = new Map<string, NewValueWrapperCreated[]>();

    constructor(
        stateFragment: BaseValueCheckmarkState | undefined,
        updateCallback: UpdateCallback
    ) {
        if (stateFragment === undefined) this.stateFragment = null;
        else this.stateFragment = stateFragment;
        this.updateCallback = updateCallback;

        StateValueWrapper.newValueWrapperCreated.forEach(x=>{
            x.forEach(callback=>callback(this));
        })
    }

    setName = (name : string) =>{
        this.name = name;
    }

    getInputValue = (id: string): string => {
        return this.getInputValueWithDefault(id, '');
    };

    getInputValueInt = (id: string): number => {
        return this.getInputValueIntWithDefault(id, -1);
    };

    subscribeInput = (id: string, callback: InputUpdateCallback) => {
        if (!!StateValueWrapper.callbackMap.has(id)) {
            StateValueWrapper.callbackMap.get(id)!.push(callback);
        } else {
            StateValueWrapper.callbackMap.set(id, [callback]);
        }
    };

    unSubscribeInput = (id: string, callback: InputUpdateCallback) => {
        if (!StateValueWrapper.callbackMap.has(id)) {
            return;
        } else {
            const array = StateValueWrapper.callbackMap.get(id)!;
            var index = array.indexOf(callback);
            if (index !== -1) {
                array.splice(index, 1);
            }
        }
    };

    getInputValueIntWithDefault = (
        id: string,
        defaultValue: number
    ): number => {
        if (this.stateFragment === null) return defaultValue;
        const { values } = this.stateFragment;
        if (values === null) return defaultValue;
        if (!values.has(id)) {
            values.set(id, defaultValue.toString());
        }

        const value = values.get(id);
        if (value) return parseInt(value);

        return defaultValue;
    };

    getInputValueWithDefault = (id: string, defaultValue: string): string => {
        if (this.stateFragment === null) return defaultValue;
        const { values } = this.stateFragment;
        if (values === null) return '';
        if (!values.has(id)) {
            values.set(id, defaultValue);
        }

        const value = values.get(id);
        if (value) return value;

        return '';
    };

    getCheckboxValue = (id: string) => {
        if (this.stateFragment === null) return false;
        const { checkmarks } = this.stateFragment;
        if (checkmarks === null) return false;
        if (!checkmarks.has(id)) {
            checkmarks.set(id, false);
        }

        const value = checkmarks.get(id);
        if (value) return value;

        return false;
    };

    setInputValue = (id: string, value: string) => {
        if (this.stateFragment === null) return;
        const { values } = this.stateFragment;
        values?.set(id, value);
        this.update();
        if(!!StateValueWrapper.callbackMap.has(id)){
            for(let callback of StateValueWrapper.callbackMap.get(id)!){
                callback(id, value);
            }
        }
    };

    setCheckboxValue = (id: string, value: boolean) => {
        if (this.stateFragment === null) return;
        const { checkmarks } = this.stateFragment;
        checkmarks?.set(id, value);
        this.update();
    };

    setCheckboxBulk = (checkmarkValues: Map<string, boolean>) => {
        if (this.stateFragment === null) return;
        const { checkmarks } = this.stateFragment;

        checkmarkValues.forEach((value, key, map) => {
            checkmarks?.set(key, value);
        });

        this.update();
    };

    inputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { id, value } = event.target;
        this.setInputValue(id, value);
    };

    selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        const { id, value } = event.target;
        this.setInputValue(id, value);
    };

    checkboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = event.target;
        this.setCheckboxValue(id, checked);
    };

    checkboxClick = (event: React.MouseEvent<HTMLInputElement>) => {
        const { id, checked } = event.currentTarget;
        this.setCheckboxValue(id, checked);
    };

    textAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault();
        const { id, value } = event.target;
        this.setInputValue(id, value);
    };

    checkboxProgressChange = (
        idPrefix: string,
        index: number,
        total: number,
        value: boolean,
        start?: number
    ) => {
        if (!start) start = 0;
        const map = new Map<string, boolean>();
        for (let i = start; i < start + total; i++) {
            const elementId = `${idPrefix}${i}`;

            if (i <= index && index > start) {
                map.set(elementId, true);
            } else {
                map.set(elementId, false);
            }
        }
        if (index === start) {
            const elementId = `${idPrefix}${index}`;
            map.set(elementId, value);
        }

        this.setCheckboxBulk(map);
    };

    update() {
        if (this.stateFragment === null) return;
        this.updateCallback(this.stateFragment);
    }
}
