import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface AuthState {
    jwtToken: string | null
    dataAccess: number[]
}

const initialState: AuthState = {
    jwtToken: window.sessionStorage.getItem("jwt"),
    dataAccess: []
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string | null>) => {
            state.jwtToken = action.payload
        },
        setDataAccess: (state, action: PayloadAction<number[]>) => {
            state.dataAccess = action.payload
        },
    },
})

export const { setToken, setDataAccess } = authSlice.actions

export const selectJWT = (state: RootState) => state.auth.jwtToken

export const selectDataAccess = (state: RootState) => state.auth.dataAccess

export default authSlice.reducer
