import SheetLostSettlement from '../sheet-lost-settlements/sheet-lost-settlements.component';
import { SheetDeathCount } from '../sheet-death-count/sheet-death-count.component';

import styles from './front-sheet-header.module.scss';
import KDHTMLInput from '../../../../../../components/general/kd-html-input/KDHtmlInput.component';

interface FrontSheetHeaderProps {
    layout?: 'default' | 'ark';
}

const FrontSheetHeader = ({ layout = 'default' }: FrontSheetHeaderProps) => {
    return (
        <div className={styles.header}>
            <div className={styles.survivalLimitBoxContainer}>
                <div className={styles.survivalLimitBox}>
                    <KDHTMLInput
                        type="number"
                        className={styles.survivalLimitInput}
                        id="survival_limit"
                        name="survival_limit"
                        min="0"
                        max="99"
                    />
                </div>
                <p className={styles.survivalLimitText}>Survival Limit</p>
            </div>

            <div className={styles.survivalLimitVerticalLine}></div>

            <div className={styles.settlementNameLabelContainer}>
                <div className={styles.settlementNameLabel}>
                    <span> Settlement Name</span>
                    <KDHTMLInput
                        type="text"
                        className={styles.settlementNameInput}
                        id="settlement_name"
                        name="settlement_name"
                    />
                </div>
                <div className={styles.settlementNameLine} />
                <p className={styles.settlementNameHelperText}>
                    When the settlement is named for the first time
                    <strong>, returning survivors</strong> gain +1 survival.
                </p>
            </div>

            {layout === 'ark' ? (
                <div className={styles.deathCountContainer}>
                    <SheetLostSettlement idPrefix="set_check_" />
                </div>
            ) : (
                <SheetDeathCount />
            )}
        </div>
    );
};

export default FrontSheetHeader;
