import styles from './sheet-principle.module.scss';
import sheetStyles from '../styles/sheet-styles.module.scss';
import KDHTMLCustomCheckbox from '../../../../../../components/general/kd-html-input/KDHtmlCustomCheckbox';
import classes from '../../../../../../utils/classes';
import KDHTMLInput from '../../../../../../components/general/kd-html-input/KDHtmlInput.component';
import { renderForEach } from '../../../../../../utils/renderFor';

interface PrincipleOptionProps {
    id: number;
    label: string;
    default?: boolean;
    inputId? : string
}

interface PrinciplesProps {
    idPrefix: string;
    extraFieldsIds?: string[];
    principles: {
        label: string;
        id? : string;
        options: {
            1?: PrincipleOptionProps;
            2?: PrincipleOptionProps;
        };
    }[];
}

const PrincipleOption = ({
    singleOption,
    idPrefix
}: {
    singleOption?: PrincipleOptionProps,
    idPrefix: string
}) => {
    if (!!singleOption) {
        return (
            <div className={classes(styles.principleOption)}>
                <KDHTMLCustomCheckbox
                    containerClass={classes(
                        sheetStyles.checkboxContainer,
                        styles.principleOptionCheckbox
                    )}
                    key={`label_${idPrefix}${singleOption.id}`}
                    id={`${idPrefix}${singleOption.id}`}
                    checked={!!singleOption.default}
                    disabled={!!singleOption.default}
                />
                {
                    !!singleOption.inputId?(
                        <KDHTMLInput
                            type="text"
                            id={singleOption.inputId}
                            className={styles.principleOptionInput}
                        />
                    ):(<>{singleOption.label}</>)
                }
            </div>
        );
    }
    return <></>;
};

const SheetPrinciples = ({
    principles,
    idPrefix,
    extraFieldsIds = [],
}: PrinciplesProps) => {
    const renderPrinciples = renderForEach(principles, (principle, index) => {
        return (
            <div key={index} className={styles.principlesHorizontalLine}>
                <div key={index} className={styles.principleContainer}>
                    {!principle.id && <p className={styles.principleName}>{principle?.label}</p>}
                    {!!principle.id && <KDHTMLInput id={principle.id}/>}

                    <div className={styles.principleOptionContainer}>
                        {!!principle.options[1] ? (<PrincipleOption singleOption={principle.options[1]} idPrefix={idPrefix}/>) : (<div></div>)}

                        <span className={styles.orOption}>
                            {Object.keys(principle.options).length > 1 && 'or'}
                        </span>

                        {!!principle.options[2] ? (<PrincipleOption singleOption={principle.options[2]} idPrefix={idPrefix}/>) : (<div></div>)}
                    </div>
                </div>
            </div>
        );
    });

    const renderExtraPrinciples = extraFieldsIds.map((id, index) => (
        <div key={index} className={styles.principlesHorizontalLine}>
            <KDHTMLInput
                type="text"
                className={styles.principleInput}
                id={id}
                name={id}
            />
        </div>
    ));

    return (
        <div className={styles.principlesContainer}>
            <div className={styles.principlesInnerContainer}>
                {renderPrinciples}
                {renderExtraPrinciples}
            </div>
        </div>
    );
};

export default SheetPrinciples;
