import React, { useContext, useState } from 'react';
import styles from './kdbuttoncheckbox.module.scss';
import classes from '../../../utils/classes';
import conditionalClass from '../../../utils/conditional-class';
import { StateValueWrapperContext } from '../../../modules/record-sheets-module/contexts/StateValueWrapperContext';

export interface KDHTMLInputProps
    extends React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    defaultStringValue?: string;
    isLast? : boolean;
}

export default function KDHTMLButtonCheckbox(props: KDHTMLInputProps) {
    const { type, id, defaultStringValue, className } = props;

    const context = useContext(StateValueWrapperContext);

    const getCheckBoxValue = (id: string | undefined) => {
        if (context !== null) {
            if (id) {
                return context.getCheckboxValue(id);
            }
        }

        return false;
    };

    const [checked, setChecked] = useState(getCheckBoxValue(id));

    const changeOnClick = (event: React.MouseEvent<HTMLInputElement>) => {
        event.preventDefault();
        const result = !checked;
        setChecked(result);
        if (!id) return;
        context?.setCheckboxValue(id, result);
    };

    return (
        <div className={styles.generalContainer}>
            <div
                className={classes(
                    styles.border,
                    conditionalClass(checked, styles.checked, undefined),
                    conditionalClass(!!props.isLast, styles.isLast, undefined)
                )}
            ></div>
            <div style={{ position: 'relative', zIndex: 1001, width: '100%' }}>
                <input
                    type="button"
                    className={classes(styles.noStyleButton, className)}
                    {...props}
                    onClick={changeOnClick}
                />
            </div>
        </div>
        // <input
        //         type="button"
        //         className={classes(
        //             conditionalClass(checked, styles.checked, undefined),
        //             styles.noStyleButton,
        //             className
        //         )}
        //         {...props}
        //         onClick={changeOnClick}
        //     />
    );
}
