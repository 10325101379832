import { SurvivorListComponent } from '../../survivor-list/survivor-list.component';

import styles from './core-back.module.scss';
import baseStyles from '../styles/core-settlement.module.scss';
import classes from '../../../../../../utils/classes';
import SheetParagraph from '../../../elements/sheets/sheet-paragraphs/sheet-paragraph.component';
import SheetNemesisMonsters from '../../../elements/sheets/sheet-nemesis/sheet-nemesis.component';
import SheetLaternResearch from '../../../elements/sheets/sheet-lantern-research/sheet-lantern-research.component';
import SheetNotes from '../../../elements/sheets/sheet-notes/sheet-notes.component';
import SheetPopulation from '../../../elements/sheets/sheet-population/sheet-population.component';
import SheetLostSettlement from '../../../elements/sheets/sheet-lost-settlements/sheet-lost-settlements.component';
import { SettlementType } from '../../../../models/settlements/SettlementType';

interface ICoreBackPageTemplate {
    fromYear: number;
    toYear: number;
    sheetId: number;
    openSurvivor: (survivorId: number) => void;
    newSurvivorCount: number;
}

export default function CoreSettlementBackPage(props: ICoreBackPageTemplate) {
    const nemesisMonsters = ['Butcher', "King's Man", 'The Hand'];

    return (
        <div className={baseStyles.pageContainer}>
            <div className={baseStyles.innerContainer}>
                <div
                    className={classes(styles.twoColumnLayout, styles.firstRow)}
                >
                    <div>
                        <div className={styles.header}>
                            <div className={styles.headerTitle}>
                                Resource Storage
                            </div>
                            <div className={styles.headerHelper}>
                                Gear & Resources may be stored without limit.
                            </div>
                        </div>
                        <SheetParagraph
                            idPrefix="resource_"
                            linesPerColumn={13}
                            totalLines={26}
                            helperText=""
                        />
                    </div>
                    <div>
                        <div className={styles.header}>
                            <div className={styles.headerTitle}>
                                Gear Storage
                            </div>
                            <div className={styles.headerHelper}>
                                Gear & Resources may be stored without limit.
                            </div>
                        </div>
                        <SheetParagraph
                            idPrefix="gear_"
                            linesPerColumn={13}
                            totalLines={25}
                            helperText="( If you run out of room, use scrap paper )"
                        />
                    </div>
                </div>

                <div className={styles.secondRow}>
                    <div className={styles.secondRowLeft}>
                        <SheetNemesisMonsters
                            nemesisMonsters={nemesisMonsters}
                        />
                        <SheetLaternResearch />
                    </div>
                    {/* <SheetParagraph */}
                    {/*     title="Notes" */}
                    {/*     subtitle="" */}
                    {/*     totalLines={11} */}
                    {/*     linesPerColumn={11} */}
                    {/*     helperText="" */}
                    {/*     idPrefix="note_" */}
                    {/* /> */}
                    <SheetNotes />
                </div>

                <div className={styles.thirdRow}>
                    <SheetPopulation />
                    <SheetLostSettlement idPrefix="set_check_" />
                </div>

                <SurvivorListComponent {...props} settlementType={SettlementType.Core} />
            </div>
        </div>
    );
}
