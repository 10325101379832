import { SVGProps } from 'react';

export const LockIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={8}
        height={8}
        viewBox="0 0 135.467 135.467"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect
            style={{
                fill: '#000',
                strokeWidth: 0.173893,
            }}
            width={87.352}
            height={73.352}
            x={24.057}
            y={48.706}
            ry={9.268}
        />
        <rect
            style={{
                fill: '#fff',
                strokeWidth: 0.153352,
            }}
            width={15.577}
            height={33.916}
            x={59.945}
            y={67.088}
            ry={7.789}
        />
        <path
            style={{
                fill: 'none',
                stroke: '#000',
                strokeWidth: 10.4328,
                strokeLinecap: 'butt',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 4,
                strokeDasharray: 'none',
                strokeOpacity: 1,
            }}
            d="M46.826 44.212s-4.162-30.06 20.978-30.441c25.297-.383 20.778 30.442 20.778 30.442"
        />
    </svg>
);
