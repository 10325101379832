import ICookieService from '../ICookieService';
import Cookies from 'js-cookie';
import { injectable } from 'inversify';

@injectable()
export default class CookieService implements ICookieService {
    private keys! : string[];
    private attributes! : any;

    constructor(){
        this.keys = [];
        this.attributes = {};
    }
    getExpirationAttribute(cookieAttributes: Cookies.CookieAttributes): Cookies.CookieAttributes {
        const expiration = new Date();
        expiration.setFullYear(expiration.getFullYear()+1);
        return {
            ...cookieAttributes,
            expires: expiration
        };
    }
    set(
        name: string,
        value: string,
        options?: Cookies.CookieAttributes | undefined
    ): void {
        this.keys.push(name);
        this.attributes[name] = options;
        Cookies.set(name, value, options);
    }

    get(name: string) {
        return Cookies.get(name);
    }

    remove(name:string, options?: Cookies.CookieAttributes | undefined){
        Cookies.remove(name,options);
    }

    getDomainAttribute(cookieAttributes : Cookies.CookieAttributes,domain:string) : Cookies.CookieAttributes{
        return {
            ...cookieAttributes,
            domain : domain
        };
    }

    reset(){
        this.keys.forEach(x=>{this.remove(x, this.attributes[x])});
    }
}
