import KDHTMLInput from '../../../../../../components/general/kd-html-input/KDHtmlInput.component';
import renderFor from '../../../../../../utils/renderFor';
import styles from './sheet-lantern-research.module.scss';
const SheetLaternResearch = () => {
    return (
        <div className={styles.lanternResearchContainer}>
            <div className={styles.headerWrapper}>
                <div className={styles.header}>
                    <p className={styles.headerTitle}>Lantern Research Level</p>
                    <p className={styles.headerHelper}>
                        Your settlements progress researching the secrets of the
                        Final Lantern.
                    </p>
                </div>
                <div className={styles.inputContainer}>
                    <KDHTMLInput
                        className={styles.inputField}
                        type="number"
                        id="research"
                        name="research"
                        min="0"
                        max="99"
                    />
                </div>
            </div>

            <div className={styles.laternInnerContainer}>
                <p className={styles.lanternResearchMonsterVolume}>
                    Monster Volumes
                </p>
                {renderFor(0, 4, (index) => {
                    const id = `volume_${index}`;
                    return (
                        <div key={index} className={styles.horizontalLine}>
                            <KDHTMLInput
                                className={styles.volumeInput}
                                type="text"
                                key={id}
                                id={id}
                                name={id}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default SheetLaternResearch;
