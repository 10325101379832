

import styles from './potstar-front.module.scss';
import baseStyles from '../styles/potstar-settlement.module.scss';
import FrontSheetHeader from '../../../elements/sheets/sheet-header/front-sheet-header.component';
import SheetTimeLineTable from '../../../elements/sheets/sheet-timeline/sheet-timeline.component';
import SheetMileStones from '../../../elements/sheets/sheet-milestones/sheet-milestones.component';
import SheetInnovations from '../../../elements/sheets/sheet-innovations/sheet-innovations.component';
import SheetPrinciples from '../../../elements/sheets/sheet-principles/sheet-principle.component';
import SheetSettlements from '../../../elements/sheets/sheet-settlements/sheet-settlements.component';
import SheetQuarries from '../../../elements/sheets/sheet-quarries/sheet-quarries.component';
import renderFor from '../../../../../../utils/renderFor';
import classes from '../../../../../../utils/classes';
import KDHTMLInput from '../../../../../../components/general/kd-html-input/KDHtmlInput.component';


export default function POTStarSettlementFrontPage() {
    const locationPrefilled = [
        'Throne',
        'Bone Smith',
        'Skinnery',
        'Organ Grinder',
        'Catarium',
        'Weapon Crafter',
        'Leather Worker',
        'Stone Circle',
        'Barber Surgeon',
        'Plumery',
        'Blacksmith',
        'Mask Maker',
    ];

    const principles = [
        {
            label: 'New Life',
            options: {
                1: { id: 0, label: 'Protect the Young' },
                2: { id: 1, label: 'Survival of the Fittest' },
            },
        },
        {
            label: 'Death',
            options: {
                1: { id: 2, label: 'Cannibalize' },
                2: { id: 3, label: 'Graves' },
            },
        },
        {
            label: 'Society',
            options: {
                1: { id: 4, label: 'Collective Toil' },
                2: { id: 5, label: 'Accept Darkness' },
            },
        },
        {
            label: 'Conviction',
            options: {
                1: { id: 6, label: 'Barbaric' },
                2: { id: 7, label: 'Romantic' },
            },
        },
    ];

    const milestones = [
        {
            id: 0,
            label: 'First child is born',
            principle: 'Principle: New Life',
        },
        {
            id: 1,
            label: 'Population reaches 15',
            principle: 'Principle: Society',
        },
        {
            id: 2,
            label: 'Population reaches 0',
            principle: 'Game Over',
        },
        {
            id: 3,
            label: 'First time death count is updated',
            principle: 'Principle: Death',
        },
        {
            id: -1,
            label: '',
            principle: '',
        }
    ];

    const quarries = [
        { id: -1, label: 'White Lion' },
        { id: 0, label: 'Screaming Antelope' },
        { id: 1, label: 'Phoenix' },
    ];

    return (
        <div className={baseStyles.pageContainer}>
            <div className={baseStyles.innerContainer}>
                <FrontSheetHeader />
                <SheetTimeLineTable
                    columns={['Year', 'Story & Social Event']}
                    layout="full"
                    sheetId="potstar"
                />
                <SheetMileStones
                    milestones={milestones}
                    idPrefix="mile_"
                    layout="horizontal"
                />
                <div className={styles.lowerHalfContainer}>
                    <div>
                        <SheetInnovations
                            idPrefix="innovation_"
                            firstLineLabel="Dragon Speech"
                        />
                        <div className={styles.principlesContainer}>
                            <div className={styles.header}>
                                <div className={styles.headerTitle}>
                                    Principle
                                </div>
                                <div className={styles.headerHelper}>
                                    The settlement's established principles.
                                </div>
                            </div>
                            <div
                                className={styles.principlesHorizontalLine}
                            ></div>
                            <SheetPrinciples
                                principles={principles}
                                idPrefix="principle_check_"
                                extraFieldsIds={['principle_0', 'principle_1']}
                            />
                        </div>
                    </div>
                    <div>
                        <div className={styles.settlementContainer}>
                            <div className={styles.header}>
                                <div className={styles.headerTitle}>
                                    Settlement Locations
                                </div>
                                <div className={styles.headerHelper}>
                                    Locations in your settlement.
                                </div>
                            </div>
                            <SheetSettlements
                                locationPrefilled={locationPrefilled}
                                idPrefix="settlement_check_"
                                extraFieldIDPrefix="settlement_"
                                layout="default"
                            />
                        </div>
                        <div className={styles.quarryContainer}>
                            <SheetQuarries
                                quarries={quarries}
                                idPrefix="quarry_check_"
                                layout="default"
                                emptyLines={renderFor(0, 5, (index) => {
                                    const id = `quarry_${index}`;

                                    return (
                                        <div
                                            key={index}
                                            className={classes(
                                                styles.emptyLine,
                                                styles.quarryEmptyLine
                                            )}
                                        >
                                            <KDHTMLInput
                                                key={index}
                                                className={
                                                    styles.emptyLineInput
                                                }
                                                type="text"
                                                id={id}
                                                name={id}
                                            />
                                        </div>
                                    );
                                })}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
