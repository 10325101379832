import { SVGProps } from 'react';

export const DeathIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 160 164"
        width={14}
        height={14}
        {...props}
    >
        <path
            data-name="Color Fill 1"
            d="M67 4h30v4h8v5h4v8h5v8h4v13h-4v21h-5v4h-4v17h-4v9h-4v4H63v-9h-4V67h-4v-4h-9v-8h5v-5h-5V21h5v-4h4v-4h4V8h8V4Zm-4 38v8h9v-8h-9Zm25 0v8h13v-4h-4v-4h-9ZM25 71h4v5h9v8h4v4h4v5h9v4h8v4h9v4h21v-4h8v-4h8v-4h5v-5h4v-4h4v-4h4v-4h5v-5h4v5h8v4h4v4h5v4h4v13h-4v4h-17v4h-17v5h-13v4h4v4h13v4h21v4h13v17h-9v4h-4v9h-17v-4h-4v-5h-4v-4h-4v-4h-5v-4h-8v-4h-8v-5h-9v-4h-4v4H67v5h-8v4h-8v4h-5v4h-4v4h-4v5h-4v4H21v-4h-4v-5h-4v-4H8v-4H4v-8h4v-5h9v-4h25v-4h13v-8H42v-5H25v-4H8v-4H4v-8h4v-5h5v-4h4v-8h8v-5Z"
            style={{
                fillRule: 'evenodd',
            }}
        />
    </svg>
);
