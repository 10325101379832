import { useInjection } from 'inversify-react';
import AuthPanelDialogWrapper from '../auth-panel-dialog-wrapper/auth-panel-dialog-wrapper.component';
import styles from './invalid-qr-panel.module.scss';
import { IAuthService } from '../../services/IAuthService';
import { Link } from 'react-router-dom';

export default function InvalidQRPanel() {
    const authService = useInjection<IAuthService>('IAuthService');

    const bottomLine = (function () {
        if (!authService.isAuthenticated()) {
            return (
                <div className={styles.alreadyHaveAnAccount}>
                    <p className={styles.bold}>Already have an account?</p>
                    <p>
                        <a href="https://auth.kingdomdeath.com/login">
                            Login here!
                        </a>{' '}
                        or
                        {' '}<a href="https://auth.kingdomdeath.com/register">
                            Create a new one!
                        </a>
                    </p>
                </div>
            );
        }
        else{
            return (
                <div className={styles.alreadyHaveAnAccount}>
                    <Link to="/account">Click here to go to your account!</Link>
                </div>
            );
        }
    })();

    return (
        <AuthPanelDialogWrapper popupClass={['success']}>
            <div className={styles.container}>
                <h3>Invalid QR Code</h3>
                <div>
                    <p>The provided QR Code was already used or invalid.</p>
                    <p>
                        Need Support? <br/>Contact{' '}
                        <a href="mailto:dark.architect@kingdomdeath.com">
                            dark.architect@kingdomdeath.com
                        </a>
                    </p>
                    {bottomLine}
                </div>
            </div>
        </AuthPanelDialogWrapper>
    );
}
