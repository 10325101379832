export class MapperBase {
    protected getBoolCount(
        map: Map<string, boolean> | null,
        prefix: string,
        lookUpValue: boolean
    ): number {
        if (map == null) return 0;
        let count = 0;
        map.forEach((value, key, map) => {
            const indexString = key.substring(prefix.length);
            const intValue = parseInt(indexString);
            if (
                key.startsWith(prefix) &&
                value === lookUpValue &&
                !Number.isNaN(intValue) &&
                intValue.toString().length === indexString.length
            ) {
                count++;
            }
        });
        return count;
    }

    protected getBoolCountArray(
        map: Map<string, boolean> | null,
        prefix: string,
        totalCount : number,
        individualCount : number,
        lookUpValue: boolean
    ): number[] {
        if (map == null) return [];

        const countsArray = [];

        for(let index=0; index<totalCount; index++){
            countsArray.push(0);
            for(let individualIndex = 0; individualIndex<individualCount; individualIndex++){
                if(!!map.get(`${prefix}${index}_${individualIndex}`))
                countsArray[index]++;
            }
        }
        return countsArray;
    }

    protected getBoolCountArrayWithCountMap(
        map: Map<string, boolean> | null,
        prefix: string,
        totalCount : number,
        individualCount : number[]
    ): number[] {
        if (map == null) return [];

        const countsArray = [];

        for(let index=0; index<totalCount; index++){
            countsArray.push(0);
            for(let individualIndex = 0; individualIndex<individualCount[index]; individualIndex++){
                if(!!map.get(`${prefix}${index}_${individualIndex}`))
                countsArray[index]++;
            }
        }
        return countsArray;
    }

    protected getTextSubArray(
        map: Map<string, string> | null,
        filter: (key: string, value: string) => boolean
    ): string[] {
        if (map == null) return [];
        const subArray: string[] = [];
        map.forEach((value, key, map) => {
            if (filter(key, value)) {
                subArray.push(value);
            }
        });
        return subArray;
    }

    protected getTextSubArrayPrefix(
        map: Map<string, string> | null,
        prefix: string
    ): string[] {
        return this.getTextSubArray(map, (key, value) =>
            key.startsWith(prefix)
        );
    }

    protected getBooleanSubArray(
        map: Map<string, boolean> | null,
        filter: (key: string, value: boolean) => boolean
    ): number[] {
        if (map == null) return [];
        const subArray: number[] = [];
        map.forEach((value, key, map) => {
            if (filter(key, value)) {
                subArray.push(value ? 1 : 0);
            }
        });
        return subArray;
    }

    protected getBooleanSubArrayPrefix(
        map: Map<string, boolean> | null,
        prefix: string
    ): number[] {
        return this.getBooleanSubArray(map, (key, value) =>
            key.startsWith(prefix)
        );
    }

    protected setBooleanSubArrayToMap(
        map: Map<string, boolean> | null,
        prefix: string,
        booleanArray: boolean[]
    ) {
        if (map == null) return;
        booleanArray.forEach((value, index, array) => {
            map.set(`${prefix}${index}`, value);
        });
    }

    protected setBooleanIntSubArrayToMap(
        map: Map<string, boolean> | null,
        prefix: string,
        booleanArray: number[]
    ) {
        if (map == null) return;
        booleanArray.forEach((value, index, array) => {
            map.set(`${prefix}${index}`, value === 1);
        });
    }

    protected setTextSubArrayToMap(
        map: Map<string, string> | null,
        prefix: string,
        textSubarray: string[]
    ) {
        if (map == null) return;
        textSubarray.forEach((value, index, array) => {
            map.set(`${prefix}${index}`, value);
        });
    }

    protected setBooleanCountToMap(
        map: Map<string, boolean> | null,
        prefix: string,
        count: number,
        total: number,
        from: number = 0
    ) {
        if (map == null) return;
        for (let i = from; i < total; i++) {
            map.set(`${prefix}${i}`, i < count);
        }
    }

    protected setBitMaskToMap(
        map: Map<string, boolean> | null,
        prefix: string,
        suffix: string,
        mask: number,
        total: number,
        from: number = 0
    ) {
        if (map == null) return;
        const binArray = this.dec2bin(mask, total);
        for (let i = from; i < total; i++) {
            map.set(`${prefix}${i}${suffix}`, binArray[i] === 1);
        }
    }

    protected setText(
        map: Map<string, string> | null,
        name: string,
        value: string | null | undefined
    ) {
        if (map == null) return;
        if (value) {
            map.set(name, value);
        } else {
            map.set(name, '');
        }
    }
    protected getBitMaskFromMapArray(
        map: Map<string, boolean> | null,
        prefix: string,
        suffix: string,
        globalCount: number,
        total: number,
        from: number = 0
    ) {
        const results = [];
        
       for(let i = 0; i<globalCount; i++){
            results.push(0);
            results[i] = this.getBitMaskFromMap(map, prefix, suffix, total, from);
       }

       return results;
    }

    protected getBitMaskFromMap(
        map: Map<string, boolean> | null,
        prefix: string,
        suffix: string,
        total: number,
        from: number = 0
    ) {
        if (map == null) return 0;
        const binArray = [];
        for (let i = from; i < total; i++) {
            binArray.push(map.get(`${prefix}${i}${suffix}`) ? 1 : 0);
        }
        return this.bin2dec(binArray);
    }

    private dec2bin(dec: number, total : number) {
        const binArray = [];
        const binString = (dec >>> 0).toString(2);
        for (let i = 0; i < binString.length; i++) {
            binArray.push(parseInt(binString[i]));
        }
        while(binArray.length<total){
           binArray.unshift(0);
        }
        return binArray;
    }

    private bin2dec(binArray: number[]) {
        const binaryString = binArray.join('');
        return parseInt(binaryString, 2);
    }

    protected booleanToInt(value: boolean): number {
        return value ? 1 : 0;
    }

    protected intToBoolean(value: number): boolean {
        return value === 0 ? false : true;
    }

    protected getValueInt(
        map: Map<string, string> | null,
        key: string
    ): number {
        let value = parseInt(this.getValue(map, key));
        if(Number.isNaN(value))
            value = 0;
        return value;
    }

    protected getValue(map: Map<string, string> | null, key: string): string {
        if (map == null) return '';
        const value = map.get(key);
        if (!value) {
            return '';
        } else if (value) {
            return value;
        }
        return '';
    }

    protected getCheckmarkValue(
        map: Map<string, boolean> | null,
        key: string
    ): boolean {
        if (map == null) return false;

        const value = map.get(key);
        if (value) return value;

        return false;
    }

    protected getCheckmarkValueInt(
        map: Map<string, boolean> | null,
        key: string
    ): number {
        return this.booleanToInt(this.getCheckmarkValue(map, key));
    }

    protected setExpansionCheckmarksFromString(
        map: Map<string, boolean> | null,
        prefix: string,
        expansion: string,
        total: number
    ) {
        if (map == null) return;
        const split = expansion.split(',');
        for (let i = 1; i < total; i++) {
            map?.set(`${prefix}${i}`, false);
        }
        for (let k = 0; k < split.length; k++) {
            map?.set(`${prefix}${split[k]}`, true);
        }
    }

    protected getExpansionString(
        map: Map<string, boolean> | null,
        prefix: string
    ): string {
        if (map == null) return '';

        const values: string[] = [];
        map?.forEach((value, key) => {
            if (key.startsWith(prefix) && value) {
                values.push(key.replace(prefix, ''));
            }
        });

        return values.join(',');
    }
}

export default abstract class BaseMapper<From, To> extends MapperBase {
    public abstract mapToState(from: From): To;
    public abstract mapFromState(from: To): From;
}
