import { useInjection } from 'inversify-react';
import { useState } from 'react';
import ITransService from '../../../services/ITransService';
import IUIService from '../../../services/IUIService';
import CustomerModal from '../modal/customer-modal.component';
import modalStyles from '../modal/customer-modal.module.scss';
import styles from './activate-key-modal.module.scss';
import DownloadLauncherButton from 'components/download-launcher-button/download-launcher-button.component';

export default function ActivateKeyModal({refresh, productKeyValue}:{refresh:()=>void, productKeyValue : string|null}) {
    if(!productKeyValue)
        productKeyValue =''
    
    const [productKey, setProductKey] = useState(productKeyValue);
    const transService = useInjection<ITransService>('ITransService');
    const uiService = useInjection<IUIService>('IUIService');

    return (
        <CustomerModal header="Activate Key">
            <>
            <div className={modalStyles.rowContainer}>
                <div className={modalStyles.row}>
                    <input
                        className={styles.productKeyText}
                        value={productKey}
                        onChange={(e) => {
                            e.preventDefault();
                            setProductKey(e.target.value);
                        }}
                        type="text"
                        placeholder="Enter Product Key"
                    />
                    <input
                        type="button"
                        className={styles.productKeyBtn}
                        disabled={productKey.length === 0}
                        onClick={async (e) => {
                            e.preventDefault();
                            uiService.showLoading();
                            const processed =
                                transService.processProductKey(productKey);
                            setProductKey('');
                            uiService.hideLoading();
                            if (!!processed) {
                                uiService.showSuccessNotification(
                                    'Product Key Activated'
                                );
                                refresh();
                            } else {
                                uiService.showErrorNotification(
                                    'Failed to activate product key!'
                                );
                            }
                        }}
                        value="Activate Key"
                    />
                </div>
            </div>
            </>
            <DownloadLauncherButton style={{marginTop: '15px'}}/>
        </CustomerModal>
    );
}
