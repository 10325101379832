import StateValueWrapper from '../../../../../models/stateFragments/StateValueWrapper';
import GearCard from './gear-card.component';
import gearGridStyles from './gear-grid-styles.module.scss';

export default function GearGrid({
    stateValueWrapper,
}: {
    stateValueWrapper: StateValueWrapper;
}) {
    return (
        <div className={gearGridStyles.gear}>
            <div className={gearGridStyles.gearRow}>
                <GearCard stateValueWrapper={stateValueWrapper} id={'gear_entry_0'}/>
                <GearCard stateValueWrapper={stateValueWrapper}  id={'gear_entry_1'}/>
                <GearCard stateValueWrapper={stateValueWrapper}  id={'gear_entry_2'}/>
            </div>
            <div className={gearGridStyles.gearRow}>
                <GearCard stateValueWrapper={stateValueWrapper}  id={'gear_entry_3'} />
                <GearCard stateValueWrapper={stateValueWrapper}  id={'gear_entry_4'} />
                <GearCard stateValueWrapper={stateValueWrapper}  id={'gear_entry_5'}/>
            </div>
            <div className={gearGridStyles.gearRow}>
                <GearCard stateValueWrapper={stateValueWrapper}  id={'gear_entry_6'}/>
                <GearCard stateValueWrapper={stateValueWrapper}  id={'gear_entry_7'}/>
                <GearCard stateValueWrapper={stateValueWrapper}  id={'gear_entry_8'}/>
            </div>
        </div>
    );
}
