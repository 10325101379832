import React, { useContext } from 'react';
import { NumericFormat } from 'react-number-format';
import AvailabilityOfEditing from '../../../modules/record-sheets-module/utils/availabilityOfEdit';
import { StateValueWrapperContext } from '../../../modules/record-sheets-module/contexts/StateValueWrapperContext';


export interface KDHTMLInputProps
    extends React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    defaultStringValue?: string;
}

export default function KDHTMLInput(props: KDHTMLInputProps) {
    const { type, id, defaultStringValue } = props;

    const context = useContext(StateValueWrapperContext);

    let disabled = false;

    const availability = AvailabilityOfEditing();

    if(!!props.disabled){
        disabled = true;
    } else{
        disabled = !availability.edit;
    }

    const getInputValue = (id: string | undefined) => {
        if (context !== null) {
            if (id) {
                if (defaultStringValue)
                    return context.getInputValueWithDefault(
                        id,
                        defaultStringValue
                    );
                return context.getInputValue(id);
            }
        }

        return '';
    };

    const changeInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const { id, value } = event.target;
        context?.setInputValue(id, value);
    };

    const getCheckBoxValue = (id: string | undefined) => {
        if (context !== null) {
            if (id) {
                return context.getCheckboxValue(id);
            }
        }

        return false;
    };

    const changeCheckboxValue = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { id, checked } = event.target;
        context?.setCheckboxValue(id, checked);
    };

    if (type === 'number') {
        return (
            // @ts-ignore
            <NumericFormat
                {...props}
                type="text"
                disabled={disabled}
                // @ts-ignore
                value={props.value ? props.value : getInputValue(id)}
                onChange={props.onChange ? props.onChange : changeInputValue}
            />
        );
    }

    if (type !== 'checkbox') {
        return (
            <input
                {...props}
                disabled={disabled}
                value={props.value ? props.value : getInputValue(id)}
                onChange={props.onChange ? props.onChange : changeInputValue}
            />
        );
    }

    return (
        <input
            {...props}
            disabled={disabled}
            checked={props.checked ? props.checked : getCheckBoxValue(id)}
            onChange={props.onChange ? props.onChange : changeCheckboxValue}
        />
    );
}
