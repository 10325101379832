import { inject, injectable } from "inversify";
import ProductKeyTitle from "../../models/trans/ProductKeyNameModel";
import type ITransRESTClient from "../../rest-clients/ITransRESTClient";
import ITransService from "../ITransService";

@injectable()
export default class TranService implements ITransService{
   
  
    @inject('ITransRESTClient')
    private readonly transRESTClient! : ITransRESTClient;
    
    async processProductKey(key:string):Promise<boolean>{
        return await this.transRESTClient.processProductKey(key);
    }
    
    async getProductKeys() : Promise<ProductKeyTitle[]>{
        return await this.transRESTClient.getProductKeys();
    }

    async getActiveProducts(): Promise<string[]> {
        return await this.transRESTClient.getActiveProducts();
    }

    async validateProductKey(key: string): Promise<boolean> {
        return await this.transRESTClient.validateProductKey(key);
    }
}