import { injectable } from "inversify";
import { LiteEvent } from "../../models/LiteEvent";
import ISelectedPledgeItemsService from "../../services/ISelectedPledgeItemsService";
import ShopifyPledgeDetails from "models/shopify/ShopifyPledgeDetails";

@injectable()
export default class SelectedPledgeItemsService implements ISelectedPledgeItemsService{
    private readonly selectedPledges : Map<number, number>
    private readonly selectedItemsChanged = new LiteEvent<never>();
    public get SelectedItemsChanged() { return this.selectedItemsChanged.expose(); }

    constructor(){
        this.selectedPledges = new Map<number, number>();
    }
    clearAllSelections(): void {
        console.log('clear all pledges');
        this.selectedPledges.clear()
    }

    selectPledge(pledge: number): void {
        if(this.selectedPledges.has(pledge)){
            this.selectedPledges.set(pledge, this.selectedPledges.get(pledge)!+1);
        } else{
            this.selectedPledges.set(pledge, 1);
        }

        this.selectedItemsChanged.trigger();
    }

    unSelectPledge(pledge: number): void {
        if(this.selectedPledges.has(pledge)){
            this.selectedPledges.set(pledge, this.selectedPledges.get(pledge)!-1);

            if(this.selectedPledges.get(pledge)){
                this.selectedPledges.delete(pledge);
            }
            

            this.selectedItemsChanged.trigger();
        }
    }
    getTotalSelectedPledges(): number {
        let numberOfSelectedPledges = 0;

        for(let counter of this.selectedPledges.values()){
            numberOfSelectedPledges+=counter;
        }

        return numberOfSelectedPledges;
    }

    getPledges() : ShopifyPledgeDetails[]{
        const results : ShopifyPledgeDetails[] = []

        for(let pledge of this.selectedPledges.keys()){
            results.push({
                variantId : pledge,
                quantity : this.selectedPledges.get(pledge)!
            })
        }

        return results;
    }
    
    
}