import { useInjection } from 'inversify-react';
import { useEffect, useState } from 'react';
import { IAuthService } from '../../../services/IAuthService';
import classes from '../../../utils/classes';
import DownloadLauncherButton from '../../download-launcher-button/download-launcher-button.component';
import styles from './customer-cp-navigation.module.scss';
import IRecordSheetService from '../../../modules/record-sheets-module/services/IRecordSheetService';
import INavigationDataService from '../../../services/INavigationDataService';

export default function CustomerCPNavigation({ hash }: { hash: string }) {
    const [isRecordsDisabled, setRecordsDisabled] = useState(true);

    const authService = useInjection<IAuthService>('IAuthService');
    const settlementService = useInjection<IRecordSheetService>(
        'IRecordSheetService'
    );
    const navigationDataService = useInjection<INavigationDataService>(
        'INavigationDataService'
    );
    const menuContent = navigationDataService.getRouteLabels();

    useEffect(() => {
        settlementService.getSettlementList(1).then((x) => {
            if (x) {
                setRecordsDisabled(!(x.max_results > 0));
            }
        });
    }, []);

    const isVisible = (menu: any) => {
        return (
            authService.getAccessLevel('devops') >= menu.level ||
            authService.getAccessLevel('data') >= menu.level ||
            authService.getAccessLevel('auth') >= menu.level ||
            authService.getLocalISS()?.endsWith("@kingdomdeath.com")
        );
    };

    return (
        <div id="mySidenav" className={styles.sidenav}>
            {menuContent.map((menu, i) => {
                if (!isVisible(menu)) return '';

                let target = `#${menu.route}`;
                if (menu.route.startsWith('http')) target = menu.route;
                const active = hash === target || (menu.default && !hash);
                let localClasses = [];
                if (active) {
                    localClasses.push(styles.active);
                }
                if (menu.title === 'Campaigns') {
                    if (isRecordsDisabled) {
                        localClasses.push(styles.disabled);
                    }
                }
                return (
                    <a
                        href={target}
                        key={i}
                        className={classes(...localClasses)}
                    >
                        <div>
                            {menu.title}
                            {!!menu.permanentTooltip && <p style={{ fontSize: '10px' }}>
                                {menu.permanentTooltip}
                            </p>}
                        </div>
                    </a>
                );
            })}
        </div>
    );
}
