import React from 'react';
import { isSafari } from 'react-device-detect';
import styles from './styles/ark-survivor-sheet.module.scss';
import StateValueWrapper from '../../../../models/stateFragments/StateValueWrapper';
import KDHTMLInput from '../../../../../../components/general/kd-html-input/KDHtmlInput.component';
import KDHTMLProgress from '../../../../../../components/general/kd-html-input/KDHtmlProgress.component';
import classes from '../../../../../../utils/classes';
import KDHtmlBoldable from '../../../../../../components/general/kd-html-input/KDHtmlBoldableCheckbox';
import renderFor, { renderForEach } from '../../../../../../utils/renderFor';
import KDHTMLCustomCheckbox from '../../../../../../components/general/kd-html-input/KDHtmlCustomCheckbox';
import { LockIcon, ShieldIcon } from '../../../../../../components/icons';
import { StateValueWrapperProvider } from '../../../../contexts/StateValueWrapperContext';
import SurvivorInfo from '../survivor-info/survivor-info.component';
import { SurvivorInfoSideBar } from './survivor-info-side-bar.component';

interface SurvivorSheetProps {
    stateValueWrapper: StateValueWrapper;
    lanternYearChanged: (year: number) => void;
    id: number;
    neighbours: number[];
    openSurvivor: (id: number) => void;
    print?: boolean;
}

interface ISurvivorSheetProps extends SurvivorSheetProps {
    genders: string[];
}

const SurvivorName = ({ genders, ...rest }: ISurvivorSheetProps) => {
    const swapGender = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = event.target;
        if (id === 'male_check') {
            rest.stateValueWrapper.setCheckboxValue(id, checked);
        } else {
            rest.stateValueWrapper.setCheckboxValue('male_check', !checked);
        }
    };
    return (
        <>
            <div className={styles.nameContainer}>
                <div className={styles.nameInnerContainer}>
                    <div className={styles.nameInputContainer}>
                        <p className={styles.name}>Name</p>
                        <KDHTMLInput id="survivor_name" type="text" />
                    </div>

                    <div className={styles.genderCheckboxContainer}>
                        <label
                            htmlFor="male_check"
                            className={styles.genderLabel}
                        >
                            <span>M</span>
                            <div className={styles.checkboxContainer}>
                                <KDHTMLInput
                                    type="checkbox"
                                    id="male_check"
                                    name="male_check"
                                    value="male_check"
                                    onChange={swapGender}
                                    checked={rest.stateValueWrapper.getCheckboxValue(
                                        'male_check'
                                    )}
                                />
                                <span className={styles.checkmark}></span>
                            </div>
                        </label>
                        <label
                            htmlFor="female_check"
                            className={styles.genderLabel}
                        >
                            <span>F</span>
                            <div className={styles.checkboxContainer}>
                                <KDHTMLInput
                                    type="checkbox"
                                    id="female_check"
                                    name="female_check"
                                    value="female_check"
                                    onChange={swapGender}
                                    checked={
                                        !rest.stateValueWrapper.getCheckboxValue(
                                            'male_check'
                                        )
                                    }
                                />
                                <span className={styles.checkmark}></span>
                            </div>
                        </label>
                    </div>
                </div>
                <div className={styles.nameSeparator} />
                <div className={styles.nameContainerSecond}>
                    <p className={styles.nameHelper}>
                        When you name your survivor, gain <b>+1 survival</b>
                    </p>
                    <div className={styles.deadContainer}>
                        <p>Dead</p>
                        <KDHTMLCustomCheckbox
                            id="survivor_dead"
                            containerClass={classes(
                                styles.checkboxContainer,
                                styles.survivalCheckBox
                            )}
                        />
                    </div>
                </div>
                <div className={styles.nameContainerThird}>
                    <p className={classes(styles.nameHelper, styles.title)}><b>Savior:</b></p>
                    <SaviourType stateValueWrapper={rest.stateValueWrapper}/>
                </div>
            </div>
        </>
    );
};

const HuntXP = () => {
    return (
        <div>
            <div className={styles.huntXPContainer}>
                <div className={styles.firstLine}>
                    <p className={styles.huntXPHeader}>Hunt XP</p>
                    <div className={styles.huntXCheckBoxContainer}>
                        <KDHTMLProgress
                            count={16}
                            prefix="hunt_xp_"
                            item={(index, change) => {
                                const id = `hunt_xp_${index}`;
                                let currentClass = classes(
                                    styles.checkboxContainer,
                                    styles.checkboxHuntXP
                                );
                                if (index === 15) {
                                    currentClass = classes(
                                        currentClass,
                                        styles.boldCheckboxContainer
                                    );
                                }

                                return (
                                    <KDHtmlBoldable
                                        id={id}
                                        className={currentClass}
                                        boldclass={styles.boldCheckboxContainer}
                                        key={`checkbox_hunt_bold_${index}`}
                                        onChange={(e) => change(index, e)}
                                    />
                                );
                            }}
                        />
                    </div>
                </div>
                <div className={styles.huntXPSeparator}></div>

                <div className={styles.secondLine}>
                    {renderFor(0, 5, (index) => {
                        if (index === 4) {
                            return (
                                <div
                                    key={index}
                                    className={styles.ageContainer}
                                >
                                    <div
                                        className={classes(
                                            styles.blackRectangle,
                                            styles.big
                                        )}
                                    >
                                        <div className={styles.circle} />
                                    </div>
                                    <p className={styles.retired}>Retired</p>
                                </div>
                            );
                        }
                        const generalView = (
                            <div key={index} className={styles.ageContainer}>
                                {renderFor(0, index + 1, (rectangleIndex) => {
                                    const view = (
                                        <div
                                            key={rectangleIndex}
                                            className={styles.blackRectangle}
                                        />
                                    );
                                    return view;
                                })}
                                <p>
                                    {index === 0
                                        ? 'Adopt a Philosophy'
                                        : 'Rank'}
                                </p>
                            </div>
                        );
                        return generalView;
                    })}
                </div>
            </div>
        </div>
    );
};

const WeaponProficiency = () => {
    return (
        <div className={styles.weaponProficiencyContainer}>
            <div className={styles.weaponProficiencyLeft}>
                <p className={styles.weaponProficiencyHeader}>
                    Weapon Proficiency
                </p>
                <div className={styles.typeSeparatorContainer}>
                    <p className={styles.typeLabel}>Type :</p>
                    <div className={styles.typeSeparator}>
                        <KDHTMLInput
                            className={styles.weaponInput}
                            id="weapon_proficiency"
                            type="text"
                        />
                    </div>
                </div>
                <p className={styles.typeHelper}>Select Before Hunt</p>
            </div>

            <div className={styles.weaponProficiencyRight}>
                <div className={styles.checkboxesContainer}>
                    <KDHTMLProgress
                        count={8}
                        prefix="weapon_xp_"
                        item={(index, change, id) => {
                            let bold = undefined;
                            if (index === 2 || index === 7) {
                                bold = styles.boldCheckboxContainer;
                            }
                            return (
                                <KDHTMLCustomCheckbox
                                    type="checkbox"
                                    id={id}
                                    key={id}
                                    containerClass={classes(
                                        styles.checkboxContainer,
                                        styles.checkboxWeaponXP,
                                        bold
                                    )}
                                    onChange={(event) => change(index, event)}
                                />
                            );
                        }}
                    />
                </div>

                <div className={styles.weaponProficiencyRightInnerContainer}>
                    <div className={styles.specialistContainer}>
                        <div className={styles.blackRectangle} />
                        <p>Specialist</p>
                    </div>

                    <div className={styles.masterContainer}>
                        <div className={styles.blackRectangle} />
                        <div
                            className={classes(
                                styles.blackRectangle,
                                styles.second
                            )}
                        />
                        <p>Master</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface ISurvivorboxProps {
    survivalCheckLabels: {
        label: string;
        id: string;
    }[];
}

const SurvivalBox = ({ survivalCheckLabels }: ISurvivorboxProps) => {
    return (
        <div className={styles.survivalContainer}>
            <div className={styles.survivalLeft}>
                <div className={styles.inputContainer}>
                    <div className={styles.survivalinputcontainer}>
                        <KDHTMLInput
                            className={styles.inputField}
                            type="number"
                            id="survival_count"
                            name="survival_count"
                            min="0"
                            max="99"
                        />
                    </div>
                </div>
                <div className={styles.survivalLeftLabelContainer}>
                    <p className={styles.survivalHeader}>Survival</p>
                    <div className={styles.survivalCheckContainer}>
                        <KDHTMLCustomCheckbox
                            containerClass={classes(
                                styles.checkboxContainer,
                                styles.survivalCheckBox
                            )}
                            id="survival_check"
                            name="survival_check"
                        />
                        <LockIcon />
                        <p className={styles.survivalHelper}>
                            Cannot spend survival
                        </p>
                    </div>
                </div>
            </div>

            <div className={styles.survivalRightContainer}>
                <div className={styles.survivalRight}>
                    {renderForEach(survivalCheckLabels, (element, index) => {
                        return (
                            <div
                                key={index}
                                className={styles.survivalCheckLabelsContainer}
                            >
                                <KDHTMLCustomCheckbox
                                    containerClass={classes(
                                        styles.checkboxContainer,
                                        styles.survivalCheckBoxRight
                                    )}
                                    id={element.id}
                                    name={element.id}
                                    key={element.id}
                                    checked={index === 0}
                                />
                                <span
                                    key={index}
                                    className={styles.survivorCheckLabel}
                                >
                                    {element.label}
                                </span>
                            </div>
                        );
                    })}
                </div>
                <div className={styles.injuryStatContainer}>
                    <div className={styles.injuryStatInnerContainer}>
                        <div className={styles.injuryStatBoxInput}>
                            <KDHTMLInput
                                type="text"
                                id="pressure"
                                className={styles.attribute_count}
                                name="pressure"
                            />
                        </div>
                        <span className={styles.statLabel}>S.Pressure</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

const MovementAndBrain = () => {
    const movements = [
        {
            label: 'Accuracy',
            id: 'accuracy_count',
        },
        {
            label: 'Strength',
            id: 'strength_count',
        },
        {
            label: 'Evasion',
            id: 'evasion_count',
        },
        {
            label: 'Luck',
            id: 'luck_count',
        },
        {
            label: 'Speed',
            id: 'speed_count',
        },
        {
            label: 'Luminosity',
            id: 'luminosity',
        },
    ];
    return (
        <div className={styles.movementAndBrainContainer}>
            <div className={styles.movementContainer}>
                <div className={styles.movementLabelContainer}>
                    <div className={styles.movementInputContainer}>
                        <KDHTMLInput
                            className={styles.inputField}
                            type="number"
                            id="movement"
                            name="movement"
                            min="0"
                            max="99"
                        />
                    </div>
                    <p className={styles.movementLabel}>Movement</p>
                </div>

                {renderForEach(movements, (element, index) => {
                    const left = `${50 + index * 46}px`;
                    return (
                        <div
                            key={index}
                            className={styles.statContainer}
                            style={{ left: left }}
                        >
                            <div className={styles.statBoxInput}>
                                <KDHTMLInput
                                    type="text"
                                    id={element.id}
                                    className={styles.attribute_count}
                                    name={element.id}
                                />
                            </div>
                            <p className={styles.statLabel}>{element.label}</p>
                        </div>
                    );
                })}
            </div>

            <div className={styles.brainContainer}>
                <div className={styles.brainLeft}>
                    <div className={styles.insanityShield}>
                        <div className={styles.insanityShieldIcon}>
                            <ShieldIcon />
                        </div>
                        <KDHTMLInput
                            className={styles.insanityInput}
                            type="number"
                            id="insanity_count"
                            name="insanity_count"
                            min="0"
                            max="99"
                        />
                    </div>
                    <p className={styles.insanityLabel}>Insanity</p>
                </div>

                <div className={styles.brainRight}>
                    <div className={styles.brainRightInnerContainer}>
                        <p className={styles.brainHeader}>Brain</p>
                        <p className={styles.brainHelper}>
                            If your insanity is 3+, you are <b>insane</b>
                        </p>
                    </div>

                    <KDHTMLCustomCheckbox
                        containerClass={classes(
                            styles.checkboxContainer,
                            styles.braincheckbox
                        )}
                        id="brain_check"
                        name="brain_check"
                    />
                </div>
                <div className={styles.brainStatContainer}>
                    <div className={styles.brainStatInnerContainer}>
                        <div className={styles.brainStatBoxInput}>
                            <KDHTMLInput
                                type="text"
                                id="torment"
                                className={styles.attribute_count}
                                name="torment"
                            />
                        </div>
                        <span className={styles.statLabel}>Torment</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

const CourageAndUnderStanding = () => {
    return (
        <>
            <div className={styles.courageContainer}>
                <div className={styles.courageLeft}>
                    <div className={styles.content}>
                        <p className={styles.header}>Courage</p>

                        <div className={styles.courageCheckBoxContainer}>
                            <KDHTMLProgress
                                count={9}
                                prefix="courage_xp_"
                                item={(index, change, id) => {
                                    let bold = undefined;
                                    if (index === 2 || index === 8) {
                                        bold = styles.boldCheckboxContainer;
                                    }
                                    return (
                                        <KDHTMLCustomCheckbox
                                            type="checkbox"
                                            id={id}
                                            key={id}
                                            containerClass={classes(
                                                styles.checkboxContainer,
                                                styles.courageCheckBox,
                                                bold
                                            )}
                                            onChange={(event) =>
                                                change(index, event)
                                            }
                                        />
                                    );
                                }}
                            />
                        </div>

                        <div className={styles.infoRow}>
                            <div className={styles.infoRowLeft}>
                                <div className={styles.blackRectangle} />
                                <p className={styles.icon}>s</p>
                                <p className={styles.withBook}>Bold</p>
                            </div>
                            <div className={styles.infoRowLeft}>
                                <div
                                    className={
                                        styles.infoBlackRectangelContainer
                                    }
                                >
                                    <div className={styles.blackRectangle} />
                                    <div className={styles.blackRectangle} />
                                </div>
                                <p className={styles.icon}>s</p>
                                <p className={styles.withBook}>See the Truth</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.understandingContainer}>
                    <div className={styles.content}>
                        <p className={styles.header}>Understanding</p>

                        <div className={styles.understandingCheckBoxContainer}>
                            <KDHTMLProgress
                                count={9}
                                prefix="understanding_xp_"
                                item={(index, change, id) => {
                                    let bold = undefined;
                                    if (index === 2 || index === 8) {
                                        bold = styles.boldCheckboxContainer;
                                    }
                                    return (
                                        <KDHTMLCustomCheckbox
                                            type="checkbox"
                                            id={id}
                                            key={id}
                                            containerClass={classes(
                                                styles.checkboxContainer,
                                                styles.understandingCheckBox,
                                                bold
                                            )}
                                            onChange={(event) =>
                                                change(index, event)
                                            }
                                        />
                                    );
                                }}
                            />
                        </div>

                        <div className={styles.infoRow}>
                            <div className={styles.infoRowLeft}>
                                <div className={styles.blackRectangle} />
                                <p className={styles.icon}>s</p>
                                <p className={styles.withBook}>Insight</p>
                            </div>
                            <div className={styles.infoRowLeft}>
                                <div
                                    className={
                                        styles.infoBlackRectangelContainer
                                    }
                                >
                                    <div className={styles.blackRectangle} />
                                    <div className={styles.blackRectangle} />
                                </div>
                                <p className={styles.icon}>s</p>
                                <p className={styles.withBook}>White Secret</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const Injuries = () => {
    const injuriesData = [
        {
            icon: 'D',
            label: 'Head',
            id: 'head_count',
            toggle_count: 1,
            toggle_prefix: 'head_toggle_',
            injuries: [
                {
                    start: 0,
                    count: 1,
                    prefix: 'injury_0_',
                    label: 'Intracranial Hemorrhage',
                },
                {
                    start: 1,
                    count: 1,
                    prefix: 'injury_1_',
                    label: 'Deaf',
                },
                {
                    start: 2,
                    count: 2,
                    prefix: 'injury_2_',
                    label: 'Blind',
                },
                {
                    start: 4,
                    count: 1,
                    prefix: 'injury_3_',
                    label: 'Shattered Jaw',
                },
            ],
        },
        {
            icon: 'F',
            label: 'Arms',
            id: 'arms_count',
            toggle_count: 2,
            toggle_prefix: 'arms_toggle_',
            injuries: [
                {
                    start: 5,
                    count: 2,
                    prefix: 'injury_4_',
                    label: 'Dismembered Arm',
                },
                {
                    start: 7,
                    count: 1,
                    prefix: 'injury_5_',
                    label: 'Ruptured Muscle',
                },
                {
                    start: 8,
                    count: 5,
                    prefix: 'injury_6_',
                    label: 'Contracture',
                },
                {
                    start: 13,
                    count: 2,
                    prefix: 'injury_7_',
                    label: 'Brokent Arm',
                },
            ],
        },
        {
            icon: 'G',
            label: 'Body',
            id: 'body_count',
            toggle_count: 2,
            toggle_prefix: 'body_toggle_',
            injuries: [
                {
                    start: 15,
                    count: 5,
                    prefix: 'injury_8_',
                    label: 'G. Chest Wound',
                },
                {
                    start: 20,
                    count: 1,
                    prefix: 'injury_9_',
                    label: 'Destroyed Back',
                },
                {
                    start: 21,
                    count: 5,
                    prefix: 'injury_10_',
                    label: 'Brokent Rib',
                },
            ],
        },
        {
            icon: 'H',
            label: 'Waist',
            id: 'waist_count',
            toggle_count: 2,
            toggle_prefix: 'waist_toggle_',
            injuries: [
                {
                    start: 26,
                    count: 1,
                    prefix: 'injury_11_',
                    label: 'Intestinal Prolapse',
                },
                {
                    start: 27,
                    count: 5,
                    prefix: 'injury_12_',
                    label: 'Warped Pelvis',
                },
                {
                    start: 32,
                    count: 1,
                    prefix: 'injury_13_',
                    label: 'Destroyed genitals',
                },
                {
                    start: 33,
                    count: 1,
                    prefix: 'injury_14_',
                    label: 'Broken Hip',
                },
            ],
        },
        {
            icon: 'J',
            label: 'Leg',
            id: 'legs_count',
            toggle_count: 2,
            toggle_prefix: 'legs_toggle_',
            injuries: [
                {
                    start: 34,
                    count: 2,
                    prefix: 'injury_15_',
                    label: 'Dismembered Leg',
                },
                {
                    start: 36,
                    count: 1,
                    prefix: 'injury_16_',
                    label: 'Hamstring',
                },
                {
                    start: 37,
                    count: 2,
                    prefix: 'injury_17_',
                    label: 'Brokent Leg',
                },
            ],
        },
    ];
    return (
        <>
            <div className={styles.injuries}>
                {renderForEach(injuriesData, (element, index) => {
                    return (
                        <div key={index} className={styles.injuryContainer}>
                            <div className={styles.injuryShield}>
                                <div className={styles.injuryShieldIcon}>
                                    <ShieldIcon />
                                </div>
                                <div className={styles.injuryInputContainer}>
                                    <KDHTMLInput
                                        id={element.id}
                                        type="number"
                                        min="0"
                                        max="99"
                                        className={styles.injuryInput}
                                    />
                                </div>
                            </div>
                            <div className={styles.injuryInnerContainer}>
                                <div className={styles.titleContainer}>
                                    <p className={styles.icon}>
                                        {element.icon}
                                    </p>

                                    <p className={styles.injuryTitle}>
                                        {element.label}
                                    </p>
                                </div>

                                <div className={styles.helperContainer}>
                                    <div className={styles.blackRectangle} />

                                    <p className={styles.injuryHelper}>
                                        Heavy Injury
                                    </p>
                                </div>
                            </div>

                            <div className={styles.injuryCenteralContainer}>
                                {renderForEach(
                                    element.injuries,
                                    (injury, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={
                                                    styles.injuryProgress
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.injuryProgressCheckboxes
                                                    }
                                                >
                                                    <KDHTMLProgress
                                                        key={
                                                            injury.prefix +
                                                            index
                                                        }
                                                        start={0}
                                                        count={injury.count}
                                                        prefix={injury.prefix}
                                                        item={(
                                                            index,
                                                            change,
                                                            id
                                                        ) => {
                                                            return (
                                                                <KDHTMLCustomCheckbox
                                                                    type="checkbox"
                                                                    id={id}
                                                                    key={id}
                                                                    containerClass={classes(
                                                                        styles.checkboxContainer,
                                                                        styles.checkboxContainerInjury
                                                                    )}
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        change(
                                                                            index,
                                                                            event
                                                                        )
                                                                    }
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </div>
                                                <span
                                                    className={
                                                        styles.injuryProgressLabel
                                                    }
                                                >
                                                    {injury.label}
                                                </span>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                            <div className={styles.rightCheckBoxContainer}>
                                <KDHTMLProgress
                                    count={element.toggle_count}
                                    prefix={element.toggle_prefix}
                                    item={(index, change, id) => {
                                        if (
                                            (index === 0 &&
                                                element.toggle_count === 1) ||
                                            index === 1
                                        ) {
                                            return (
                                                <div
                                                    className={styles.checkBoxR}
                                                >
                                                    <KDHTMLCustomCheckbox
                                                        containerClass={classes(
                                                            styles.checkboxContainer,
                                                            styles.injuryCheckbox,
                                                            styles.injuryCheckboxBold
                                                        )}
                                                        id={id}
                                                        onChange={e=>change(index, e)}
                                                    />
                                                    <p
                                                        className={classes(
                                                            styles.label,
                                                            styles.high
                                                        )}
                                                    >
                                                        H
                                                    </p>
                                                </div>
                                            );
                                        }
                                        else
                                        {
                                            return (
                                                <div
                                                    className={styles.checkBoxL}
                                                >
                                                    <KDHTMLCustomCheckbox
                                                        containerClass={classes(
                                                            styles.checkboxContainer,
                                                            styles.injuryCheckbox
                                                        )}
                                                        id={id}
                                                        onChange={e=>change(index, e)}
                                                    />
                                                    <p
                                                        className={classes(
                                                            styles.label,
                                                            styles.low
                                                        )}
                                                    >
                                                        L
                                                    </p>
                                                </div>
                                            );
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

const AbilitiesAndDisorders = () => {
    return (
        <div className={styles.AbilitiesAndDisordersContainer}>
            <div className={styles.fightingArtsContainer}>
                <div className={styles.labelContainer}>
                    <div className={classes(styles.fightingHeaderContainer)}>
                        <div className={styles.fightingheader}>
                            Fighting Art & Secret Fighting Art
                            <span>
                                Maximum 1 of Each When you gain a fighting art,
                                instead
                            </span>
                            <span className={styles.icon}>s</span>
                            <span>Vaultless</span>
                        </div>
                    </div>
                </div>
                <div className={styles.horizontalLinesContainer}>
                    {renderFor(0, 2, (index) => {
                        const id = `fighting_art_${index}`;

                        return (
                            <div key={index} className={styles.horizontalLine}>
                                <KDHTMLInput
                                    type="text"
                                    key={id}
                                    id={id}
                                    name={id}
                                    className={styles.survivor_line}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className={styles.disordersContainer}>
                <div className={styles.labelContainer}>
                    <div className={styles.headerContainer}>
                        <p className={styles.header}>Disorders</p>
                        <p className={styles.tooltip}>Maximum 3</p>
                    </div>
                </div>

                <div className={styles.horizontalLinesContainer}>
                    {renderFor(0, 3, (index) => {
                        const id = `disorders_${index}`;

                        return (
                            <div key={index} className={styles.horizontalLine}>
                                <KDHTMLInput
                                    type="text"
                                    key={id}
                                    id={id}
                                    name={id}
                                    className={styles.survivor_line}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className={styles.abilitiesAndImpairmentsContainer}>
                <div className={styles.labelContainer}>
                    <div className={styles.headerContainer}>
                        <p className={styles.header}>Abilities & Impairments</p>
                    </div>
                    <div className={styles.helperContainer}>
                        <KDHTMLCustomCheckbox
                            containerClass={classes(
                                styles.checkboxContainer,
                                styles.fightingArtsCheckbox
                            )}
                            id="hunt_check"
                            name="hunt_check"
                        />

                        <LockIcon />
                        <p>Skip Next Hunt</p>
                    </div>
                </div>

                <div className={styles.horizontalLinesContainer}>
                    {renderFor(0, 4, (index) => {
                        const id = `ability_${index}`;

                        return (
                            <div key={index} className={styles.horizontalLine}>
                                <KDHTMLInput
                                    type="text"
                                    key={id}
                                    id={id}
                                    name={id}
                                    className={styles.survivor_line}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className={styles.oncePerLifeTimeContainer}>
                <div className={styles.labelContainer}>
                    <div className={styles.headerContainer}>
                        <p className={styles.header}>Once Per Lifetime</p>
                    </div>
                    <div className={styles.helperContainer}>
                        <KDHTMLCustomCheckbox
                            containerClass={classes(
                                styles.checkboxContainer,
                                styles.oncePerLifeTimeCheckbox
                            )}
                            id="reroll_check"
                            name="reroll_check"
                        />

                        <LockIcon />
                        <p>Reroll used</p>
                    </div>
                </div>

                <div className={styles.horizontalLinesContainer}>
                    <div className={styles.horizontalLine}>
                        <KDHTMLInput
                            type="text"
                            className={styles.survivor_line}
                            id="lifetime_entry"
                            name="lifetime_entry"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const NextDeparture = () => {
    return (
        <div className={styles.nextDepartureContainer}>
            <p>Next Departure</p>
            <div className={styles.departureInputContainer}>
                <KDHTMLInput
                    className={styles.departureInput}
                    type="text"
                    id={`departure`}
                />
            </div>
        </div>
    );
};

const Philosophy = () => {
    return (
        <div className={styles.philosophyContainer}>
            <div className={styles.header}>
                <div>
                    <p>Philosophy</p>
                    <div className={styles.headerinputContainer}>
                        <KDHTMLInput type="text" id="philosophy" />
                    </div>
                </div>

                <div className={styles.statContainer}>
                    <div className={styles.statBoxInput}>
                        <KDHTMLInput
                            type="number"
                            id="philosophy_count"
                            className={styles.attribute_count}
                        />
                    </div>
                    <p className={styles.statLabel}>Rank</p>
                </div>
            </div>

            <div className={styles.description}>
                <p className={styles.descriptionLabel}>Ponder</p>
                <p className={styles.details}>
                    During the settlement phase, if you have enough Hunt XP, you
                    must rank up your philosophy. Limit one increase per
                    settlement phase.
                </p>
            </div>

            <div className={styles.tenetContainer}>
                <div className={styles.tenetInputContainer}>
                    <div className={styles.tenetNeurosisInput}>
                        <KDHTMLInput type="text" id="tenet_neurosis" />
                    </div>
                    <p>Neurosis</p>
                </div>
                <div className={styles.secondRow}>
                    <div className={styles.tenetInputContainer}>
                        <div className={styles.tenetKnowledgeInput}>
                            <KDHTMLInput type="text" id="tenet_knowledge" />
                        </div>
                        <p>knowledge</p>
                    </div>
                    <div className={styles.tenetProgressContainer}>
                        <KDHTMLProgress
                            prefix="neurosis_xp_"
                            count={9}
                            item={(index, change, id) => {
                                let currentClass = classes(
                                    styles.checkboxContainer,
                                    styles.tenetCheckboxContainer,
                                    id
                                );
                                return (
                                    <KDHtmlBoldable
                                        className={currentClass}
                                        key={id}
                                        id={id}
                                        boldclass={styles.boldArkCheckbox}
                                        onChange={(e) => change(index, e)}
                                    />
                                );
                            }}
                        />
                    </div>
                </div>
                <div className={styles.tenetInputContainer}>
                    <div className={styles.tenetRulesInput}>
                        <KDHTMLInput type="text" id="tenet_rules" />
                    </div>
                    <p>Rules</p>
                </div>
                <div className={styles.tenetInputContainer}>
                    <div className={styles.tenetRevelationInput}>
                        <KDHTMLInput type="text" id="tenet_observation" />
                    </div>
                    <p>Observation Conditions</p>
                </div>
            </div>
        </div>
    );
};

const Knowledge = () => {
    return (
        <div className={styles.knowledgeContainer}>
            <div className={styles.header}>
                <p className={styles.headerLabel}>Knowledge</p>
                <div className={styles.headerInnerContainer}>
                    <div className={styles.headerRowTwo}>
                        <div className={styles.bulletPoint}></div>
                        <div className={styles.knowledgeCheckboxContainer}>
                            <KDHTMLCustomCheckbox
                                containerClass={classes(
                                    styles.checkboxContainer,
                                    styles.knowledgeChecbox
                                )}
                                id="fighting_arts_check"
                                name="fighting_arts_check"
                            />

                            <LockIcon />
                            <p>
                                I you cannot use fighting arts, you cannot use
                                knowledge.
                            </p>
                        </div>
                    </div>
                    <div className={styles.knowledgeHorizontalLine}></div>
                </div>
            </div>

            <div className={styles.knowledgeInnerWrapper}>
                <div className={styles.tenetContainer}>
                    <div className={styles.secondRow}>
                        <div className={styles.tenetInputContainer}>
                            <div className={styles.tenetKnowledgeInput}>
                                <KDHTMLInput type="text" id="knowledge_0" />
                            </div>
                            <p>knowledge name</p>
                        </div>
                        <div className={styles.tenetProgressContainer}>
                            <KDHTMLProgress
                                prefix="knowledge_xp_0_"
                                count={9}
                                item={(index, change, id) => {
                                    let currentClass = classes(
                                        styles.checkboxContainer,
                                        styles.tenetCheckboxContainer,
                                        id
                                    );
                                    return (
                                        <KDHtmlBoldable
                                            className={currentClass}
                                            key={id}
                                            id={id}
                                            boldclass={styles.boldArkCheckbox}
                                            onChange={(e) => change(index, e)}
                                        />
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.tenetInputContainer}>
                        <div className={styles.tenetRulesInput}>
                            <KDHTMLInput type="text" id="knowledge_rules_0" />
                        </div>
                        <p>Rules</p>
                    </div>
                    <div className={styles.tenetInputContainer}>
                        <div className={styles.tenetRevelationInput}>
                            <KDHTMLInput
                                type="text"
                                id="knowledge_observation_0"
                            />
                        </div>
                        <div className={styles.labelContainer}>
                            <p>Observation Conditions</p>
                        </div>
                    </div>
                </div>
                <div className={styles.tenetContainer}>
                    <div className={styles.secondRow}>
                        <div className={styles.tenetInputContainer}>
                            <div className={styles.tenetKnowledgeInput}>
                                <KDHTMLInput type="text" id="knowledge_1" />
                            </div>
                            <p>knowledge name</p>
                        </div>
                        <div className={styles.tenetProgressContainer}>
                            <KDHTMLProgress
                                prefix="knowledge_xp_1_"
                                count={9}
                                item={(index, change, id) => {
                                    let currentClass = classes(
                                        styles.checkboxContainer,
                                        styles.tenetCheckboxContainer,
                                        id
                                    );
                                    return (
                                        <KDHtmlBoldable
                                            className={currentClass}
                                            key={id}
                                            id={id}
                                            boldclass={styles.boldArkCheckbox}
                                            onChange={(e) => change(index, e)}
                                        />
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.tenetInputContainer}>
                        <div className={styles.tenetRulesInput}>
                            <KDHTMLInput type="text" id="knowledge_rules_1" />
                        </div>
                        <p>Rules</p>
                    </div>
                    <div className={styles.tenetInputContainer}>
                        <div className={styles.tenetRevelationInput}>
                            <KDHTMLInput
                                type="text"
                                id="knowledge_observation_1"
                            />
                        </div>
                        <div className={styles.labelContainer}>
                            <p>Observation Conditions</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const SaviourType = ({
    stateValueWrapper,
}: {
    stateValueWrapper: StateValueWrapper;
}) => {
    const saviourType = [
        {
            id: '1',
            title: 'Dream of the Beast',
        },
        {
            id: '2',
            title: 'Dream of the Crown',
        },
        {
            id: '3',
            title: 'Dream of the Lantern',
        },
    ];

    return (
        <div className={styles.saviorSection}>
            {saviourType.map((element, index) => {
                return (
                    <>
                        <div className={styles.saviorElement}>
                            <KDHTMLCustomCheckbox
                                id={`checkbox_${element.id}`}
                                containerClass={classes(
                                    styles.checkboxContainer,
                                    styles.checkbox
                                )}
                                checked={
                                    stateValueWrapper.getInputValueInt(
                                        'saviour_type'
                                    ) -
                                        1 ===
                                    index
                                }
                                onChange={(e) => {
                                    const currentIsChecked =
                                        stateValueWrapper.getInputValueInt(
                                            'saviour_type'
                                        ) -
                                            1 ===
                                        index;
                                    if (!!e.target.checked) {
                                        stateValueWrapper.setInputValue(
                                            'saviour_type',
                                            (index + 1).toString()
                                        );
                                    } else if (currentIsChecked) {
                                        stateValueWrapper.setInputValue(
                                            'saviour_type',
                                            '0'
                                        );
                                    }
                                }}
                            />
                            <p>{element.title}</p>
                        </div>
                        {(index<saviourType.length-1 && <p className={styles.or}><strong>or</strong></p>)}
                    </>
                );
            })}
        </div>
    );
};

const ArkSurvivorSheetComponent = React.forwardRef(
    (props: SurvivorSheetProps, ref: React.Ref<any>) => {
        const { stateValueWrapper } = props;

        const genders = ['M', 'F'];

        const survivalCheckLabels = [
            { label: 'Dodge', id: 'dodge_check' },
            { label: 'Encourage', id: 'encourage_check' },
            { label: 'Surge', id: 'surge_check' },
            { label: 'Dash', id: 'dash_check' },
            { label: 'Fist Pump', id: 'fist_pump_check' },
        ];
        const shouldPrint = !!props.print;

        return (
            <StateValueWrapperProvider value={stateValueWrapper}>
                {!shouldPrint && (
                    <div id="survivor-data">
                        <div className="ark survivor_sheet" id="survivor_sheet">
                            <div
                                ref={ref}
                                className={styles.pageContainer}
                                style={{ opacity: 1 }}
                            >
                                <div className={styles.innerContainer}>
                                    <div className={styles.firstRow}>
                                        <SurvivorName
                                            genders={genders}
                                            {...props}
                                        />
                                        <HuntXP />
                                    </div>

                                    <div className={styles.secondRow}>
                                        <SurvivalBox
                                            survivalCheckLabels={
                                                survivalCheckLabels
                                            }
                                        />
                                        <WeaponProficiency />
                                    </div>

                                    <div className={styles.thirdRow}>
                                        <MovementAndBrain />
                                        <div
                                            className={
                                                styles.thirdRowInnerContainer
                                            }
                                        >
                                            <CourageAndUnderStanding />
                                            <NextDeparture />
                                        </div>
                                    </div>
                                    <div className={styles.fourthRow}>
                                        <Injuries />
                                        <AbilitiesAndDisorders />
                                    </div>
                                </div>

                                <div className={styles.pageRightSide}>
                                    <Philosophy />
                                    <Knowledge />
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {!!shouldPrint && (
                    <>
                    <p  style={{
                               breakAfter: 'page'
                            }}>
                    <div
                            id="survivor-data"
                            style={{
                                position: 'absolute',
                                left: '50%',
                                top: isSafari?'0%' :'20%',
                                transform: isSafari? 'translateX(-50%) scale(1)' : 'translateX(-50%) scale(1.4)',
                                
                            }}
                        >
                            <div
                                className="ark survivor_sheet"
                                id="survivor_sheet"
                            >
                                <div
                                    ref={ref}
                                    className={styles.pageContainer}
                                    style={{ opacity: 1 }}
                                >
                                    <div className={styles.innerContainer}>
                                        <div className={styles.firstRow}>
                                            <SurvivorName
                                                genders={genders}
                                                {...props}
                                            />
                                            <HuntXP />
                                        </div>

                                        <div className={styles.secondRow}>
                                            <SurvivalBox
                                                survivalCheckLabels={
                                                    survivalCheckLabels
                                                }
                                            />
                                            <WeaponProficiency />
                                        </div>

                                        <div className={styles.thirdRow}>
                                            <MovementAndBrain />
                                            <div
                                                className={
                                                    styles.thirdRowInnerContainer
                                                }
                                            >
                                                <CourageAndUnderStanding />
                                                <NextDeparture />
                                            </div>
                                        </div>
                                        <div className={styles.fourthRow}>
                                            <Injuries />
                                            <AbilitiesAndDisorders />
                                        </div>
                                    </div>

                                    <div className={styles.pageRightSide}>
                                        <Philosophy />
                                        <Knowledge />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p>
                       
                        <SurvivorInfoSideBar
                            availableYears={[]}
                            {...props}
                            gearGrid
                            style={{
                                position: 'absolute',
                                top: isSafari? '108%' : '120%',
                                left: '50%',
                                transform: isSafari?'translateX(-40%) scale(1.1)' : 'translateX(-40%) scale(1.5)' ,
                                breakAfter: 'page'
                            }}
                        />
                        <KDHTMLInput
                            style={{
                                position: 'absolute',
                                bottom: '10%',
                                left: '5%',
                                right: '5%'
                            }}
                            id="notes"
                        />
                    </>
                )}
            </StateValueWrapperProvider>
        );
    }
);

export default ArkSurvivorSheetComponent;
