import { useInjection } from 'inversify-react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import IUIService from '../../../../../../services/IUIService';
import SurvivorHeaderClient from '../survivor-sheet-header/survivor-sheet-header-client.component';
import { useRef, useState } from 'react';
import recordStyles from '../../record-styles.module.scss';
import { useReactToPrint } from 'react-to-print';
import { SurvivorType } from '../../../../models/survivors/SurvivorType';
import CoreSurvivorSheet from '../../survivor/survivor-sheet/core-survivor-sheet.component';
import ArkSurvivorSheetComponent from '../../survivor/survivor-sheet/ark-survivor-sheet.component';
import POTStarSurvivorSheet from '../../survivor/survivor-sheet/potstar-survivor-sheet.component';
import POTSunSurvivorSheet from '../../survivor/survivor-sheet/potsun-survivor-sheet.component';
import StateValueWrapper from '../../../../models/stateFragments/StateValueWrapper';
import SurvivorFooter from '../../survivor/survivor-footer/survivor-footer.component';
import SurvivorInfo from '../../survivor/survivor-info/survivor-info.component';
import IRecordSheetService from '../../../../services/IRecordSheetService';
interface SurvivorSheetContainerProps {
    stateValueWrapper: StateValueWrapper;
    refresh: () => void;
    close: () => void;
    update: () => void;
    lanternYearChanged: (year: number) => void;
    copy: () => void;
    paste: () => void;
    saveEnabled: boolean;
    id: number;
    neighbours: number[];
    openSurvivor: (id: number) => void;
    survivorType: SurvivorType;
}

export default function SurvivorSheetContainerClient(
    props: SurvivorSheetContainerProps
) {
    const dodService = useInjection<IRecordSheetService>('IRecordSheetService');
    const uiService = useInjection<IUIService>('IUIService');

    const [infoVisible, setInfoVisible] = useState(false);
    const pageSize = useRef('946px 670px');

    const printRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => printRef.current as any,
        removeAfterPrint: true,
        pageStyle: () => `@page { size: ${pageSize.current}; } #survivor-data { width: unset !important; }`,
    });

    const copiedSurvivor = useSelector(
        (state: RootState) => state.copiedSurvivor.survivor
    );

    const onRefreshSurvivor = () => {
        props.refresh();
    };

    const onUpdateSurvivor = async () => {
        props.update();
    };

    const onCloseSurvivor = () => {
        props.close();
    };

    const onCopySurvivor = () => {
        props.copy();
    };

    const onPasteSurvivor = () => {
        props.paste();
    };

    const isArkSurvivor =
        props.stateValueWrapper.getInputValueInt('survivor_type') ===
        SurvivorType.Ark;

    const isInfoButtonVisible = () => {
        return isArkSurvivor;
    };

    const onInfoClicked = () => {
        if (!isArkSurvivor) {
            return;
        }
        setInfoVisible(!infoVisible);
    };

    const onDeleteSurvivor = async () => {
        const confirm = await uiService.showConfirmationDialog(
            `Are you sure you want to delete survivor ${props.stateValueWrapper.getInputValue(
                'name'
            )}?`,
            'No',
            'Yes'
        );
        if (!confirm) return;
        const response = await dodService.deleteSurvivor(
            props.stateValueWrapper.getInputValueInt('id')
        );
        if (response) {
            uiService.showSuccessNotification('Survivor deleted successfuly');
            onCloseSurvivor();
        } else {
            uiService.showErrorNotification('Failed to remove the survivor!');
        }
    };

    const renderCoreSurvivor = () => {
        return (
            <CoreSurvivorSheet
                availableYears={[]}
                stateValueWrapper={props.stateValueWrapper}
                lanternYearChanged={props.lanternYearChanged}
                id={props.id}
                neighbours={props.neighbours}
                openSurvivor={props.openSurvivor}
            />
        );
    };

    const renderArkSurvivor = () => {
        return (
            <ArkSurvivorSheetComponent
                stateValueWrapper={props.stateValueWrapper}
                lanternYearChanged={props.lanternYearChanged}
                id={props.id}
                neighbours={props.neighbours}
                openSurvivor={props.openSurvivor}
            />
        );
    };

    const renderPOTStarSurvivor = () => {
        return (
            <POTStarSurvivorSheet
                stateValueWrapper={props.stateValueWrapper}
                lanternYearChanged={props.lanternYearChanged}
                id={props.id}
                neighbours={props.neighbours}
                openSurvivor={props.openSurvivor}
            />
        );
    };

    const renderPOTSunSurvivor = () => {
        return (
            <POTSunSurvivorSheet
                stateValueWrapper={props.stateValueWrapper}
                lanternYearChanged={props.lanternYearChanged}
                id={props.id}
                neighbours={props.neighbours}
                openSurvivor={props.openSurvivor}
            />
        );
    };

    const renderSurvivor = () => {
        if (props.survivorType === SurvivorType.Core) {
            pageSize.current = '700px 700px';
            return renderCoreSurvivor();
        }
        if (props.survivorType === SurvivorType.Ark) {
            pageSize.current = '946px 670px';
            return renderArkSurvivor();
        }
        if (props.survivorType === SurvivorType.PoTStar) {
            pageSize.current = '700px 670px';
            return renderPOTStarSurvivor();
        }
        if (props.survivorType === SurvivorType.PoTSun) {
            pageSize.current = '700px 700px';
            return renderPOTSunSurvivor();
        }
    };

    const renderFooter = () => {
        if (props.survivorType !== SurvivorType.Ark) return '';
        return (
            <SurvivorFooter
                neighbours={props.neighbours}
                id={props.id}
                openSurvivor={props.openSurvivor}
            />
        );
    };

    return (
        <form id="survivor-form" className={recordStyles.survivorForm}>
            <div>
                <SurvivorHeaderClient
                    onPrintSurvivorSheet={()=>{console.log(pageSize.current);handlePrint()}}
                    refreshSurvivor={onRefreshSurvivor}
                    updateSurvivorRequested={onUpdateSurvivor}
                    closeSurvivorRequested={onCloseSurvivor}
                    copySurvivorRequested={onCopySurvivor}
                    pasteSurvivorRequested={onPasteSurvivor}
                    deleteSurvivorRequested={onDeleteSurvivor}
                    pasteEnabled={copiedSurvivor !== null}
                    saveEnabled={props.saveEnabled}
                    infoVisible={isInfoButtonVisible()}
                    infoClicked={onInfoClicked}
                />
                <SurvivorInfo
                    stateValueWrapper={props.stateValueWrapper}
                    lanternYearChanged={props.lanternYearChanged}
                    isVisible={infoVisible}
                />
                <div ref={printRef} className='print-area'>{renderSurvivor()}</div>
                {renderFooter()}
            </div>
        </form>
    );
}
