import styles from './ark-third-page.module.scss';
import baseStyles from '../styles/core-settlement.module.scss';
import { SurvivorListComponent } from '../../survivor-list/survivor-list.component';
import { useState } from 'react';
import SheetPopulation from '../../../elements/sheets/sheet-population/sheet-population.component';
import classes from '../../../../../../utils/classes';
import { SheetDeathCount } from '../../../elements/sheets/sheet-death-count/sheet-death-count.component';
import { SettlementType } from '../../../../models/settlements/SettlementType';
import ClientOnly from '../../../../utils/clientOnly';

interface ArkThirdPageProps {
    sheetId: number;
    onOpenSurvivor: (id: number) => void;
    dataAccess : number
    createSurvivor: (name: string) => Promise<any>;
}

export default function ArkThirdPage({
    sheetId,
    onOpenSurvivor,
    createSurvivor,
    dataAccess
}: ArkThirdPageProps) {
    const [newSurvivorCount, setNewSurvivorCount] = useState(0);
    const [newCharacterName, setNewCharacterName] = useState('');

    const onSurvivorNameInput = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        event.preventDefault();
        const { value } = event.target;
        if (value === '') {
            setNewCharacterName('');
        } else {
            setNewCharacterName(value);
        }
    };

    const onCheckCreateSurvivor = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (event.key !== 'Enter' && event.key !== 'Return') return;
        if (newCharacterName === null || newCharacterName === '') return;
        onSurvivorCreation(newCharacterName);
    };

    const onSurvivorCreation = async (name: string) => {
        await createSurvivor(name);
        setNewCharacterName('');
        setNewSurvivorCount(newSurvivorCount + 1);
    };
    return (
        <div className={classes(baseStyles.pageContainer, styles.ark)}>
            <div className={baseStyles.innerContainer}>
                <div className={styles.headerContainer}>
                    <SheetPopulation />
                    <SheetDeathCount layout="ark" />
                </div>
                <SurvivorListComponent
                    fromYear={0}
                    toYear={36}
                    sheetId={sheetId}
                    newSurvivorCount={newSurvivorCount}
                    openSurvivor={onOpenSurvivor}
                    settlementType={SettlementType.Ark}
                />
                {dataAccess >= 1  && !ClientOnly().clientOnly}
                {dataAccess >= 1  && !ClientOnly().clientOnly ? (
                    <div id="create_survivor" 
                    className={styles.createBar}>
                        <input
                            type="text"
                            id="create_survivor_name"
                            name="create_survivor_name"
                            placeholder="New survivor name..."
                            onInput={onSurvivorNameInput}
                            onKeyDown={onCheckCreateSurvivor}
                            value={newCharacterName!}
                        />
                        <button
                            type="button"
                            className={styles.createButton}
                            id="confirm_survivor_creation"
                            onClick={(e) => {
                                if (
                                    newCharacterName === null ||
                                    newCharacterName === ''
                                )
                                    return;
                                onSurvivorCreation(newCharacterName);
                            }}
                            disabled={
                                newCharacterName === null ||
                                newCharacterName === ''
                            }
                        ></button>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
}
