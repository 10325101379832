export default function classes(...classes: (string | undefined)[]): string {
    const value = classes.reduce((prev, current, index, arr) => {
            if (prev === undefined || current === undefined)
                return prev;
            prev = prev + current;
            if (index !== arr.length - 1) {
                prev += ' ';
            }
            return prev;
        }, '')

    if(value===undefined)
        return '';

    return value;
}
