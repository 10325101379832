import styles from './reward-item.module.scss';
import placeholder from '../../../assets/images/placeholder KD.png';
import Pledge from '../../../models/treasury/Pledge';
import { useState } from 'react';

export interface RewardItemProps {
    pledge: Pledge;
    selectionChanged?: (p: Pledge, old: string, v: string) => void;
}

export default function RewardItem({
    pledge,
    selectionChanged,
}: RewardItemProps) {
    const mainImage = pledge?.reward?.extra_info?.imageURL;
    const [oldValue, setOldValue] = useState('noLocation');

    const getDescription = () => {
        if (pledge.reward.reward_status === 2) {
            return pledge.reward.extra_info?.description;
        }
        if (pledge.reward.reward_status === 3) {
            return pledge.reward.extra_info?.description;
        }
        if (pledge.reward.reward_status === 1) {
            let result = '';
            if (!!pledge.reward.extra_info?.description) {
                result += pledge.reward.extra_info!.description;
                result += '\n';
            }
            if (!!pledge.reward.extra_info?.delivery_date) {
                let differenceInTime =
                    new Date(
                        pledge.reward.extra_info!.delivery_date
                    ).getTime() - new Date(Date.now()).getTime();

                // Calculating the no. of days between
                // two dates
                let days = Math.round(differenceInTime / (1000 * 3600 * 24));

                if (days > 0) {
                    result += `Ships in ${days} day${days === 1 ? '' : 's'}`;
                }
            }

            return result;
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.img}>
                <div
                    style={{
                        backgroundImage: `url(${
                            !!mainImage ? mainImage : placeholder
                        })`,
                    }}
                ></div>
            </div>
            <div className={styles.content}>
                <div className={styles.middleContainer}>
                    <div className={styles.header}>
                        {pledge.reward.reward_name}
                    </div>
                    <div>{getDescription()}</div>
                    {pledge.reward.reward_status === 2 && (
                        <div className={styles.selectContainer}>
                            <div className={styles.label}>Ship Now</div>
                            <select
                                onChange={(e) => {
                                    e.preventDefault();
                                    if (!!selectionChanged) {
                                        const newValue = e.target.value;
                                        var prevValue = oldValue;
                                        selectionChanged(
                                            pledge,
                                            prevValue,
                                            e.target.value
                                        );
                                        setOldValue(newValue);
                                    }
                                }}
                            >
                                <option value="noLocation">
                                    Select Shipping Warehouse
                                </option>
                                {pledge.reward.reward_variants.map((rwi) => {
                                    return (
                                        <option
                                            key={rwi.shopify_variant_id}
                                            data-quantity={rwi.quantity}
                                            disabled={rwi.quantity <= 0}
                                            value={rwi.shopify_variant_id.toString()}
                                        >
                                            {rwi.shopify_variant_title}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    )}
                </div>
                {!!pledge.backerkit_email && (<div className={styles.backerkitInfo}>
                    Imported from {pledge.backerkit_email}'s backerkit.
                </div>)}
            </div>
            <div className={styles.pledge}>
                {/* <div className={styles.label}>Pledge / Preorder</div>
                <div>$60.00</div> */}
            </div>
        </div>
    );
}
