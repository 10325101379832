import React from 'react';
import styles from './customer-modal.module.scss';

interface CustomerModalProps {
    children?: React.ReactNode;
    header?: string;
    loading?: boolean;
}

export default function CustomerModal(props: CustomerModalProps) {
    const { children, header } = props;

    const headerElement = (function () {
        if (!header) return '';
        return <div className={styles.modalHeader}>{header}</div>;
    })();

    return (
        <div className={styles.container}>
            <div className={styles.modal}>
                {headerElement}
                {children}
            </div>
            {!!props.loading && (<div className={styles.loadingContainer}>
                <div className={styles.loader}></div>
            </div>)}
        </div>
    );
}
