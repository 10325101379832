import { inject, injectable } from "inversify";
import ExtraPayload from "../../models/ExtraPayload";
import type { IAuthGateRESTClient } from "../../rest-clients/IAuthGateRESTClient";
import IExtraService from "../IExtraService";

@injectable()
export default class ExtraService implements IExtraService{
    @inject('IAuthGateRESTClient')
    private readonly authGate!: IAuthGateRESTClient;


    async parseExtra(extra:string):Promise<ExtraPayload | null>{
        return await this.authGate.parseExtra(extra);
    }
}