import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface LoadingState {
    loading : boolean,
    loadingText? : string,
    isProgress : boolean,
    progress : number,
    progressBarMessage : string
}

const initialState: LoadingState = {
    loading : false,
    loadingText : 'Loading',
    isProgress : false,
    progress : 0,
    progressBarMessage : ''
}

export const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
            state.loadingText = undefined;
            state.isProgress = false;
            state.progress = 0;
            state.progressBarMessage = ''
        },
        setLoadingWithText: (state, action: PayloadAction<{show : boolean, text : string}>) => {
            state.loading = action.payload.show;
            state.loadingText = action.payload.text;
            state.isProgress = false;
            state.progress = 0;
            state.progressBarMessage = ''
        },
        setLoadingWithProgress : (state, action : PayloadAction<boolean>)=>{
            state.loading = action.payload;
            state.isProgress = action.payload;
        },
        setProgress: (state, action: PayloadAction<number>) => {
            state.progress = action.payload
        },
        setProgressMessage: (state, action: PayloadAction<string>) => {
            state.progressBarMessage = action.payload
        }
    },
})

export const { setLoading, setLoadingWithText, setLoadingWithProgress, setProgress, setProgressMessage } = loadingSlice.actions

export const selectLoading = (state: RootState) => state.loading.loading

export default loadingSlice.reducer
