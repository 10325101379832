import React from 'react';
import IList from '../../../models/IList';
import ListViewPagination from '../list-view-pagination/list-view-pagination.component';
import styles from './list-view.module.scss'
import classes from '../../../utils/classes';

export interface ListViewProps<T> {
    elements: IList<T> | null;
    page: number;
    selectionChanged: (id: number) => void;
    selectedValue: number | string| null;
    pageChanged: (page: number) => void;
    listElement:(element : T)=>React.ReactNode;
    containerId? : string,
    listId? : string,
    listClass? : string
}

export default function ListViewComponent<T extends {}>(props: ListViewProps<T>) {
    const onChangeSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        const selectedSettlement = parseInt(value);
        props.selectionChanged(selectedSettlement);
    };

    const changePage = (newPage: number) => {
        props.pageChanged(newPage);
    };

    const containerId = props.containerId === undefined?"settlement-list" : props.containerId;

    const listId = props.listId === undefined? "settlements" : props.listId;

    const listClass = props.listClass === undefined ? "settlements_list" : props.listClass;

    const { elements, page, selectedValue } = props;
    return (
        <div id={containerId}>
            <select
                name={listId}
                id={listId}
                className={classes(listClass, styles.overflow)}
                size={40}
                value={selectedValue !== null ? selectedValue : undefined}
                onChange={onChangeSelection}
            >
                {elements?.getItems().map((element) => (
                    props.listElement(element)
                ))}
            </select>
            <ListViewPagination
                onChangePage={changePage}
                maxCount={elements?.max_results}
                currentPage={page}
            />
        </div>
    );
}
