import styles from './sheet-quarries.module.scss';
import sheetStyles from '../styles/sheet-styles.module.scss';
import { renderForEach } from '../../../../../../utils/renderFor';
import KDHTMLCustomCheckbox from '../../../../../../components/general/kd-html-input/KDHtmlCustomCheckbox';
import classes from '../../../../../../utils/classes';

interface SheetQuarries {
    layout?: 'default' | 'ark';
    idPrefix: string;
    emptyLines: React.ReactNode;
    quarries: {
        id: number;
        label: string;
    }[];
}

const SheetQuarries = ({
    quarries,
    idPrefix,
    layout = 'default',
    emptyLines,
}: SheetQuarries) => {
    const renderQuarries = renderForEach(quarries, (quarry, i) => {
        const id = `${idPrefix}${quarry.id}`;

        return (
            <div key={id} className={styles.quarryHorizontalLine}>
                <div className={styles.quarryLabelContainer}>
                    <KDHTMLCustomCheckbox
                        containerClass={sheetStyles.checkboxContainer}
                        key={`label_${id}`}
                        id={id}
                        checked={quarry.id === -1}
                    />
                    <p className={styles.quarryLabel}>{quarry.label}</p>
                    {layout === 'ark' && (
                        <span className={styles.arkNode}>Node {i + 1}</span>
                    )}
                </div>
            </div>
        );
    });

    return (
        <div className={classes(styles.quarryContainer, styles[layout])}>
            <div className={styles.quarryHeader}>
                <p className={styles.quarryHeaderTitle}>Quarries</p>
                <p className={styles.quaryHelper}>
                    The monsters your settlement can select to hunt.
                </p>
            </div>

            <div
                className={classes(
                    styles.quarryInnerContainer,
                    layout === 'default' ? styles.twoColumn : styles.oneColumn
                )}
            >
                {renderQuarries}
                {emptyLines}
            </div>
        </div>
    );
};

export default SheetQuarries;
