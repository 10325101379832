import { inject } from "inversify";
import CustomerLinkedAccounts from "../../models/linkedAccount/CustomerLinkedAccount";
import type { IAuthService } from "../../services/IAuthService";
import IAuthManagementRESTClient from "../IAuthManagementRESTClient";
import { RESTClient } from "./RESTClient";
import axios from "axios";

export default class AuthManagementRESTClient extends RESTClient implements IAuthManagementRESTClient {

    @inject('IAuthService')
    private readonly authService!: IAuthService;

    constructor() {
        super();
        this.setBaseURL('https://wod-auth-uux56memxa-uc.a.run.app/api/v1/');
    }

    async getLinkedAccounts() : Promise<CustomerLinkedAccounts | null>{
        const token = await this.authService.getToken();
        if (token === null) return null;

        const authToken = await this.getToken(token);

        if (!authToken) return null;

        const iss = await this.authService.getISS();

        if (!iss) return null;

        const url = this.getFullURL(`user/${iss}/linked-accounts`);

        try {
            const response = await axios.get(url, this.getHeader(authToken));
            return response.data as CustomerLinkedAccounts;
        } catch {
            return null;
        }
    }

    async removeLinkedAccount(strategy: string) : Promise<boolean>{
        const token = await this.authService.getToken();
        if (token === null) return false;

        const authToken = await this.getToken(token);

        if (!authToken) return false;

        const url = this.getFullURL(`user/linked-accounts/${strategy}`);

        try {
            await axios.delete(url, this.getHeader(authToken));
            return true;
        } catch {
            return false;
        }
    }

    async createLinkedAccount(strategy: string, data : any) : Promise<boolean>{
        const token = await this.authService.getToken();
        if (token === null) return false;

        const authToken = await this.getToken(token);

        if (!authToken) return false;

        const url = this.getFullURL(`user/linked-accounts/${strategy}`);

        try {
            await axios.put(url, JSON.stringify(data), this.getHeaderJson(this.getHeader(authToken)));
            return true;
        } catch {
            return false;
        }
    }
}