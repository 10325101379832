import { useEffect, useState } from 'react';
import HorizontalLine from '../../general/elements/HorizontalLine/HorizontalLine';
import RewardItem from '../reward-item/reward-item.component';
import styles from './rewards-directory.module.scss';
import Pledge from 'models/treasury/Pledge';
import { useInjection } from 'inversify-react';
import ITreasuryService from 'services/ITreasuryService';
import IUIService from 'services/IUIService';
import React from 'react';
import BottomBar from '../bottom-bar/bottom-bar.component';
import ISelectedPledgeItemsService from '../../../services/ISelectedPledgeItemsService';
import { useMount } from 'ahooks';
import classes from 'utils/classes';

export default function RewardsDirectory() {
    const [availablePledges, setAvailablePledges] = useState<Pledge[]>([]);
    const [claimedPledges, setClaimedPledges] = useState<Pledge[]>([]);
    const [futurePledges, setFuturePledges] = useState<Pledge[]>([]);

    const treasuryService = useInjection<ITreasuryService>('ITreasuryService');
    const uiService = useInjection<IUIService>('IUIService');
    const selectedItemRepository = useInjection<ISelectedPledgeItemsService>(
        'ISelectedPledgeItemsService'
    );

    useEffect(() => {
        uiService.showLoading();
        selectedItemRepository.clearAllSelections();
        treasuryService
            .getPledges()
            .then((p) => {
                uiService.hideLoading();
                if (!!p) {
                    const blownFutureRewards: Pledge[] = [];
                    const blownAvailableRewards: Pledge[] = [];
                    const blownClaimedRewards: Pledge[] = [];

                    for (let i = 0; i < p.length; i++) {
                        const currentPledge = p[i];

                        //pledge still in development
                        if (currentPledge.reward.reward_status === 1) {
                            let count = currentPledge.available_quantity;

                            while (count > 0) {
                                blownFutureRewards.push({
                                    ...currentPledge,
                                    quantity: 1,
                                });

                                count--;
                            }
                        }

                        //pledge was already fulfilleds
                        if (currentPledge.reward.reward_status === 3) {
                            let count = currentPledge.available_quantity;

                            while (count > 0) {
                                blownClaimedRewards.push({
                                    ...currentPledge,
                                    quantity: 1,
                                });

                                count--;
                            }
                        }

                        //pledge available
                        if (currentPledge.reward.reward_status === 2) {
                            let count = currentPledge.available_quantity;

                            while (count > 0) {
                                blownAvailableRewards.push({
                                    ...currentPledge,
                                    reward: {
                                        ...currentPledge.reward,
                                        reward_status: 2,
                                    },
                                    quantity: 1,
                                });

                                count--;
                            }

                            count = currentPledge.claimed_quantity;

                            //pledge was available but already claimed
                            while (count > 0) {
                                blownClaimedRewards.push({
                                    ...currentPledge,
                                    reward: {
                                        ...currentPledge.reward,
                                        reward_status: 3,
                                    },
                                    quantity: 1,
                                });

                                count--;
                            }
                        }
                    }

                    setAvailablePledges(blownAvailableRewards);
                    setClaimedPledges(blownClaimedRewards);
                    setFuturePledges(blownFutureRewards);
                } else {
                    uiService.showErrorNotification('Failed to load pledges');
                }
            })
            .catch(() => {
                uiService.showErrorNotification('Failed to load pledges');
            });
    }, []);

    useEffect(() => {}, [availablePledges]);

    return (
        <div className={styles.container}>
            <div className={styles.list}>
                <RewardSection
                    title="Available Rewards"
                    items={availablePledges}
                />
                <RewardSection title="Rewards in Development" items={futurePledges} />
                <RewardSection title="Fulfilled Rewards" items={claimedPledges} />

                {/* <FAQSection /> */}
            </div>

            <BottomBar />
        </div>
    );
}

interface RewardSectionProps {
    title: string;
    items: any[];
}

function RewardSection(props: RewardSectionProps) {
    const selectedItemRepository = useInjection<ISelectedPledgeItemsService>(
        'ISelectedPledgeItemsService'
    );

    return (
        <div className={styles.list}>
            <div className={styles.title}>{props.title}</div>
            <div className={styles.underTitleRule}></div>
            {!!props.items &&
                props.items.length > 0 &&
                props.items.map((p, i) => {
                    return (
                        <React.Fragment key={`pledge_${i}`}>
                            <RewardItem
                                key={`pledge_${i}`}
                                pledge={p}
                                selectionChanged={(p, old, v) => {
                                    console.log(v);

                                    if (old !== 'noLocation') {
                                        selectedItemRepository.unSelectPledge(
                                            parseInt(old)
                                        );
                                    }

                                    if (v !== 'noLocation') {
                                        selectedItemRepository.selectPledge(
                                            parseInt(v)
                                        );
                                    }
                                }}
                            />
                            <HorizontalLine
                                key={`hr_${i}`}
                                classes={styles.line}
                            />
                        </React.Fragment>
                    );
                })}
            {(!props.items || props.items.length === 0) &&
                <p style={{marginLeft: '8px'}}>No rewards in this section.</p>}
        </div>
    );
}

interface FAQSectionProps {}

function FAQSection(props: FAQSectionProps) {
    return (
        <div className={styles.faqContainer}>
            <FAQLine
                title="The Treasury!"
                body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
            />
            <FAQLine
                title="Items That Reach 90 Days"
                body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
            />
            <FAQLine
                title="Cancelling Items In Your Treasury"
                body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
            />
            <FAQLine
                title="Treasure Eligibility"
                body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
            />
        </div>
    );
}

function FAQLine({ title, body }: { title: string; body: string }) {
    const [show, setShow] = useState(false);
    return (
        <div className={styles.wrapCollabsible}>
            <button
                onClick={(e) => {
                    e.preventDefault();
                    setShow(!show);
                }}
                className={styles.toggle}
                type="button"
            >{title}</button>
            <div
                className={classes(
                    styles.collapsibleContent,
                    !!show ? styles.show : undefined
                )}
            >
                <div className={styles.contentInner}>
                    <p>{body}</p>
                </div>
            </div>
        </div>
    );
}
