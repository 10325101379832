import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { connect, ConnectedProps } from 'react-redux'
import type { RootState } from '../store'

interface CopiedSurvivorState {
    survivor : string | null
}

const initialState: CopiedSurvivorState = {
    survivor : null
}

export const copySurvivorSlice = createSlice({
    name: 'copySurvivor',
    initialState,
    reducers: {
        setCopiedSurvivor: (state, action: PayloadAction<string | null>) => {
            state.survivor = action.payload
        }
    },
})

export const { setCopiedSurvivor } = copySurvivorSlice.actions

export const selectCopiedSurvivor = (state: RootState) => state.copiedSurvivor.survivor

export default copySurvivorSlice.reducer

const mapState = (state:RootState)=>({
    copiedSurvivor : state.copiedSurvivor.survivor
})

const mapDispatch = {
    setCopiedSurvivor : (data : string)=>({type: 'copySurvivor'})
}

export const copyConnector = connect(mapState, mapDispatch)

export type PropsForCopy = ConnectedProps<typeof copyConnector>
