import LoginRequiredPanel from "../../components/login-required-panel/login-required-panel.component"

function LoginPage(){    
    document.title = 'Kingdom Death: Simulator Login'
    return (
        <LoginRequiredPanel/>
    )
}

export default LoginPage
