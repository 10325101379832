import { injectable } from 'inversify'
import { ISessionStorageService } from '../ISessionStorageService'

@injectable()
export class SessionStorageService implements ISessionStorageService {
    private keys!: string[]

    constructor() {
        this.keys = []
    }

    get(key: string): string | null {
        return window.sessionStorage.getItem(key)
    }
    set(key: string, value: string): void {
        this.keys.push(key)
        window.sessionStorage.setItem(key, value)
    }
    has(key: string): boolean {
        return window.sessionStorage.getItem(key) != null
    }

    reset() {
        window.sessionStorage.clear()
    }
}
