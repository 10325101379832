import { injectable } from "inversify";
import ITextureRegistry from "services/ITextureRegistry";

@injectable()
export default class TextureRegistry implements ITextureRegistry{
    getTextureDataCallbackSingleBuffer(url: string, callback: (data: any) => void): void {
        return;
    }
    async hasPendingImageRequests(): Promise<boolean> {
        return false;
    } 

}