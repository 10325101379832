import { SVGProps } from 'react';

export const ShieldIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            style={{
                fill: '#000',
                strokeWidth: 0.264583,
            }}
            d="M112.294 158.942c-1.862-1.856-3.851-4.088-4.421-4.961-1.856-2.842-1.955-3.393-1.965-10.914l-.009-6.681h10.303c5.667 0 10.357.09 10.423.198.065.11.042 3.312-.05 7.116l-.17 6.917-1.32 1.947c-1.253 1.845-8.693 9.751-9.178 9.751-.125 0-1.751-1.518-3.613-3.373zm7.003-.926c3.427-3.468 5.103-5.482 5.962-7.166.479-.939.551-1.72.682-7.36l.147-6.31h-19.395l.003 6.416c.002 6.3.015 6.442.67 7.85 1.063 2.283 2.77 4.467 5.653 7.235 1.476 1.417 2.772 2.576 2.88 2.576.107 0 1.637-1.459 3.399-3.241z"
        />
        <path
            style={{
                fill: '#000',
                strokeWidth: 0.608927,
            }}
            d="M21.106 52.28c-4.284-4.27-8.864-9.408-10.176-11.418-4.27-6.54-4.499-7.809-4.521-25.118L6.389.37H30.1c13.041 0 23.835.205 23.986.456.151.252.099 7.621-.116 16.377l-.39 15.92-3.039 4.48c-2.881 4.247-20.004 22.442-21.12 22.442-.29 0-4.032-3.494-8.316-7.764zm16.117-2.131c7.887-7.98 11.744-12.616 13.72-16.491 1.103-2.161 1.27-3.96 1.572-16.94l.337-14.522H8.216l.007 14.766c.004 14.5.033 14.826 1.541 18.065C12.21 40.282 16.14 45.31 22.775 51.68c3.397 3.26 6.379 5.928 6.627 5.928.247 0 3.768-3.357 7.822-7.46z"
        />
    </svg>
);
