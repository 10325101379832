import { ClickEvent, Menu, MenuButton, MenuItem } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import React from 'react'
import { SettlementType } from '../../models/settlements/SettlementType';

interface CreateSettlementProps {
    onCreateSettlement(name: string, type: SettlementType): void
}

export default function CreateSettlement(props: CreateSettlementProps) {
    const { onCreateSettlement } = props

    const [settlementName, setSettlementName] = React.useState('')

    const createNewSettlement = (type : SettlementType) => {
        onCreateSettlement(settlementName, type);
        setSettlementName('');
    }

    const changeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setSettlementName(value)
    }

    const onItemClick = (e : ClickEvent)=>{
        createNewSettlement(e.value as SettlementType);
    }

    return (
        <div id="create_settlement">
            <form id="create_settlement_form">
                <input
                    type="text"
                    id="create_settlement_name"
                    name="create_settlement_name"
                    placeholder="New settlement name..."
                    value={settlementName}
                    onInput={changeName}
                />
            </form>
            <Menu menuButton={<MenuButton id="confirm_settlement_creation" disabled={settlementName===''}></MenuButton>} onItemClick={onItemClick}>
                <MenuItem value={SettlementType.Core}>Core Settlement</MenuItem>
                <MenuItem value={SettlementType.Ark}>Ark Settlement</MenuItem>
                <MenuItem value={SettlementType.POTStar}>People of Star</MenuItem>
                <MenuItem value={SettlementType.POTSun}>People of Sun</MenuItem>
            </Menu>
        </div>
    )
}
