import { resolve } from 'inversify-react';
import React from 'react';
import {
    copyConnector,
    PropsForCopy,
    setCopiedSurvivor,
} from '../../../../../../redux/slices/copiedSurvivorSlice';
import store from '../../../../../../redux/store';
import type IUIService from '../../../../../../services/IUIService';
import SurvivorSheetContainerClient from '../survivor-sheet-container/survivor-sheet-container-client.component';
import BaseSurvivor from '../../../../models/survivors/BaseSurvivor';
import CoreSurvivor from '../../../../models/survivors/CoreSurvivor';
import { SurvivorType } from '../../../../models/survivors/SurvivorType';
import ArkSurvivor from '../../../../models/survivors/ArkSurvivor';
import PoTStarSurvivor from '../../../../models/survivors/PoTStarSurvivor';
import PoTSunSurvivor from '../../../../models/survivors/PoTSunSurvivor';
import BaseSurvivorSheetState from '../../../../models/stateFragments/survivor/BaseSurvivorSheetState';
import StateValueWrapper from '../../../../models/stateFragments/StateValueWrapper';
import type IRecordSheetService from '../../../../services/IRecordSheetService';
import * as ISurvivorModelMapperService from '../../../../services/ISurvivorModelMapperService';
import ISurvivorAbilitiesContainer from '../../../../models/survivors/base/ISurvivorAbilitiesContainer';
import ISurvivorDisordersContainer from '../../../../models/survivors/base/ISurvivorDisordersContainer';
import ISurvivorGearContainer from '../../../../models/survivors/base/ISurvivorGearContainer';
import ISurvivorInjuriesContainer from '../../../../models/survivors/base/ISurvivorInjuriesContainer';

interface SurvivorDirectoryProps extends PropsForCopy {
    id: number;
    settlementId: number;
    close: () => void;
    fromYear: number;
    toYear: number;
    openSurvivorSheet: (id: number) => void;
}

interface SurvivorDirectoryState {
    survivorSheet: BaseSurvivorSheetState | null;
    id: number;
    currentSurvivor: BaseSurvivor | null;
    survivorYearMap: Map<number, BaseSurvivor>;
    saveEnabled: boolean;
    neighbours: number[];
}

interface SurvivorProtocol<T> extends BaseSurvivor, ISurvivorAbilitiesContainer, ISurvivorDisordersContainer, ISurvivorGearContainer, ISurvivorInjuriesContainer {
    alternative_survivors?: T[];
    parent_id?: number;
}

class SurvivorDirectoryClient extends React.Component<
    SurvivorDirectoryProps,
    SurvivorDirectoryState
> {
    @resolve('IRecordSheetService')
    private readonly recordSheetService!: IRecordSheetService;

    @resolve('IUIService')
    private readonly uiService!: IUIService;

    @resolve('ISurvivorModelMapperService')
    private readonly survivorMapperService!: ISurvivorModelMapperService.ISurvivorModelMapperService;

    constructor(props: SurvivorDirectoryProps) {
        super(props);
        this.state = {
            survivorSheet: null,
            id: props.id,
            currentSurvivor: null,
            survivorYearMap: new Map<number, CoreSurvivor>(),
            saveEnabled: false,
            neighbours: [],
        };
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate() {
        if (this.state.id !== this.props.id) this.getData();
    }

    getData = async (selected: number = 0) => {
        this.uiService.showLoading();
        const response = await this.recordSheetService.getSurvivorSheet(
            this.props.id
        );
        const neighbours = await this.recordSheetService.getSurvivorNeighbours(
            this.props.settlementId,
            this.props.id,
            this.props.fromYear,
            this.props.toYear
        );
        if (response === null) {
            this.uiService.hideLoading();
            this.uiService.showErrorNotification('Failed to get survivor data');
            return;
        }

        const survivorYearMap = new Map<number, BaseSurvivor>();
        if (!!response.lantern_year)
            survivorYearMap.set(response.lantern_year, response);
        if (!!response.alternative_survivors) {
            for (let i = 0; i < response.alternative_survivors.length; i++) {
                const currentYearSurvivor = response.alternative_survivors[i];
                if (currentYearSurvivor.lantern_year)
                    survivorYearMap.set(
                        currentYearSurvivor.lantern_year,
                        currentYearSurvivor
                    );
            }
        }
        this.setState(
            {
                ...this.state,
                currentSurvivor: response,
                survivorYearMap: survivorYearMap,
                saveEnabled: false,
                neighbours: neighbours,
            },
            () => {
                if (this.state.currentSurvivor === null) {
                    this.uiService.showErrorNotification(
                        'Failed to get the survivor!'
                    );
                    return;
                }

                const { currentSurvivor } = this.state;

                let updateSurvivorViewModel : BaseSurvivor;

                if (currentSurvivor.survivor_type === SurvivorType.Core) {
                    const coreSurvivor = currentSurvivor as CoreSurvivor;
                    updateSurvivorViewModel = this.getSurvivorOfYear(coreSurvivor, selected);

                } else if (currentSurvivor.survivor_type === SurvivorType.Ark) {
                    const arkSurvivor = currentSurvivor as ArkSurvivor;
                    updateSurvivorViewModel = this.getSurvivorOfYear(arkSurvivor, selected);
                } else if (
                    currentSurvivor.survivor_type === SurvivorType.PoTStar
                ) {
                    const potStarSurvivor = currentSurvivor as PoTStarSurvivor;
                    updateSurvivorViewModel = this.getSurvivorOfYear(potStarSurvivor, selected);
                }else if (
                    currentSurvivor.survivor_type === SurvivorType.PoTSun
                ) {
                    const potSunSurvivor = currentSurvivor as PoTSunSurvivor;
                    updateSurvivorViewModel = this.getSurvivorOfYear(potSunSurvivor, selected);
                }

                
                this.updateSurvivorViewDataFromCore(
                    updateSurvivorViewModel!,
                    false
                );

                this.uiService.hideLoading();
            }
        );
    };

    updateValues = (o: BaseSurvivorSheetState, enableSave: boolean = true) => {
        this.setState({
            ...this.state,
            id: this.props.id,
            survivorSheet: o,
            saveEnabled: enableSave,
        });
    };
    
    getSurvivorOfYear<T extends SurvivorProtocol<T>>(survivor : T, lanternYear : number){
        if(lanternYear<=1){
            return survivor;
        }
        if(!survivor.alternative_survivors)
            return survivor;

        if(survivor.lantern_year === lanternYear)
            return survivor;

        const targetSurvivor = survivor.alternative_survivors.find(x=>x.lantern_year===lanternYear);

        if(!targetSurvivor){
            return survivor;
        }

        return targetSurvivor;
    }

    onRefresh = () => {
        const year = parseInt(
            this.state.survivorSheet!.values!.get('lantern_year')!
        );
        console.log(year);
        this.getData(year);
    };

    onClose = () => {
        this.props.close();
    };

    copyInternal<T extends SurvivorProtocol<T>>() {
        if (this.state.survivorSheet === null) return;

        if (this.state.currentSurvivor === null) {
            return;
        }

        const survivor = this.survivorMapperService.mapFromState<
            T,
            BaseSurvivorSheetState
        >(this.state.survivorSheet, this.state.currentSurvivor!.survivor_type);
        if (survivor === null) return;
        survivor.id = 0;
        survivor.alternative_survivors = [];
        store.dispatch(setCopiedSurvivor(JSON.stringify(survivor)));
    }

    onCopy = () => {
        if (this.state.survivorSheet === null) return;

        if (this.state.currentSurvivor === null) {
            return;
        }

        if (this.state.currentSurvivor.survivor_type === SurvivorType.Core) {
            this.copyInternal<CoreSurvivor>();
        } else if (
            this.state.currentSurvivor.survivor_type === SurvivorType.Ark
        ) {
            this.copyInternal<ArkSurvivor>();
        } else if (
            this.state.currentSurvivor.survivor_type === SurvivorType.PoTStar
        ) {
            this.copyInternal<PoTStarSurvivor>();
        }else if (
            this.state.currentSurvivor.survivor_type === SurvivorType.PoTSun)
        {
            this.copyInternal<PoTSunSurvivor>();
        }

        this.uiService.showSuccessNotification('Survivor copied to clipboard!');
    };

    onPasteInternal<T extends SurvivorProtocol<T>>(survivorObject: any) {
        const survivorCopied = survivorObject as T;

        const year = parseInt(
            this.state.survivorSheet!.values!.get('lantern_year')!
        );
        const parentId = parseInt(
            this.state.survivorSheet!.values!.get('parent_id')!
        );
        survivorCopied.parent_id = parentId;
        survivorCopied.lantern_year = year;
        if (this.state.survivorYearMap.has(year)) {
            const availableSurvivor = this.state.survivorYearMap.get(year);
            if (availableSurvivor !== undefined) {
                survivorCopied.id = availableSurvivor.id;
            }
        }
        this.updateSurvivorViewDataFromCore(survivorCopied, true);
    }

    onPaste = () => {
        if (this.props.copiedSurvivor === null) {
            return;
        }
        const survivorObject = JSON.parse(this.props.copiedSurvivor);

        const survivorType = survivorObject.survivor_type as SurvivorType;

        if (survivorType === SurvivorType.Core) {
            this.onPasteInternal<CoreSurvivor>(survivorObject);
        } else if (survivorType === SurvivorType.Ark) {
            this.onPasteInternal<ArkSurvivor>(survivorObject);
        } else if (survivorType === SurvivorType.PoTStar) {
            this.onPasteInternal<PoTStarSurvivor>(survivorObject);
        }else if (survivorType === SurvivorType.PoTSun) {
            this.onPasteInternal<PoTSunSurvivor>(survivorObject);
        }
    };

    prepareForUpdateSurvivor<T extends SurvivorProtocol<T>>() : BaseSurvivor | null {
        let { currentSurvivor, survivorSheet } = this.state;

        if (survivorSheet === null || currentSurvivor === null) {
            return null;
        }

        const survivor = this.survivorMapperService.mapFromState<
            T,
            BaseSurvivorSheetState
        >(survivorSheet, currentSurvivor.survivor_type);

        if (survivor === null) {
            return null;
        }

        const currentCasted = currentSurvivor as unknown as T;

        if (survivor.lantern_year === currentSurvivor.lantern_year) {
            survivor.alternative_survivors =
                currentCasted.alternative_survivors;
            currentSurvivor = survivor;
        } else if (currentCasted.alternative_survivors) {
            const foundSurvivorIndex =
                currentCasted.alternative_survivors.findIndex(
                    (x) => x.lantern_year === survivor.lantern_year
                );
            if (foundSurvivorIndex === -1) {
                this.uiService.showErrorNotification(
                    'Unexpected error when saving!'
                );
                this.uiService.hideLoading();
                return null;
            }
            survivor.parent_id = currentSurvivor.id;
            currentCasted.alternative_survivors[foundSurvivorIndex] = survivor;
        }

        return currentSurvivor;
    }

    onUpdate = async () => {
        
        let { currentSurvivor } = this.state;

        if (this.state.survivorSheet === null) return;

        if (currentSurvivor === null) return;

        this.uiService.showLoading();

        if (currentSurvivor.survivor_type === SurvivorType.Core) {
            currentSurvivor = this.prepareForUpdateSurvivor<CoreSurvivor>();
        } else if (currentSurvivor.survivor_type === SurvivorType.Ark) {
            currentSurvivor = this.prepareForUpdateSurvivor<ArkSurvivor>();
        } else if (currentSurvivor.survivor_type === SurvivorType.PoTStar) {
            currentSurvivor = this.prepareForUpdateSurvivor<PoTStarSurvivor>();
        } else if (currentSurvivor.survivor_type === SurvivorType.PoTSun) {
            currentSurvivor = this.prepareForUpdateSurvivor<PoTSunSurvivor>();
        }

        if (currentSurvivor === null) return;

        const response = await this.recordSheetService.updateSurvivor(currentSurvivor);
        if (response !== null) {
            if (response.statusCode === 409) {
                this.onRefresh();
            }
            this.uiService.showErrorNotification(response.value);
            this.uiService.hideLoading();
            return;
        }
        this.uiService.showSuccessNotification('Survivor saved!');
        this.uiService.hideLoading();
        this.onRefresh();
    };

    onLanternYearChangedInternal<T extends SurvivorProtocol<T>>(
        year: number,
        currentDataBase: BaseSurvivor
    ) {
        let isNewSurvivor = false;
        let { currentSurvivor, survivorYearMap } = this.state;

        if (currentSurvivor === null) {
            this.uiService.showErrorNotification(
                'Failed to load survivor data!'
            );
            return;
        }

        const currentData = currentDataBase as T;

        const currentAsCoreSurvivor = currentSurvivor as T;

        if (currentData === null) return;

        if (currentAsCoreSurvivor === null) return;

        if (currentData.lantern_year === currentSurvivor.lantern_year) {
            currentData.alternative_survivors =
                currentAsCoreSurvivor.alternative_survivors;
            currentSurvivor = currentData;
        } else {
            const foundSurvivorIndex =
                currentAsCoreSurvivor.alternative_survivors!.findIndex(
                    (x) => x.lantern_year === currentData.lantern_year
                );
            if (foundSurvivorIndex !== -1) {
                currentAsCoreSurvivor.alternative_survivors![
                    foundSurvivorIndex
                ] = currentData;
            }
        }

        if (currentData.lantern_year) {
            if (survivorYearMap.has(currentData.lantern_year)) {
                survivorYearMap.set(currentData.lantern_year, currentData);
            }
        }

        if (!survivorYearMap.has(year)) {
            const newSurvivor = this.survivorMapperService.mapFromState<
                T,
                BaseSurvivorSheetState
            >(this.state.survivorSheet!, currentSurvivor.survivor_type);
            if (newSurvivor === null) return;
            newSurvivor.id = 0;
            newSurvivor.parent_id = currentSurvivor.id;
            newSurvivor.lantern_year = year;

            const currentAsCoreSurvivor = currentSurvivor as CoreSurvivor;

            if (
                !currentAsCoreSurvivor.alternative_survivors ||
                currentAsCoreSurvivor.alternative_survivors.length <= 0
            ) {
                currentAsCoreSurvivor.alternative_survivors = [];
            }
            currentAsCoreSurvivor.alternative_survivors.push(newSurvivor);
            survivorYearMap.set(year, newSurvivor);
            isNewSurvivor = true;
        }

        const saveEnabled = isNewSurvivor || this.state.saveEnabled;

        this.setState(
            {
                ...this.state,
                currentSurvivor: currentSurvivor,
                survivorYearMap: survivorYearMap,
            },
            () => {
                this.updateSurvivorViewDataFromCore(
                    survivorYearMap.get(year)!,
                    isNewSurvivor || saveEnabled
                );
            }
        );
    }

    onLanternYearChanged = (year: number) => {
        let { currentSurvivor } = this.state;

        if (currentSurvivor === null) {
            this.uiService.showErrorNotification(
                'Failed to load survivor data!'
            );
            return;
        }

        if (currentSurvivor.survivor_type === SurvivorType.Core) {
            const currentData = this.survivorMapperService.mapFromState<
                CoreSurvivor,
                BaseSurvivorSheetState
            >(this.state.survivorSheet!, currentSurvivor.survivor_type);

            if (!currentData) {
                this.uiService.showConfirmationDialog('failed to convert');
            }

            this.onLanternYearChangedInternal<CoreSurvivor>(year, currentData!);
        } else if (currentSurvivor.survivor_type === SurvivorType.Ark) {
            const currentData = this.survivorMapperService.mapFromState<
                ArkSurvivor,
                BaseSurvivorSheetState
            >(this.state.survivorSheet!, currentSurvivor.survivor_type);

            if (!currentData) {
                this.uiService.showConfirmationDialog('failed to convert');
            }

            this.onLanternYearChangedInternal<ArkSurvivor>(year, currentData!);
        } else if (currentSurvivor.survivor_type === SurvivorType.PoTStar) {
            const currentData = this.survivorMapperService.mapFromState<
                PoTStarSurvivor,
                BaseSurvivorSheetState
            >(this.state.survivorSheet!, currentSurvivor.survivor_type);

            if (!currentData) {
                this.uiService.showConfirmationDialog('failed to convert');
            }

            this.onLanternYearChangedInternal<PoTStarSurvivor>(
                year,
                currentData!
            );
        } else if (currentSurvivor.survivor_type === SurvivorType.PoTSun) {
            const currentData = this.survivorMapperService.mapFromState<
                PoTSunSurvivor,
                BaseSurvivorSheetState
            >(this.state.survivorSheet!, currentSurvivor.survivor_type);

            if (!currentData) {
                this.uiService.showConfirmationDialog('failed to convert');
            }

            this.onLanternYearChangedInternal<PoTSunSurvivor>(
                year,
                currentData!
            );
        }
    };

    private updateSurvivorViewDataFromCore(
        response: BaseSurvivor,
        enableSave: boolean = true
    ) {
        //response.survivor_name = this.state.currentSurvivor!.survivor_name
        response.last_updated = this.state.currentSurvivor!.last_updated;

        const mappedSurvivor = this.survivorMapperService.mapFromModel<
            BaseSurvivor,
            BaseSurvivorSheetState
        >(response, response.survivor_type);

        if (mappedSurvivor === null) return;

        this.updateValues(mappedSurvivor, enableSave);
    }

    render() {
    console.log('this.state.survivorSheet',this.state.survivorSheet)
        if (this.state.survivorSheet === null) return null;
        const stateWrapper = new StateValueWrapper(
            this.state.survivorSheet,
            this.updateValues
        );
        return (
            <SurvivorSheetContainerClient
                stateValueWrapper={stateWrapper}
                refresh={this.onRefresh}
                close={this.onClose}
                update={this.onUpdate}
                lanternYearChanged={this.onLanternYearChanged}
                copy={this.onCopy}
                paste={this.onPaste}
                saveEnabled={this.state.saveEnabled}
                neighbours={this.state.neighbours}
                id={this.state.currentSurvivor!.id!}
                openSurvivor={this.props.openSurvivorSheet}
                survivorType={this.state.currentSurvivor!.survivor_type}
            />
        );
    }
}

export default copyConnector(SurvivorDirectoryClient);
