import ArkSurvivor from '../../models/survivors/ArkSurvivor';
import CoreSurvivorSheetState from '../../models/stateFragments/survivor/CoreSurvivorSheetState';
import BaseMapper from '../BaseMapper';

export default class ArkSurvivorSheetMapper extends BaseMapper<
    ArkSurvivor,
    CoreSurvivorSheetState
> {
    public mapToState(survivor: ArkSurvivor): CoreSurvivorSheetState {
        const state: CoreSurvivorSheetState = {
            values: new Map<string, string>(),
            checkmarks: new Map<string, boolean>(),
        };

        this.setText(state.values, 'id', survivor.id!.toString());
        this.setText(state.values, 'parent_id', survivor.parent_id!.toString());
        this.setText(
            state.values,
            'settlement_id',
            survivor.settlement_id.toString()
        );
        this.setText(
            state.values,
            'survivor_type',
            survivor.survivor_type.toString()
        );
        this.setText(
            state.values,
            'lantern_year',
            survivor.lantern_year!.toString()
        );
        this.setText(
            state.values,
            'survivor_name',
            survivor.survivor_name!.toString()
        );
        state.checkmarks?.set('male_check', survivor.survivor_sex === 0);
        this.setTextSubArrayToMap(state.values, 'gear_entry_', survivor.gears!);
        state.values?.set('notes', survivor.notes!);
        state.checkmarks?.set(
            'survivor_dead',
            this.intToBoolean(survivor.dead!)
        );
        this.setText(state.values, 'last_updated', survivor.last_updated);

        this.setText(
            state.values,
            'survival_count',
            survivor.survival_count!.toString()
        );
        state.checkmarks?.set(
            'survival_check',
            this.intToBoolean(survivor.survival_check!)
        );
        state.checkmarks?.set(
            'encourage_check',
            this.intToBoolean(survivor.encourage_check!)
        );
        state.checkmarks?.set(
            'surge_check',
            this.intToBoolean(survivor.surge_check!)
        );
        state.checkmarks?.set(
            'dash_check',
            this.intToBoolean(survivor.dash_check!)
        );
        state.checkmarks?.set(
            'fist_pump_check',
            this.intToBoolean(survivor.fist_pump_check!)
        );

        state.values?.set(
            'pressure',
            survivor.pressure ? survivor.pressure.toString() : '0'
        );

        state.values?.set(
            'movement',
            survivor.movement ? survivor.movement.toString() : '0'
        );

        state.values?.set('accuracy_count', survivor.accuracy_count);

        state.values?.set('strength_count', survivor.strength_count);

        state.values?.set('evasion_count', survivor.evasion_count);

        state.values?.set('luck_count', survivor.luck_count);

        state.values?.set('speed_count', survivor.speed_count);

        state.values?.set('luminosity', survivor.luminosity);

        state.values?.set('insanity_count', survivor.insanity_count.toString());

        state.checkmarks?.set(
            'brain_check',
            this.intToBoolean(survivor.brain_check!)
        );

        state.values?.set('torment', survivor.torment.toString());

        state.values?.set('head_count', survivor.head_count.toString());

        state.values?.set('arms_count', survivor.arms_count.toString());

        state.values?.set('body_count', survivor.body_count.toString());

        state.values?.set('waist_count', survivor.waist_count.toString());

        state.values?.set('legs_count', survivor.legs_count.toString());

        this.setBooleanCountToMap(
            state.checkmarks,
            'head_toggle_',
            survivor.head_toggle,
            1,
            0
        );

        this.setBooleanCountToMap(
            state.checkmarks,
            'arms_toggle_',
            survivor.arms_toggle,
            2,
            0
        );

        this.setBooleanCountToMap(
            state.checkmarks,
            'body_toggle_',
            survivor.body_toggle,
            2,
            0
        );

        this.setBooleanCountToMap(
            state.checkmarks,
            'waist_toggle_',
            survivor.waist_toggle,
            2,
            0
        );

        this.setBooleanCountToMap(
            state.checkmarks,
            'legs_toggle_',
            survivor.legs_toggle,
            2,
            0
        );

        const injuryCounts = [1,1,2,1,2,1,5,2,5,1,5,1,5,1,1,2,1,2];


        survivor.injury.forEach((injury, index)=>{
            this.setBooleanCountToMap(
                state.checkmarks,
                `injury_${index}_`,
                injury,
                injuryCounts[index],
                0
            );
        })

        this.setBooleanCountToMap(
            state.checkmarks,
            'hunt_xp_',
            survivor.hunt_xp!,
            16,
            0
        );

        this.setBitMaskToMap(
            state.checkmarks,
            'hunt_xp_',
            '_bold',
            survivor.hunt_xp_mask!,
            16,
            0
        );

        this.setText(
            state.values,
            'weapon_proficiency',
            survivor.weapon_proficiency
        );

        this.setBooleanCountToMap(
            state.checkmarks,
            'weapon_xp_',
            survivor.weapon_xp!,
            16,
            0
        );

        this.setBooleanCountToMap(
            state.checkmarks,
            'courage_xp_',
            survivor.courage_xp!,
            9,
            0
        );

        this.setBooleanCountToMap(
            state.checkmarks,
            'understanding_xp_',
            survivor.understanding_xp!,
            9,
            0
        );

        this.setText(state.values, 'departure', survivor.departure);
        this.setTextSubArrayToMap(
            state.values,
            'fighting_art_',
            survivor.fighting_art
        );
        this.setTextSubArrayToMap(
            state.values,
            'disorders_',
            survivor.disorders
        );
        this.setTextSubArrayToMap(state.values, 'ability_', survivor.abilities);

        state.checkmarks?.set(
            'hunt_check',
            this.intToBoolean(survivor.hunt_check!)
        );

        this.setText(state.values, 'lifetime_entry', survivor.lifetime_entry);

        state.checkmarks?.set(
            'reroll_check',
            this.intToBoolean(survivor.reroll_check!)
        );

        this.setText(state.values, 'philosophy', survivor.philosophy);
        //this.setText(state.values,'movement', survivor.movement!.toString());
        this.setText(
            state.values,
            'philosophy_count',
            survivor.philosophy_count!.toString()
        );
        this.setText(state.values, 'tenet_neurosis', survivor.tenet_neurosis);
        this.setText(state.values, 'tenet_knowledge', survivor.tenet_knowledge);

        this.setBooleanCountToMap(
            state.checkmarks,
            'neurosis_xp_',
            survivor.neurosis_xp!,
            9,
            0
        );

        this.setBitMaskToMap(
            state.checkmarks,
            'neurosis_xp_',
            '_bold',
            survivor.neurosis_xp_mask!,
            9,
            0
        );

        this.setText(state.values, 'tenet_rules', survivor.tenet_rules);
        this.setText(
            state.values,
            'tenet_observation',
            survivor.tenet_observation
        );

        state.checkmarks?.set(
            'fighting_arts_check',
            this.intToBoolean(survivor.fighting_arts_check!)
        );

        this.setTextSubArrayToMap(
            state.values,
            'knowledge_',
            survivor.knowledge
        );

        survivor.knowledge_xp.forEach((knowledge, index) => [
            this.setBooleanCountToMap(
                state.checkmarks,
                `knowledge_xp_${index}_`,
                knowledge,
                9
            ),
        ]);

        this.setBitMaskToMap(
            state.checkmarks,
            `knowledge_xp_0_`,
            '_bold',
            survivor.knowledge_xp_mask_0!,
            9,
            0
        );

        this.setBitMaskToMap(
            state.checkmarks,
            `knowledge_xp_1_`,
            '_bold',
            survivor.knowledge_xp_mask_1!,
            9,
            0
        );

        this.setTextSubArrayToMap(
            state.values,
            'knowledge_rules_',
            survivor.knowledge_rules
        );
        this.setTextSubArrayToMap(
            state.values,
            'knowledge_observation_',
            survivor.knowledge_observation
        );

        this.setText(state.values, 'saviour_type', survivor.saviour_type.toString());

        return state;
    }

    public mapFromState(from: CoreSurvivorSheetState): ArkSurvivor {

        console.log(from.checkmarks);

        const survivor: ArkSurvivor = {
            id: this.getValueInt(from.values, 'id'),
            parent_id: this.getValueInt(from.values, 'parent_id'),
            settlement_id: this.getValueInt(from.values, 'settlement_id'),
            survivor_type: this.getValueInt(from.values, 'survivor_type'),
            lantern_year: this.getValueInt(from.values, 'lantern_year'),
            survivor_name: this.getValue(from.values, 'survivor_name'),
            survivor_sex: this.getCheckmarkValue(from.checkmarks, 'male_check')
                ? 0
                : 1,
            gears: this.getTextSubArrayPrefix(from.values, 'gear_entry_'),
            notes: this.getValue(from.values, 'notes'),
            dead: this.getCheckmarkValueInt(from.checkmarks, 'survivor_dead'),
            last_updated: this.getValue(from.values, 'last_updated'),

            survival_count: this.getValueInt(from.values, 'survival_count'),
            survival_check: this.getCheckmarkValueInt(
                from.checkmarks,
                'survival_check'
            ),

            encourage_check: this.getCheckmarkValueInt(
                from.checkmarks,
                'encourage_check'
            ),
            surge_check: this.getCheckmarkValueInt(
                from.checkmarks,
                'surge_check'
            ),
            dash_check: this.getCheckmarkValueInt(
                from.checkmarks,
                'dash_check'
            ),
            fist_pump_check: this.getCheckmarkValueInt(
                from.checkmarks,
                'fist_pump_check'
            ),

            pressure: this.getValueInt(from.values, 'pressure'),
            movement: this.getValueInt(from.values, 'movement'),

            accuracy_count: this.getValue(from.values, 'accuracy_count'),
            strength_count: this.getValue(from.values, 'strength_count'),
            evasion_count: this.getValue(from.values, 'evasion_count'),
            luck_count: this.getValue(from.values, 'luck_count'),
            speed_count: this.getValue(from.values, 'speed_count'),
            luminosity: this.getValue(from.values, 'luminosity'),
            insanity_count: this.getValueInt(from.values, 'insanity_count'),
            brain_check: this.getCheckmarkValueInt(
                from.checkmarks,
                'brain_check'
            ),
            torment: this.getValue(from.values, 'torment'),

            head_count: this.getValueInt(from.values, 'head_count'),
            arms_count: this.getValueInt(from.values, 'arms_count'),
            body_count: this.getValueInt(from.values, 'body_count'),
            waist_count: this.getValueInt(from.values, 'waist_count'),
            legs_count: this.getValueInt(from.values, 'legs_count'),

            head_toggle: this.getBoolCount(
                from.checkmarks,
                'head_toggle_',
                true
            ),
            arms_toggle: this.getBoolCount(
                from.checkmarks,
                'arms_toggle_',
                true
            ),
            body_toggle: this.getBoolCount(
                from.checkmarks,
                'body_toggle_',
                true
            ),
            waist_toggle: this.getBoolCount(
                from.checkmarks,
                'waist_toggle_',
                true
            ),
            legs_toggle: this.getBoolCount(
                from.checkmarks,
                'legs_toggle_',
                true
            ),

            injury: this.getBoolCountArrayWithCountMap(from.checkmarks, 'injury_', 18, [1,1,2,1,2,1,5,2,5,1,5,1,5,1,1,2,1,2]),

            hunt_xp: this.getBoolCount(from.checkmarks, 'hunt_xp_', true),
            hunt_xp_mask: this.getBitMaskFromMap(
                from.checkmarks,
                'hunt_xp_',
                '_bold',
                16,
                0
            ),

            weapon_proficiency: this.getValue(
                from.values,
                'weapon_proficiency'
            ),

            weapon_xp: this.getBoolCount(from.checkmarks, 'weapon_xp_', true),
            courage_xp: this.getBoolCount(from.checkmarks, 'courage_xp_', true),
            understanding_xp: this.getBoolCount(
                from.checkmarks,
                'understanding_xp_',
                true
            ),

            departure: this.getValue(from.values, 'departure'),

            fighting_art: this.getTextSubArrayPrefix(
                from.values,
                'fighting_art_'
            ),
            disorders: this.getTextSubArrayPrefix(from.values, 'disorders_'),
            abilities: this.getTextSubArrayPrefix(from.values, 'ability_'),

            hunt_check: this.getCheckmarkValueInt(
                from.checkmarks,
                'hunt_check'
            ),

            lifetime_entry: this.getValue(from.values, 'lifetime_entry'),

            reroll_check: this.getCheckmarkValueInt(
                from.checkmarks,
                'reroll_check'
            ),

            philosophy: this.getValue(from.values, 'philosophy'),
            philosophy_count: this.getValueInt(from.values, 'philosophy_count'),
            tenet_neurosis: this.getValue(from.values, 'tenet_neurosis'),
            tenet_knowledge: this.getValue(from.values, 'tenet_knowledge'),

            neurosis_xp: this.getBoolCount(
                from.checkmarks,
                'neurosis_xp_',
                true
            ),
            neurosis_xp_mask: this.getBitMaskFromMap(
                from.checkmarks,
                'neurosis_xp_',
                '_bold',
                9,
                0
            ),

            tenet_rules: this.getValue(from.values, 'tenet_rules'),
            tenet_observation: this.getValue(from.values, 'tenet_observation'),

            fighting_arts_check: this.getCheckmarkValueInt(
                from.checkmarks,
                'fighting_arts_check'
            ),

            knowledge: this.getTextSubArrayPrefix(from.values, 'knowledge_'),
            knowledge_xp: this.getBoolCountArray(
                from.checkmarks,
                'knowledge_xp_',
                2,
                9,
                true
            ),
            knowledge_xp_mask_0: this.getBitMaskFromMap(
                from.checkmarks,
                'knowledge_xp_0_',
                '_bold',
                9
            ),
            knowledge_xp_mask_1: this.getBitMaskFromMap(
                from.checkmarks,
                'knowledge_xp_1_',
                '_bold',
                9
            ),

            knowledge_rules: this.getTextSubArrayPrefix(
                from.values,
                'knowledge_rules_'
            ),
            knowledge_observation: this.getTextSubArrayPrefix(
                from.values,
                'knowledge_observation_'
            ),

            saviour_type : this.getValueInt(from.values, 'saviour_type'),

            alternative_survivors: [],
        };

        return survivor;
    }
}
