import React, { useContext } from 'react';
import renderFor from '../../../utils/renderFor';
import { StateValueWrapperContext } from '../../../modules/record-sheets-module/contexts/StateValueWrapperContext';

interface KDHTMLProgressProps {
    count: number;
    item: (index: number, change : (index:number, event: React.ChangeEvent<HTMLInputElement>)=>void, id : string) => React.ReactNode;
    prefix : string;
    start?:number;
}

export default function KDHTMLProgress({ count, item, prefix, start }: KDHTMLProgressProps) {
    
    if(!start)
        start = 0;

    const context = useContext(StateValueWrapperContext);

    const onChange = (index:number, event: React.ChangeEvent<HTMLInputElement>)=>{
        
        if(context===null){
            return;
        }
        const {checked} = event.target;
        context.checkboxProgressChange(prefix, index, count, checked);
    }

    return <React.Fragment>{renderFor(start!, start!+count, (i)=>item(i,onChange, `${prefix}${i}`))}</React.Fragment>;
}
