import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface ConfirmDialogState {
    open : boolean,
    prompt:string,
    positiveButton : string,
    negativeButton : string,
    response : boolean | null
}

const initialState: ConfirmDialogState = {
    open : false,
    prompt : '',
    positiveButton : 'Yes',
    negativeButton : 'No',
    response : null
}

export interface ConfirmDialogPayload{
    prompt:string,
    positiveButton? : string,
    negativeButton? : string
}

export const confirmSlice = createSlice({
    name: 'confirmDialog',
    initialState,
    reducers: {
        showConfirm: (state, action: PayloadAction<ConfirmDialogPayload>) => {
            let {prompt, positiveButton, negativeButton} = action.payload
            if(!positiveButton){
                positiveButton = 'Yes';
            }
            if(!negativeButton){
                negativeButton = 'No';
            }

            state.open = true;
            state.positiveButton = positiveButton;
            state.negativeButton = negativeButton;
            state.prompt = prompt;
            state.response = null;
        },
        setResponse : (state, action : PayloadAction<boolean>)=>{
            state.response = action.payload;
        },
        hideConfirm : (state, action : PayloadAction)=>{
            state.open = false;
        }
    },
})

export const { showConfirm, setResponse, hideConfirm } = confirmSlice.actions

export const selectLoading = (state: RootState) => state.loading.loading

export default confirmSlice.reducer
