import { useInjection } from 'inversify-react';
import LoginPage from '../../pages/login-page/LoginPage';
import { IAuthService } from '../../services/IAuthService';

function ProtectedPage({
    children,
    redirectTo,
    accessLevel,
    access,
    requireLogin,
    isLoggedIn,
}: {
    children: any;
    redirectTo?: string;
    accessLevel?: number;
    access?: string;
    requireLogin?: boolean;
    isLoggedIn?: boolean;
}): any {
    const authService = useInjection<IAuthService>('IAuthService');
    
    if (access && accessLevel)
        return authService.getAccessLevel(access) >= accessLevel ? (
            children
        ) : (
            <LoginPage />
        );

    if (requireLogin && isLoggedIn) return children;

    return  <LoginPage />
}

export default ProtectedPage;
