import { resolve } from 'inversify-react';
import React from 'react';
import type { IAuthService } from '../../../../services/IAuthService';
import type IRecordSheetService from '../../services/IRecordSheetService';
import CreateSettlement from '../create-settlement/create-settlement.component';
import SettlementSheetContainerDefault from '../settlement-sheet/settlement-sheet-container-default.component';
import SettlementSheetContainerClient from '../settlement-sheet/client-side/settlement-sheet-container-client.component';
import './settlement-directory.styles.scss';
import SurvivorDirectoryDefault from '../settlement-sheet/survivor/survivor-directory/survivor-directory-default.component';
import SettlementList from '../../models/settlements/SettlementList';
import CoreSettlementSheetState from '../../models/stateFragments/sheets/CoreSettlementSheetState';
import { SheetSide } from '../../../../models/SheetSide';
import * as ISettlementModelMapperService from '../../services/ISettlementModelMapperService';
import type IUIService from '../../../../services/IUIService';
import { SettlementType } from '../../models/settlements/SettlementType';
import BaseSettlementSheetState from '../../models/stateFragments/sheets/BaseSettlementSheetState';
import { CoreSettlementSheetModel } from '../../models/settlements/CoreSettlementSheetModel';
import BaseSettlementSheetModel from '../../models/settlements/BaseSettlementSheetModel';
import { ArkSettlementSheetModel } from '../../models/settlements/ArkSettlementSheetModel';
import SettlementPeopleOfStar from '../../models/settlements/PoTStarSettlementSheetModel';
import PoTStarSettlementSheetState from '../../models/stateFragments/sheets/PoTStarSettlementSheetState';
import SettlementPeopleOfSun from '../../models/settlements/PoTSunSettlementSheetModel';
import PoTSunSettlementSheetState from '../../models/stateFragments/sheets/PoTSunSettlementSheetState';
import ListViewComponent from '../../../../components/general/list-view/list-view.component';
import DefaultListOptionView from '../../../../components/general/list-view-default-option/list-view-default-option.component';
import SurvivorDirectoryClientComponent from '../settlement-sheet/client-side/survivor-directory/survivor-directory-client.component';
import type IRefreshHandlerService from '../../services/IRefreshHandlerService';
import styles from './settlement-directory.module.scss'
import ClientOnly from '../../utils/clientOnly';

interface SettlementDirectoryListState {
    settlementList: SettlementList | null;
    page: number;
    selectedSettlement: number;
}

interface SurvivorSheetStateFragment {
    id: number;
}

interface SettlementDirectoryState {
    currentSettlementValues: CoreSettlementSheetState | null;
    settlementList: SettlementDirectoryListState;
    settlementSheetSide: SheetSide;
    saveEnabled: boolean;
    survivorSheet: SurvivorSheetStateFragment | null;
}

interface SettlementDirectoryProps {
    layout?: 'hod' | 'client';
}

export default class SettlementDirectory extends React.Component<
    SettlementDirectoryProps,
    SettlementDirectoryState
> {
    @resolve('IAuthService')
    private readonly authService!: IAuthService;

    @resolve('IRecordSheetService')
    private readonly dodService!: IRecordSheetService;

    @resolve('ISettlementModelMapperService')
    private readonly modelMapperService!: ISettlementModelMapperService.ISettlementModelMapperService;

    @resolve('IUIService')
    private readonly uiService!: IUIService;

    @resolve('IRefreshHandlerService')
    private readonly refreshHandlerService!: IRefreshHandlerService;

    constructor(props: SettlementDirectoryProps) {
        super(props);

        this.state = {
            currentSettlementValues: null,
            settlementList: {
                page: 0,
                selectedSettlement: -1,
                settlementList: null,
            },
            settlementSheetSide: SheetSide.First,
            saveEnabled: false,
            survivorSheet: null,
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        this.getListData(null, false);
    }

    getListData(preselected: number | null, newEntry: boolean) {
        let { page } = this.state.settlementList;
        if (preselected === null || newEntry) {
            page = 1;
        }
        this.uiService.showLoading();
        this.dodService
            .getSettlementList(page)
            .then((result) => {
                if (result === null) return;
                this.setState(
                    {
                        ...this.state,
                        settlementList: {
                            page: page,
                            settlementList: result,
                            selectedSettlement:
                                preselected == null
                                    ? result.settlements[0].id
                                    : preselected,
                        },
                    },
                    () =>
                        this.onSettlementChanged(
                            this.state.settlementList.selectedSettlement
                        )
                );
            })
            .catch()
            .finally(() => {
                this.uiService.hideLoading();
            });
    }

    onCreateNewSettlement = async (name: string, type: SettlementType) => {
        const newId = await this.dodService.createNewSettlement(name, type);
        if (newId === null) {
            this.uiService.showErrorNotification('Record creation failed!');
            return;
        } else {
            this.uiService.showSuccessNotification(
                'Record created successfuly'
            );
        }
        this.getListData(newId, true);
    };

    onDeleteSheet = async () => {
        const { selectedSettlement } = this.state.settlementList;

        if (selectedSettlement === null) return;

        const promptResponse = await this.uiService.showConfirmationDialog(
            `Delete settlement?`,
            'No',
            'Yes'
        );

        if (!promptResponse) return;

        const result = await this.dodService.deleteSettlement(
            selectedSettlement
        );

        if (!result) {
            this.uiService.showErrorNotification('Deleting record failed!');
            return;
        } else {
            this.uiService.showSuccessNotification(
                'Deleted record successfuly'
            );
        }
        this.getListData(null, false);
    };

    onUpdateValues = (values: BaseSettlementSheetState) => {
        this.setState({
            ...this.state,
            currentSettlementValues: values,
            saveEnabled: true,
        });
    };

    onSaveValues = async (values: BaseSettlementSheetState) => {
        let settlementType = values.values?.get('settlement_type');

        if (!settlementType) {
            settlementType = '0';
        }

        const settlementTypeInt = parseInt(settlementType);

        const coreState = this.modelMapperService.mapFromState<
            CoreSettlementSheetModel,
            CoreSettlementSheetState
        >(values, settlementTypeInt);

        console.log(coreState);

        if (coreState === null)
            this.uiService.showErrorNotification('Updating record failed!');

        if (coreState != null) {
            const result = await this.dodService.updateSettlementSheet(
                coreState
            );

            if (result === null) {
                this.uiService.showSuccessNotification(
                    'Successfully updated record!'
                );
                this.onRefresh();
            } else {
                this.uiService.showErrorNotification(result.value);

                if (result.statusCode && result.statusCode === 409) {
                    this.onRefresh();
                }
            }
        }
    };

    onListPageChanged = (page: number) => {
        this.setState(
            {
                ...this.state,
                settlementList: {
                    ...this.state.settlementList,
                    page: page,
                },
            },
            () =>
                this.getListData(
                    this.state.settlementList.selectedSettlement,
                    false
                )
        );
    };

    onSettlementFlip = (sheet: SheetSide) => {
        this.setState({
            ...this.state,
            settlementSheetSide: sheet,
        });
    };

    onSettlementChanged = async (id: number, forceFlip: boolean = true) => {
        this.uiService.showLoading();
        this.setState(
            {
                ...this.state,
                settlementList: {
                    ...this.state.settlementList,
                    selectedSettlement: id,
                },
                survivorSheet: null,
            },
            async () => {
                const value = await this.dodService.getSettlementSheet(id);
                this.uiService.hideLoading();
                if (value === null || value === undefined) {
                    this.getListData(null, false);
                    return;
                }

                if (!value.id) {
                    this.getListData(null, false);
                    return;
                }

                let coreState: CoreSettlementSheetState | null =
                    this.getSettlementMapped(value);
                if (coreState !== null) {
                    this.setState(
                        {
                            ...this.state,
                            currentSettlementValues: coreState,
                            settlementList: {
                                ...this.state.settlementList,
                                selectedSettlement: id,
                            },
                            settlementSheetSide: forceFlip
                                ? (!!ClientOnly().clientOnly ? SheetSide.First : (value.settlement_type===SettlementType.Ark? SheetSide.Third : SheetSide.Second))
                                : this.state.settlementSheetSide,
                            saveEnabled: false,
                        },
                        () => {
                            this.refreshHandlerService.emitRefresh();
                        }
                    );
                }
            }
        );
    };

    onRefresh = (forceFlip: boolean = true) => {
        const { settlementList } = this.state;
        if (settlementList === null) return;
        const currentID = settlementList.selectedSettlement;
        this.onSettlementChanged(currentID, forceFlip);
    };

    onOpenSurvivor = (id: number) => {
        this.setState({
            ...this.state,
            survivorSheet: {
                id: id,
            },
        });
    };

    onCloseSurvivor = () => {
        this.setState({
            ...this.state,
            survivorSheet: null,
        });
    };

    private getSettlementMapped(value: BaseSettlementSheetModel) {
        let coreState: CoreSettlementSheetState | null = null;

        if (value.settlement_type === SettlementType.Core) {
            coreState = this.modelMapperService.mapFromModel<
                CoreSettlementSheetModel,
                CoreSettlementSheetState
            >(value, value.settlement_type);
        }

        if (value.settlement_type === SettlementType.Ark) {
            coreState = this.modelMapperService.mapFromModel<
                ArkSettlementSheetModel,
                CoreSettlementSheetState
            >(value, value.settlement_type);
        }

        if (value.settlement_type === SettlementType.POTStar) {
            coreState = this.modelMapperService.mapFromModel<
                SettlementPeopleOfStar,
                PoTStarSettlementSheetState
            >(value, value.settlement_type);
        }

        if (value.settlement_type === SettlementType.POTSun) {
            coreState = this.modelMapperService.mapFromModel<
                SettlementPeopleOfSun,
                PoTSunSettlementSheetState
            >(value, value.settlement_type);
        }

        return coreState;
    }

    render(): React.ReactNode {
        const { layout } = this.props;
        return layout !== 'client'
            ? this.renderDefaultSettlementDirectory()
            : this.renderClientSettlementDirectory();
    }

    renderDefaultSettlementDirectory() {
        const renderSurvivorFormDefault = () => {
            if (this.state.survivorSheet === null) return;
            if (this.state.survivorSheet.id === -1) return;

            const getFromYear = () => {
                const number = parseInt(
                    this.state.currentSettlementValues!.values!.get(
                        'from_year'
                    )!
                );

                if (number && !Number.isNaN(number)) return number;

                return 1;
            };

            const getToYear = () => {
                return parseInt(
                    this.state.currentSettlementValues!.values!.get('to_year')!
                );
            };

            return (
                <SurvivorDirectoryDefault
                    id={this.state.survivorSheet.id}
                    close={this.onCloseSurvivor}
                    fromYear={getFromYear()}
                    toYear={getToYear()}
                    openSurvivorSheet={this.onOpenSurvivor}
                    settlementId={this.state.settlementList.selectedSettlement}
                />
            );
        };

        const renderList = () => {
            if (this.state.settlementList.settlementList === null) return;

            const settlementList = new SettlementList();
            settlementList.max_results =
                this.state.settlementList.settlementList.max_results;
            settlementList.settlements =
                this.state.settlementList.settlementList.settlements;

            return (
                <ListViewComponent
                    selectionChanged={this.onSettlementChanged}
                    pageChanged={this.onListPageChanged}
                    selectedValue={this.state.settlementList.selectedSettlement}
                    elements={settlementList}
                    page={this.state.settlementList.page}
                    listElement={(e) => {
                        return (
                            <DefaultListOptionView
                                value={e.settlement_name}
                                id={e.id}
                                key={e.id}
                            />
                        );
                    }}
                />
            );
        };

        const renderCreate = () => {
            if (this.authService.getAccessLevel('data') < 1) return null;

            return (
                <CreateSettlement
                    onCreateSettlement={this.onCreateNewSettlement}
                />
            );
        };

        const renderSettlementFormDefault = () => {
            console.log(
                'currentSettlementValues',
                this.state.currentSettlementValues
            );
            console.log('survivorSheet', this.state.survivorSheet);
            if (this.state.currentSettlementValues === null) return;
            if (this.state.survivorSheet !== null) return;
            return (
                <SettlementSheetContainerDefault
                    currentValues={this.state.currentSettlementValues}
                    delete={this.onDeleteSheet}
                    updateValues={this.onUpdateValues}
                    save={this.onSaveValues}
                    flipSheetSide={this.onSettlementFlip}
                    sheetSide={this.state.settlementSheetSide}
                    saveEnabled={this.state.saveEnabled}
                    refresh={this.onRefresh}
                    openSurvivor={this.onOpenSurvivor}
                />
            );
        };

        return (
            <div className="container settlement-directory-container">
                <div className={styles.mainLayout}>
                    <div>
                        <h3 style={{ textAlign: 'center' }}>Settlements</h3>
                        {renderCreate()}
                        {renderList()}
                    </div>
                    <div>
                        {renderSettlementFormDefault()}
                        {renderSurvivorFormDefault()}
                    </div>
                </div>
            </div>
        );
    }

    renderClientSettlementDirectory() {
        const renderSurvivorFormClient = () => {
            if (this.state.survivorSheet === null) return;
            if (this.state.survivorSheet.id === -1) return;

            const getFromYear = () => {
                const number = parseInt(
                    this.state.currentSettlementValues!.values!.get(
                        'from_year'
                    )!
                );

                if (number && !Number.isNaN(number)) return number;

                return 1;
            };

            const getToYear = () => {
                return parseInt(
                    this.state.currentSettlementValues!.values!.get('to_year')!
                );
            };

            return (
                <SurvivorDirectoryClientComponent
                    id={this.state.survivorSheet.id}
                    close={this.onCloseSurvivor}
                    fromYear={getFromYear()}
                    toYear={getToYear()}
                    openSurvivorSheet={this.onOpenSurvivor}
                    settlementId={this.state.settlementList.selectedSettlement}
                />
            );
        };

        const renderSettlementFormClient = () => {
            console.log(
                'currentSettlementValues',
                this.state.currentSettlementValues
            );
            console.log('survivorSheet', this.state.survivorSheet);
            if (this.state.currentSettlementValues === null) return;
            if (this.state.survivorSheet !== null) return;

            const settlementList = new SettlementList();
            if (this.state.settlementList.settlementList) {
                settlementList.max_results =
                    this.state.settlementList.settlementList.max_results;
                settlementList.settlements =
                    this.state.settlementList.settlementList.settlements;
            }

            const list = {
                onSettlementSelectionChanged: this.onSettlementChanged,
                selectedSettlement:
                    this.state.settlementList.selectedSettlement,
                settlementList: settlementList,
            };

            return (
                <SettlementSheetContainerClient
                    currentValues={this.state.currentSettlementValues}
                    delete={this.onDeleteSheet}
                    updateValues={this.onUpdateValues}
                    save={this.onSaveValues}
                    flipSheetSide={this.onSettlementFlip}
                    sheetSide={this.state.settlementSheetSide}
                    saveEnabled={this.state.saveEnabled}
                    refresh={this.onRefresh}
                    openSurvivor={this.onOpenSurvivor}
                    list={list}
                />
            );
        };

        return (
            <div className="settlement-directory-container">
                <div>
                    {renderSettlementFormClient()}
                    {renderSurvivorFormClient()}
                </div>
            </div>
        );
    }
}
