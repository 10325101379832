import ShopifyButton from '../../../../general/elements/ShopifyButton/ShopifyButton';
import styles from './shopify-linking.module.scss';

interface ShopifyLinkInfoProps {
    linkEmail?: string | null;
    unlink: () => void;
    link: () => void;
}

export default function ShopifyLinking({
    linkEmail,
    unlink,
}: ShopifyLinkInfoProps) {
    const linkShopify = () => {
        authorizationShopify().then(() => {
            console.log('trying to link');
        });
    };

    const linkingButton = () => {
        return (
            <>
                <ShopifyButton onClick={(e) => linkShopify()}>
                    Link KD Shop Account
                </ShopifyButton>
            </>
        );
    };

    const shopifyLinkingLayout = () => {
        if (!!linkEmail) {
            return (
                <div className={styles.unlinkRow}>
                    <p>{linkEmail}</p>
                    <button
                        className={styles.linkButton}
                        onClick={(e) => {
                            e.preventDefault();
                            unlink();
                        }}
                    >
                        Remove Account
                    </button>
                </div>
            );
        }

        return linkingButton();
    };

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <p className={styles.linkAccountTitle}>Link your <span style={{fontWeight:'bold'}}>Account of Death </span>and <span style={{fontWeight:'bold'}}>KD Shop Account</span> to ensure all your purchases are delivered accurately<br></br> and use the Dark Treasury (if you have been granted access)</p>
                {shopifyLinkingLayout()}
            </div>
        </div>
    );
}

async function authorizationShopify() {
    const shopId = process.env.REACT_APP_SHOPIFY_SHOP_ID;
    const clientId = process.env.REACT_APP_SHOPIFY_CLIENT_ID;
    const redirectURL = process.env.REACT_APP_SHOPIFY_REDIRECT_URL;

    if (!shopId) return;

    if (!clientId) return;

    if (!redirectURL) return;

    const authorizationRequestUrl = new URL(
        `https://shopify.com/${shopId}/auth/oauth/authorize`
    );

    authorizationRequestUrl.searchParams.append(
        'scope',
        'openid email https://api.customers.com/auth/customer.graphql'
    );

    authorizationRequestUrl.searchParams.append('client_id', clientId);

    authorizationRequestUrl.searchParams.append('response_type', 'code');

    authorizationRequestUrl.searchParams.append('redirect_uri', redirectURL);

    const state = await generateState();
    const nonce = await generateNonce(12);

    authorizationRequestUrl.searchParams.append('state', state);
    authorizationRequestUrl.searchParams.append('nonce', nonce);

    window.open(authorizationRequestUrl.toString(), '_blank');
}

export async function generateState(): Promise<string> {
    const timestamp = Date.now().toString();
    const randomString = Math.random().toString(36).substring(2);
    return timestamp + randomString;
}

export async function generateNonce(length: number) {
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let nonce = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        nonce += characters.charAt(randomIndex);
    }

    return nonce;
}
