import { useEffect, useState } from 'react';
import ShopifyButton from '../../../components/general/elements/ShopifyButton/ShopifyButton';
import styles from './bottom-bar.module.scss';
import { useInjection } from 'inversify-react';
import ISelectedPledgeItemsService from '../../../services/ISelectedPledgeItemsService';
import IShopifyAddToCartService from '../../../services/IShopifyAddToCartService';
import { IAuthService } from 'services/IAuthService';
import IAuthManagementRESTClient from 'rest-clients/IAuthManagementRESTClient';

export interface BottomBarProps{

}

export default function BottomBar() {

    const [totalPledges, setTotalPledges] =  useState(0)
    const [isAccountLinked, setIsAccountLinked] = useState(false)
    const selectedItemPledgeRepositoryService = useInjection<ISelectedPledgeItemsService>('ISelectedPledgeItemsService')
    const shopifyAddToCartService = useInjection<IShopifyAddToCartService>('IShopifyAddToCartService')
    const authManagementRESTClient = useInjection<IAuthManagementRESTClient>('IAuthManagementRESTClient')

    useEffect(()=>{
        selectedItemPledgeRepositoryService.SelectedItemsChanged.on(()=>{
            setTotalPledges(selectedItemPledgeRepositoryService.getTotalSelectedPledges())
        })
        setTotalPledges(selectedItemPledgeRepositoryService.getTotalSelectedPledges());
    },[])

    useEffect(()=>{
        authManagementRESTClient.getLinkedAccounts().then(x=>{
            if(!!x?.shopify_email){
                if(x.shopify_email!==null && x.shopify_email.length>0){
                    setIsAccountLinked(true);
                }
            }
        })
    },[])

    useEffect(()=>{

    }, [totalPledges, isAccountLinked])

    const isDisabled = (totalPledges===0 || isAccountLinked===false);

    return (
        <div className={styles.bottomBar}>
            <div className={styles.container}>
                <ShopifyButton onClick={e=>{
                    e.preventDefault();
                    if(isDisabled){
                        if(!isAccountLinked){
                             window.location.hash = 'account';
                        }
                    }
                    else{
                        shopifyAddToCartService.proceed();
                    }
                }} fakeDisable={isDisabled}>{!!isAccountLinked?'Add To Cart':'Link Shopify Email'}</ShopifyButton>
            </div>
        </div>
    );
}
