import { inject, injectable } from 'inversify'
import { IAuthService } from '../IAuthService'
import type { ISessionStorageService } from '../ISessionStorageService'
import type { IAuthRESTClient } from '../../rest-clients/IAuthRESTClient'
import  JWTAccessToken from '../../models/JWTAccessToken'
import store from '../../redux/store'
import { setToken, setDataAccess } from '../../redux/slices/authSlice'
import jwtDecode from 'jwt-decode'
import type { IAuthGateRESTClient } from '../../rest-clients/IAuthGateRESTClient'
import ActivateResponse from '../../models/ActivateResponse'
import { LoginState } from '../../models/LoginState'
import type ICookieService from '../ICookieService'
import { ErrorMessage } from '../../models/ErrorMessage'


@injectable()
export class AuthService implements IAuthService {
   
    @inject('ISessionStorageService')
    private readonly storageService!: ISessionStorageService

    @inject('IAuthRESTClient')
    private readonly authRestClient!: IAuthRESTClient

    @inject('IAuthGateRESTClient')
    private readonly authGateRestClient!: IAuthGateRESTClient

    @inject('ICookieService')
    private readonly cookieService!: ICookieService

    setToken(jwt: JWTAccessToken | null): void {
        if(jwt!=null)
            this.setTokenInner(jwt.jwt, jwt.refreshToken, jwt.expires)
        else{
            store.dispatch(setToken(null));
        }
    }

    getTokenLocal() : JWTAccessToken{
        return {
            jwt :   this.storageService.get('jwt')!,
            expires : parseInt(this.storageService.get('expiration')!)!,
            refreshToken : this.storageService.get('refresh')!
        };
    }

    initFromCookies():boolean{
        const jwt = this.cookieService.get('jwt');
        const refresh = this.cookieService.get('refresh');
        const expirationString = this.cookieService.get('expiration');

        if(!!jwt && !!refresh && !!expirationString){
            this.setTokenInner(jwt, refresh, parseInt(expirationString));
            return true;
        }

        return false;
    }

    private setTokenInner(
        jwt: string,
        refresh: string,
        expiration: number
    ): void {
        this.storageService.set('jwt', jwt)
        this.storageService.set('refresh', refresh)
        this.storageService.set('expiration', expiration.toString())
        const domainAttribute = this.cookieService.getDomainAttribute({}, "kingdomdeath.com");
        const cookieFinalAttribute = this.cookieService.getExpirationAttribute(domainAttribute);
        this.cookieService.set('jwt', jwt, cookieFinalAttribute);
        this.cookieService.set('refresh', refresh, cookieFinalAttribute);
        this.cookieService.set('expiration', expiration.toString(), cookieFinalAttribute);

        this.authRestClient.getTokenAccessLevels(
            jwt
        ).then(accessLevels=>{
            if (accessLevels === null) return LoginState.Fail

            const accessLevelNumerical:number[] =[]
            
            accessLevels.forEach((value, key, map) => {
                this.setAccessLevel(key, value)
                accessLevelNumerical.push(parseInt(value));
            })

            store.dispatch(setDataAccess(accessLevelNumerical));
        })

       

        store.dispatch(setToken(jwt))
    }

    private setAccessLevel(access: string, level: string) {
        this.storageService.set(`${access}:access`, level)
    }

    getAccessLevel(access: string): number {
        const accessLevel = this.storageService.get(`${access}:access`)

        if (accessLevel === null) return -1

        return parseInt(accessLevel)
    }

    private getExpiration(): number {
        const expirationString = this.storageService.get('expiration')

        if (expirationString === null) return -1

        return parseInt(expirationString)
    }

    getToken = async (): Promise<string | null> => {
        if (this.getExpiration() < Date.now()) {
            console.log(this.getExpiration() + ' ' + Date.now())
            const refreshToken = this.storageService.get('refresh')
            if (refreshToken === null) return null
            const authToken = await this.authRestClient.refresh(refreshToken)

            if (authToken === null) return null

            this.setToken(authToken)
        }
        return this.storageService.get('jwt');
    }

    isAuthenticated(): boolean {
        return this.storageService.has('jwt')
    }

    getLocalISS(): string | null {
        if(!this.isAuthenticated()){
            return null;
        }

        const token =this.storageService.get('jwt');
        if(token===null)
            return null;
        const iss = (jwtDecode(token) as any).iss;
        if(iss){
            return iss;
        }

        return null;
    }

    login = async (email: string, password: string): Promise<LoginState> => {
        const result = await this.authRestClient.login(email, password)
        
        
        
        if (result.status!==LoginState.Success) return result.status;

        if(result.token===null) return LoginState.Fail;

        const accessLevels = await this.authRestClient.getTokenAccessLevels(
            result.token.jwt
        )

        if (accessLevels === null) return LoginState.Fail

        const accessLevelNumerical:number[] =[]
        
        accessLevels.forEach((value, key, map) => {
            this.setAccessLevel(key, value)
            accessLevelNumerical.push(parseInt(value));
        })

        store.dispatch(setDataAccess(accessLevelNumerical));
        
        
        this.setToken(result.token)
        
        return LoginState.Success
    }

    loginJWT = async (email: string, password: string): Promise<JWTAccessToken | null> => {
        const result = await this.authRestClient.login(email, password)
        if (result === null) return null
        if(result.status !== LoginState.Success) return null;

        return result.token;
    }

    async getISS(): Promise<string | null> {
        const token = await this.getToken();
        if(token===null)
            return null;
        const iss = (jwtDecode(token) as any).iss;
        if(iss){
            return iss;
        }

        return null;
    }

    async resendEmail(email:string ,extra:string|null) : Promise<boolean>{
        return await this.authGateRestClient.resendEmail(email ,extra);
    }

    async requestChangeEmail(email:string) : Promise<ErrorMessage> {
        const token = await this.getToken();
        const iss = await this.getISS();

        return await this.authGateRestClient.changeEmail(token, iss, email)
    }

    activateUser= async (key:string) : Promise<ActivateResponse> =>{
        const result = await this.authGateRestClient.activateUser(key);
        return result;
    } 

    logout() {
        this.storageService.reset();
        this.cookieService.reset();
        this.setToken(null);
    }
}
