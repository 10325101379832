import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { injectable } from 'inversify'
import { IRESTClient } from '../IRESTClient'

export interface AccessTokenDateTime {
    token: string
    expires: number
}

@injectable()
export abstract class RESTClient implements IRESTClient {
    protected baseUrl: string
    private accessTokenDateTime: AccessTokenDateTime


    protected tokenURL = 'auth/token'

    constructor() {
        this.baseUrl = ''
        this.accessTokenDateTime = {
            token: '',
            expires: 0,
        }
    }

    protected setBaseURL(url: string) {
        this.baseUrl = url
    }

    protected getFullURL(
        url: string,
        params: Map<string, string> = new Map<string, string>()
    ): string {
        let targetUrl = this.baseUrl + url
        let currentParam = 0
        params.forEach((value: string, key: string) => {
            if (currentParam === 0) {
                targetUrl += '?'
            } else targetUrl += '&'

            targetUrl += key
            targetUrl += '='
            targetUrl += value

            currentParam++;
        })
        return targetUrl
    }

    private isResolvingToken: boolean = false

    public async getToken(jwt: string): Promise<string> {
        while (this.isResolvingToken) {
            await this.delay(100)
        }

        if (
            this.accessTokenDateTime.token !== '' &&
            this.accessTokenDateTime.expires > Date.now()
        ) {
            return this.accessTokenDateTime.token
        }

        const path = this.getFullURL(this.tokenURL)
        this.isResolvingToken = true

        const formData = new FormData()
        formData.append('assertion', jwt)

        try {
            const response = await axios.post(path, formData)
            const token = response.data.access_token
            const expires = response.data.expires

            this.accessTokenDateTime.token = token
            this.accessTokenDateTime.expires = Date.now() + (expires - 300)*1000

            this.isResolvingToken = false

            return token
        } catch {
            this.accessTokenDateTime.token = ''
            return ''
        }
    }

    protected async clearAccessToken() {
        while (this.isResolvingToken) {
            await this.delay(100)
        }
        this.accessTokenDateTime.token = ''
    }

    protected getHeader(token: string) {
        const config: AxiosRequestConfig = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
        return config
    }

    protected getHeaderAndBody(token: string, formData : FormData) {
        const config: AxiosRequestConfig = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data : formData
        }
        return config
    }

    protected getHeaderJson(currentConfig : AxiosRequestConfig | null = null) {
        let config : AxiosRequestConfig;
        if(currentConfig==null){
            config = {
                headers: {
                    'Content-Type': 'application/json'
                },
            }
        }
        else{
            config = {
                ...currentConfig,
                headers : {
                    ...currentConfig.headers,
                    'Content-Type': 'application/json'
                }
            }
        }
        return config
    }

    delay(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms))
    }

    isSuccess(response: AxiosResponse): boolean {
        return response.status >= 200 && response.status < 300
    }
}
