import { injectable } from 'inversify'
import { ILocalStorageService } from '../ILocalStorageService'

@injectable()
export class LocalStorageService implements ILocalStorageService {
    private keys!: string[]

    constructor() {
        this.keys = []
    }

    get(key: string): string | null {
        return window.localStorage.getItem(key)
    }
    set(key: string, value: string): void {
        this.keys.push(key)
        window.localStorage.setItem(key, value)
    }
    has(key: string): boolean {
        return window.localStorage.getItem(key) != null
    }

    remove(key:string) : void{
        window.localStorage.removeItem(key);
    }

    reset() {
        window.localStorage.clear()
    }
}
