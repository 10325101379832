import styles from './ark-second-page.module.scss';
import baseStyles from '../styles/core-settlement.module.scss';
import classes from '../../../../../../utils/classes';
import SheetPrinciples from '../../../elements/sheets/sheet-principles/sheet-principle.component';
import SheetParagraph from '../../../elements/sheets/sheet-paragraphs/sheet-paragraph.component';
import SheetSettlements from '../../../elements/sheets/sheet-settlements/sheet-settlements.component';

export default function ArkSecondPage() {
    const locationPrefilled = [
        'Keeper of Dreams',
        'Bone Smith',
        'Skinnery',
        'Organ Grinder',
        'Forum',
        'Outskirts',
        'Leather worker',
        'Weapon Crafter',
        'Barber Surgeon',
        'Blacksmith',
        'Mask Maker',
        'Crimson Crockery',
        'Chorusseum',
        'Plumery',
        'Kingsmith',
    ];

    const principles = [
        {
            label: 'New Life',
            options: {
                1: { id: 0, label: 'Protect the Young' },
                2: { id: 1, label: 'Survival of the Fittest' },
            },
        },
        {
            label: 'Death',
            options: {
                1: { id: 2, label: 'Cannibalize' },
                2: { id: 3, label: 'Graves' },
            },
        },
        {
            label: 'Society',
            options: {
                1: { id: 4, label: 'Collective Toil' },
                2: { id: 5, label: 'Accept Darkness' },
            },
        },
        {
            label: 'Conviction',
            options: {
                1: { id: 6, label: 'Barbaric' },
                2: { id: 7, label: 'Romantic' },
            },
        },
        {
            id: 'principle_0',
            label: '',
            options: {
                1: { id: 8, label: '', inputId: 'principle_1' },
                2: { id: 9, label: '', inputId: 'principle_2' },
            },
        },
    ];

    return (
        <div className={classes(baseStyles.pageContainer, styles.ark)}>
            <div className={baseStyles.innerContainer}>
                <div className={styles.twoCol}>
                    <div className={styles.principleContainer}>
                        <div className={styles.header}>
                            <div className={styles.headerTitle}>Principles</div>
                            <div className={styles.horizontalLine}></div>
                            <div className={styles.headerSubtitle}>
                                The settlement's established principles.
                            </div>
                        </div>
                        <SheetPrinciples
                            principles={principles}
                            idPrefix="principle_check_"
                            extraFieldsIds={[]}
                        />
                    </div>
                    <div className={styles.seedsContainer}>
                        <div className={styles.header}>
                            <div className={styles.headerTitle}>
                                Seed Patterns
                            </div>
                            <div className={styles.horizontalLine}></div>
                            <div className={styles.headerSubtitle}>
                                Patterns gained when survivors reach 3
                                understanding.
                            </div>
                        </div>
                        <SheetParagraph
                            idPrefix="seed_pattern_"
                            linesPerColumn={5}
                            totalLines={10}
                            lineHeight={19}
                            helperText=""
                        />
                    </div>
                    <div className={styles.InnovationContainer}>
                        <div className={styles.header}>
                            <div className={styles.headerTitle}>Innovation</div>
                            <div className={styles.horizontalLine}></div>
                            <div className={styles.headerSubtitle}>
                                The settlement's innovations (including weapon
                                masteries).
                            </div>
                        </div>
                        <SheetParagraph
                            idPrefix="innovations_"
                            linesPerColumn={11}
                            totalLines={22}
                            lineHeight={19}
                            helperText=""
                        />
                    </div>
                    <div className={styles.settlementContainer}>
                        <div className={styles.header}>
                            <div className={styles.headerTitle}>
                                Settlement Locations
                            </div>
                            <div className={styles.horizontalLine}></div>
                        </div>
                        <SheetSettlements
                            locationPrefilled={locationPrefilled}
                            idPrefix="settlement_checks_"
                            extraFieldIDPrefix="settlements_"
                            layout="ark"
                            locationTextboxCount={7}
                            startTextBoxIndex={15}
                        />
                    </div>

                    <div className={styles.resourceContainer}>
                        <div className={styles.header}>
                            <div className={styles.headerTitle}>
                                Resource Storage
                            </div>
                            <div className={styles.resourceColumnNames}>
                                <span>Basic & Strange</span>
                                <span>Node 1</span>
                                <span>Node 2</span>
                                <span>Node 3</span>
                                <span>Node 4</span>
                            </div>
                            <div className={styles.horizontalLine}></div>
                        </div>
                        <SheetParagraph
                            idPrefix="resources_"
                            linesPerColumn={10}
                            totalLines={50}
                            lineHeight={22}
                            gap={15}
                            helperText=""
                        />
                    </div>
                    <div className={styles.lastRow}>
                        <div className={styles.gearContainer}>
                            <div className={styles.header}>
                                <div className={styles.headerTitle}>
                                    Gear Storage
                                </div>
                                <div className={styles.horizontalLine}></div>
                            </div>
                            <SheetParagraph
                                idPrefix="gears_"
                                linesPerColumn={10}
                                totalLines={30}
                                lineHeight={22}
                                gap={15}
                                helperText=""
                            />
                        </div>
                        <div className={styles.gearContainer}>
                            <div className={styles.header}>
                                <div className={styles.headerTitle}>
                                    Patterns
                                </div>
                                <div className={styles.horizontalLine}></div>
                            </div>
                            <SheetParagraph
                                idPrefix="patterns_"
                                linesPerColumn={10}
                                totalLines={10}
                                lineHeight={22}
                                helperText=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
