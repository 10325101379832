import { useInjection } from 'inversify-react';
import React, { useEffect } from 'react';
import { SurvivorListComponent } from '../survivor-list/survivor-list.component';
import ArkFirstPage from './ark-settlement/ark-first-page.component';
import ArkFourthPage from './ark-settlement/ark-fourth-page.component';
import ArkSecondPage from './ark-settlement/ark-second-page.component';
import ArkThirdPage from './ark-settlement/ark-third-page.component';
import styles from './styles/ark-settlement-sheet.module.scss';
import StateValueWrapper from '../../../models/stateFragments/StateValueWrapper';
import { SheetSide } from '../../../../../models/SheetSide';
import { IAuthService } from '../../../../../services/IAuthService';
import classes from '../../../../../utils/classes';
import { StateValueWrapperProvider } from '../../../contexts/StateValueWrapperContext';

interface ArkSettlementSheetProps {
    stateValueWrapper: StateValueWrapper;
    sheetSide: SheetSide;
    openSurvivor: (id: number) => void;
    createSurvivor: (name: string) => Promise<any>;
    setShowPrintBtn: (show: boolean) => void;
}

const ArkSettlementSheet = (props: ArkSettlementSheetProps) => {
    const authService = useInjection<IAuthService>('IAuthService');
    const [newCharacterName, setNewCharacterName] = React.useState('');
    const [newSurvivors, setNewSurvivors] = React.useState(0);

    useEffect(() => {
        props.setShowPrintBtn(false);
        return () => {
            props.setShowPrintBtn(true);
        };
    }, []);

    const filterSurvivors = () => {};

    const openSurvivor = (id: number) => {
        props.openSurvivor(id);
    };

    const onSurvivorNameInput = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        event.preventDefault();
        const { value } = event.target;
        if (value === '') {
            setNewCharacterName('');
        } else {
            setNewCharacterName(value);
        }
    };

    const onCheckCreateSurvivor = (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (event.key !== 'Enter' && event.key !== 'Return') return;
        if (newCharacterName === null || newCharacterName === '') return;
        onSurvivorCreation(newCharacterName);
    };

    const onSurvivorCreation = async (name: string) => {
        await props.createSurvivor(name);
        setNewCharacterName('');
        setNewSurvivors(newSurvivors + 1);
    };

    const onCreateSurvivor = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (newCharacterName === null || newCharacterName === '') return;
        onSurvivorCreation(newCharacterName);
    };

    const dataAccess = authService.getAccessLevel('data');

    const renderFirstPage = () => {
        const { sheetSide } = props;

        return (
            <div
                className={classes(
                    'record_sheet_front print_sheet',
                    sheetSide === SheetSide.First ? 'show-page' : 'hide-page'
                )}
            >
                <ArkFirstPage />
            </div>
        );
    };

    const renderSecondPage = () => {
        const { sheetSide } = props;

        return (
            <div
                className={classes(
                    'record_sheet_front print_sheet',
                    sheetSide === SheetSide.Second ? 'show-page' : 'hide-page'
                )}
            >
                <ArkSecondPage />
            </div>
        );
    };

    const renderThirdPage = () => {
        const { sheetSide } = props;

        return (
            <div
                className={classes(
                    'record_sheet_front print_sheet',
                    sheetSide === SheetSide.Third ? 'show-page' : 'hide-page'
                )}
            >
                <ArkThirdPage createSurvivor={onSurvivorCreation} dataAccess={2} onOpenSurvivor={props.openSurvivor} sheetId={props.stateValueWrapper.getInputValueInt('id')}/>
            </div>
        );
    };

    const renderFourthPage = () => {
        const { sheetSide } = props;

        return (
            <div
                className={classes(
                    'record_sheet_front print_sheet',
                    sheetSide === SheetSide.Fourth ? 'show-page' : 'hide-page'
                )}
            >
                <ArkFourthPage />
            </div>
        );
    };

    const arkSheet = () => {
        return (
            <div className={styles.pageContainer}>
                {/* <FrontSheetHeader /> */}
                {dataAccess >= 1 ? (
                    <div className={styles.create_survivor}>
                        <input
                            type="text"
                            id="create_survivor_name"
                            name="create_survivor_name"
                            className={styles.create_survivor_name}
                            placeholder="New survivor name..."
                            onInput={onSurvivorNameInput}
                            onKeyDown={onCheckCreateSurvivor}
                            value={newCharacterName!}
                        />
                        <button
                            type="button"
                            className={styles.create_survivor_btn}
                            onClick={onCreateSurvivor}
                            disabled={
                                newCharacterName === null ||
                                newCharacterName === ''
                            }
                        ></button>
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    };

    return (
        <StateValueWrapperProvider value={props.stateValueWrapper}>
            {renderFirstPage()}
            {renderSecondPage()}
            {renderThirdPage()}
            {renderFourthPage()}
        </StateValueWrapperProvider>
    );
};

export default ArkSettlementSheet;
