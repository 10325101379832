import { Children } from "react";
import styles from './Toolbar.module.scss'
import classes from "../../../../utils/classes";

interface ToolbarComponents{
    children: React.ReactNode;
    toolbarClass?:string;
    style?: React.CSSProperties;
}

function Toolbar(props : ToolbarComponents){

    let _toolbarLeft;
    let _toolbarRight;
    Children.forEach(props.children, (child) => {
        if (child) {
          
            if ((child as React.ReactElement).type === ToolBarLeft) {
                _toolbarLeft = child;
            }
            if ((child as React.ReactElement).type === ToolBarRight) {
                _toolbarRight = child;
            }
        }
    });

    return (
        <div className={classes(props.toolbarClass, styles.toolbar)} style={props.style}>
            {!!_toolbarLeft&&<div className={styles.left}>{_toolbarLeft}</div>}
            {!!_toolbarRight&&<div  className={styles.right}>{_toolbarRight}</div>}
        </div>
    )
}

const ToolBarLeft = (props: ToolbarComponents) => props.children;
const ToolBarRight = (props: ToolbarComponents) => props.children;


Toolbar.Left = ToolBarLeft as React.ElementType;
Toolbar.Right = ToolBarRight as React.ElementType;

export const ToolbarSeparator = ()=><div className={styles.divider}></div>

export default Toolbar;