import styles from './sheet-nemesis.module.scss';
import sheetStyles from '../styles/sheet-styles.module.scss';
import classes from '../../../../../../utils/classes';
import renderFor, { renderForEach } from '../../../../../../utils/renderFor';
import KDHTMLCustomCheckbox from '../../../../../../components/general/kd-html-input/KDHtmlCustomCheckbox';
import KDHTMLInput from '../../../../../../components/general/kd-html-input/KDHtmlInput.component';

interface INemesisMonsters {
    nemesisMonsters: string[];
    layout?: 'default' | 'ark';
    cssClass? : string
}

const SheetNemesisMonsters = ({
    nemesisMonsters,
    layout = 'default',
    cssClass
}: INemesisMonsters) => {
    const numberOfLines = layout === 'ark' ? 6 : 5;

    const checkboxes = [
        [
            {
                id: -1,
                checked: true,
            },
            {
                id: 0,
                checked: false,
            },
            {
                id: 1,
                checked: false,
            },
            {
                id: 2,
                checked: false,
            },
        ],
        [
            {
                id: 9,
                checked: false,
            },
            {
                id: 3,
                checked: false,
            },
            {
                id: 4,
                checked: false,
            },
            {
                id: 5,
                checked: false,
            },
        ],
        [
            {
                id: 10,
                checked: false,
            },
            {
                id: 6,
                checked: false,
            },
            {
                id: 7,
                checked: false,
            },
            {
                id: 8,
                checked: false,
            },
        ],
    ];

    return (
        <div className={cssClass}>
            <div
                className={classes(
                    styles.nemesisMonstersContainer,
                    styles[layout]
                )}
            >
                <div className={styles.header}>
                    <p className={styles.headerTitle}>Nemesis Monsters</p>
                    <p className={styles.headerHelper}>
                        The available nemesis encounter monsters.
                    </p>
                </div>

                {renderFor(0, numberOfLines, (index) => {
                    const top = `${index * 22.8 + 73}px`;
                    return (
                        <div
                            key={index}
                            className={styles.horizontalLine}
                            style={{ top: top }}
                        ></div>
                    );
                })}

                {renderForEach(nemesisMonsters, (element, index) => {
                    const top = `${52 + index * 23}px`;
                    return (
                        <div
                            key={index}
                            className={styles.nemesisMonsterRow}
                            style={{ top: top }}
                        >
                            <p className={styles.nemesisMonster}>{element}</p>

                            {renderFor(0, 3, (index) => {
                                const left = `${135 + index * 38}px`;
                                return (
                                    <p
                                        key={index}
                                        className={styles.nemesisLvl}
                                        style={{
                                            left: left,
                                        }}
                                    >
                                        Lvl {index + 1}
                                    </p>
                                );
                            })}
                        </div>
                    );
                })}
                <div className={styles.nemesisCheckboxBlock}>
                    {checkboxes.map((row, index) => {
                        return (
                            <div key={index} className={styles.nemesisCheckboxRow}>
                                <div
                                    className={
                                        styles.nemesisFirstCheckboxContainer
                                    }
                                >
                                    <KDHTMLCustomCheckbox
                                        containerClass={classes(
                                            sheetStyles.checkboxContainer,
                                            styles.nemesisCheckboxContainer,
                                            row[0].id < 9 && row[0].id > -1
                                                ? 'small'
                                                : 'large'
                                        )}
                                        key={`label_${row[0].id}`}
                                        id={`nemesis_check_${row[0].id}`}
                                        name={`nemesis_check_${row[0].id}`}
                                        checked={row[0].checked}
                                    />
                                </div>
                                <div
                                    className={
                                        styles.nemesisRestCheckboxContainer
                                    }
                                >
                                    {row.map((checkbox, index) => {
                                        if (index === 0) return '';
                                        const id = `nemesis_check_${checkbox.id}`;
                                        return (
                                            <KDHTMLCustomCheckbox
                                                containerClass={classes(
                                                    sheetStyles.checkboxContainer,
                                                    styles.nemesisCheckboxContainer,
                                                    checkbox.id < 9 &&
                                                    checkbox.id > -1
                                                        ? 'small'
                                                        : 'large'
                                                )}
                                                key={`label_${checkbox.id}`}
                                                id={id}
                                                name={id}
                                                checked={checkbox.checked}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
{/* 
                {renderFor(-1, 11, (index) => {
                    const id = `nemesis_check_${index}`;
                    let currentClass = classes(
                        sheetStyles.checkboxContainer,
                        styles.nemesisCheckboxContainer
                    );
                    let top = '';
                    let left = '';
                    const { row, col } = rowCol(index, 3);
                    top = `${13 + row * 23}px`;
                    if (index < 9 && index > -1) {
                        currentClass = classes(currentClass, 'small');
                        left = `${125 + col * 38}px`;
                    } else {
                        top = `${
                            36.5 + (index >= 9 ? index - 9 : index) * 23
                        }px`;
                        currentClass = classes(currentClass, 'large');
                    }

                    return (
                        <KDHTMLCustomCheckbox
                            containerClass={currentClass}
                            key={`label_${id}`}
                            id={id}
                            name={id}
                            containerStyle={{ top: top, left: left }}
                            checked={index === -1}
                        />
                    );
                })} */}

                <KDHTMLInput
                    type="text"
                    className={styles.nemesisMonsterInput}
                    id="nemesis_0"
                    name="nemesis_0"
                />
                <KDHTMLInput
                    type="text"
                    className={styles.nemesisMonsterInput}
                    id="nemesis_1"
                    name="nemesis_1"
                />
            </div>
        </div>
    );
};

export default SheetNemesisMonsters;
