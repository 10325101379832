import PoTSunSurvivor from '../../models/survivors/PoTSunSurvivor';
import CoreSurvivorSheetState from '../../models/stateFragments/survivor/CoreSurvivorSheetState';
import BaseMapper from '../BaseMapper';

export default class PoTSunSurvivorSheetMapper extends BaseMapper<
    PoTSunSurvivor,
    CoreSurvivorSheetState
> {
    public mapToState(survivor: PoTSunSurvivor): CoreSurvivorSheetState {
        const state: CoreSurvivorSheetState = {
            values: new Map<string, string>(),
            checkmarks: new Map<string, boolean>(),
        };

        this.setText(state.values,'id', survivor.id!.toString());

        this.setText(state.values,'survivor_name', survivor.survivor_name);

        state.checkmarks?.set('male_check', survivor.survivor_sex === 0);
        this.setText(state.values,'survival_count', survivor.survival_count!.toString());

        state.checkmarks?.set(
            'survival_check',
            this.intToBoolean(survivor.survival_check!)
        );
        state.checkmarks?.set(
            'resolve_check',
            this.intToBoolean(survivor.resolve_check!)
        );
        state.checkmarks?.set(
            'overcharge_check',
            this.intToBoolean(survivor.overcharge_check!)
        );
        state.checkmarks?.set(
            'dash_check',
            this.intToBoolean(survivor.dash_check!)
        );

        this.setText(state.values,'movement', survivor.movement!.toString());

        this.setText(state.values,'accuracy_count', survivor.accuracy_count);
        this.setText(state.values,'strength_count', survivor.strength_count);
        this.setText(state.values,'evasion_count', survivor.evasion_count);
        this.setText(state.values,'luck_count', survivor.luck_count);
        this.setText(state.values,'speed_count', survivor.speed_count);
        this.setText(state.values,'insanity_count', survivor.insanity_count!.toString());

        state.checkmarks?.set(
            'brain_check',
            this.intToBoolean(survivor.brain_check!)
        );

        this.setText(state.values,'head_count', survivor.head_count!.toString());
        this.setText(state.values,'arms_count', survivor.arms_count!.toString());
        this.setText(state.values,'body_count', survivor.body_count!.toString());
        this.setText(state.values,'waist_count', survivor.waist_count!.toString());
        this.setText(state.values,'legs_count', survivor.legs_count!.toString());

        state.checkmarks?.set(
            'head_toggle',
            this.intToBoolean(survivor.head_toggle!)
        );
        this.setBooleanCountToMap(
            state.checkmarks,
            'head_toggle_',
            survivor.head_toggle,
            1,
            0
        );

        this.setBooleanCountToMap(
            state.checkmarks,
            'arms_toggle_',
            survivor.arms_toggle,
            2,
            0
        );

        this.setBooleanCountToMap(
            state.checkmarks,
            'body_toggle_',
            survivor.body_toggle,
            2,
            0
        );

        this.setBooleanCountToMap(
            state.checkmarks,
            'waist_toggle_',
            survivor.waist_toggle,
            2,
            0
        );

        this.setBooleanCountToMap(
            state.checkmarks,
            'legs_toggle_',
            survivor.legs_toggle,
            2,
            0
        );

        const injuryCounts = [1,1,2,1,2,1,5,2,5,1,5,1,5,1,1,2,1,2];


        survivor.injury.forEach((injury, index)=>{
            this.setBooleanCountToMap(
                state.checkmarks,
                `injury_${index}_`,
                injury,
                injuryCounts[index],
                0
            );
        })

        this.setBooleanCountToMap(
            state.checkmarks,
            'hunt_xp_',
            survivor.hunt_xp!,
            16,
            0
        );

        this.setText(state.values,'weapon_proficiency', survivor.weapon_proficiency);

        this.setBooleanCountToMap(
            state.checkmarks,
            'weapon_xp_',
            survivor.weapon_xp!,
            8
        );

        this.setBooleanCountToMap(
            state.checkmarks,
            'courage_xp_',
            survivor.courage_xp!,
            9
        );
        this.setBooleanCountToMap(
            state.checkmarks,
            'understanding_xp_',
            survivor.understanding_xp!,
            9
        );

        state.checkmarks?.set(
            'purified_check',
            this.intToBoolean(survivor.purified_check!)
        );

        state.checkmarks?.set(
            'sun_eater_check',
            this.intToBoolean(survivor.sun_eater_check!)
        );

        state.checkmarks?.set(
            'child_of_the_sun_check',
            this.intToBoolean(survivor.child_of_the_sun_check!)
        );

        this.setText(state.values, 'partner', survivor.partner);
        
        state.checkmarks?.set(
            'arts_check',
            this.intToBoolean(survivor.arts_check!)
        );

        this.setTextSubArrayToMap(
            state.values,
            'fighting_art_',
            survivor.fighting_arts!
        );

        this.setTextSubArrayToMap(
            state.values,
            'disorders_',
            survivor.disorders!
        );

        state.checkmarks?.set(
            'hunt_check',
            this.intToBoolean(survivor.hunt_check!)
        );
        
        this.setTextSubArrayToMap(state.values, 'ability_', survivor.abilities!);       

        this.setTextSubArrayToMap(state.values, 'gear_entry_', survivor.gears!);

        this.setText(state.values,'survivor_notes', survivor.notes);

        state.checkmarks?.set(
            'survivor_dead',
            this.intToBoolean(survivor.dead!)
        );

        this.setText(state.values,'last_updated', survivor.last_updated);
        this.setText(state.values,'parent_id', survivor.parent_id!.toString());
        this.setText(state.values,'settlement_id', survivor.settlement_id.toString());
        this.setText(state.values,'lantern_year', survivor.lantern_year!.toString());

        this.setText(state.values,'survivor_type', survivor.survivor_type.toString());

        this.setText(state.values, 'saviour_type', survivor.saviour_type.toString());
        
        return state;
    }

    public mapFromState(from: CoreSurvivorSheetState): PoTSunSurvivor {
        const survivor: PoTSunSurvivor = {
            id: this.getValueInt(from.values, 'id'),
            survivor_name: this.getValue(from.values, 'survivor_name'),
            survivor_sex: this.getCheckmarkValue(from.checkmarks, 'male_check')
                ? 0
                : 1,
            survival_count: this.getValueInt(from.values, 'survival_count'),
            survival_check: this.getCheckmarkValueInt(
                from.checkmarks,
                'survival_check'
            ),
            resolve_check: this.getCheckmarkValueInt(
                from.checkmarks,
                'resolve_check'
            ),
            overcharge_check: this.getCheckmarkValueInt(
                from.checkmarks,
                'overcharge_check'
            ),
            dash_check: this.getCheckmarkValueInt(
                from.checkmarks,
                'dash_check'
            ),

            movement: this.getValueInt(from.values, 'movement'),

            accuracy_count: this.getValue(from.values, 'accuracy_count'),
            strength_count: this.getValue(from.values, 'strength_count'),
            evasion_count: this.getValue(from.values, 'evasion_count'),
            luck_count: this.getValue(from.values, 'luck_count'),
            speed_count: this.getValue(from.values, 'speed_count'),
            insanity_count: this.getValueInt(from.values, 'insanity_count'),

            brain_check: this.getCheckmarkValueInt(
                from.checkmarks,
                'brain_check'
            ),

            head_count: this.getValueInt(from.values, 'head_count'),
            arms_count: this.getValueInt(from.values, 'arms_count'),
            body_count: this.getValueInt(from.values, 'body_count'),
            waist_count: this.getValueInt(from.values, 'waist_count'),
            legs_count: this.getValueInt(from.values, 'legs_count'),

            head_toggle: this.getBoolCount(
                from.checkmarks,
                'head_toggle_',
                true
            ),
            arms_toggle: this.getBoolCount(
                from.checkmarks,
                'arms_toggle_',
                true
            ),
            body_toggle: this.getBoolCount(
                from.checkmarks,
                'body_toggle_',
                true
            ),
            waist_toggle: this.getBoolCount(
                from.checkmarks,
                'waist_toggle_',
                true
            ),
            legs_toggle: this.getBoolCount(
                from.checkmarks,
                'legs_toggle_',
                true
            ),

            injury: this.getBoolCountArrayWithCountMap(from.checkmarks, 'injury_', 18, [1,1,2,1,2,1,5,2,5,1,5,1,5,1,1,2,1,2]),

            hunt_xp: this.getBoolCount(from.checkmarks, 'hunt_xp_', true),
            
            weapon_proficiency: this.getValue(
                from.values,
                'weapon_proficiency'
            ),
            
            weapon_xp: this.getBoolCount(from.checkmarks, 'weapon_xp_', true),
            
            courage_xp: this.getBoolCount(from.checkmarks, 'courage_xp_', true),

            understanding_xp: this.getBoolCount(
                from.checkmarks,
                'understanding_xp_',
                true
            ),
            

            purified_check: this.getCheckmarkValueInt(
                from.checkmarks,
                'purified_check'
            ),
            sun_eater_check: this.getCheckmarkValueInt(
                from.checkmarks,
                'sun_eater_check'
            ),
            child_of_the_sun_check: this.getCheckmarkValueInt(
                from.checkmarks,
                'child_of_the_sun_check'
            ),
            partner: this.getValue(
                from.values,
                'partner'
            ),
            arts_check: this.getCheckmarkValueInt(
                from.checkmarks,
                'arts_check'
            ),

            fighting_arts: this.getTextSubArrayPrefix(
                from.values,
                'fighting_art_'
            ),
            disorders: this.getTextSubArrayPrefix(from.values, 'disorders_'),
            hunt_check: this.getCheckmarkValueInt(
                from.checkmarks,
                'hunt_check'
            ),
         
            abilities: this.getTextSubArrayPrefix(from.values, 'ability_'),           

            gears: this.getTextSubArrayPrefix(from.values, 'gear_entry_'),
            notes: this.getValue(from.values, 'survivor_notes'),

            dead: this.getCheckmarkValueInt(from.checkmarks, 'survivor_dead'),

            last_updated: this.getValue(from.values, 'last_updated'),
            parent_id: this.getValueInt(from.values, 'parent_id'),
            alternative_survivors: [],
            settlement_id: this.getValueInt(from.values, 'settlement_id'),
            lantern_year: this.getValueInt(from.values, 'lantern_year'),
            survivor_type: this.getValueInt(from.values, 'survivor_type'),
            saviour_type : this.getValueInt(from.values, 'saviour_type')
        };

        return survivor;
    }
}
