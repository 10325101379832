import styles from './sheet-milestones.module.scss';
import sheetStyles from '../styles/sheet-styles.module.scss';
import KDHTMLCustomCheckbox from '../../../../../../components/general/kd-html-input/KDHtmlCustomCheckbox';
import classes from '../../../../../../utils/classes';

interface Milestone {
    id: number;
    label: string;
    principle: string;
}

interface SheetMileStoneProps {
    milestones: Milestone[];
    idPrefix: string;
    layout?: 'horizontal' | 'vertical';
    emptyLines?: React.ReactNode;
    title?: string;
}

interface MilestoneProps extends Milestone {
    idPrefix: string;
}

const MileStoneLayoutVertical = ({
    milestones,
    idPrefix,
    emptyLines,
    title,
}: SheetMileStoneProps) => {
    return (
        <div className={styles.verticalLayout}>
            <div className={styles.mileStoneStoryEventLabel}>
                <p className={styles.mileStoneHeader}>{title}</p>
                <span className={styles.mileStoneHelper}>
                    Trigger these story events when the milestone condition is
                    met.
                </span>
            </div>

            <div className={styles.mileStoneLine}>
                {milestones.map((mileStone, index) => {
                    const id = `${idPrefix}${mileStone.id}`;
                    return (
                        <div key={index} className={styles.mildStoneContainer}>
                            <KDHTMLCustomCheckbox
                                containerClass={classes(
                                    sheetStyles.checkboxContainer
                                )}
                                id={id}
                                name={id}
                                key={id}
                            />
                            <p
                                className={classes(
                                    styles.mileStoneRegular,
                                    styles.firstChildIsBorn
                                )}
                            >
                                {mileStone.label}
                            </p>
                            <p
                                className={classes(
                                    styles.mileStoneMedium,
                                    styles.principleNewLife
                                )}
                            >
                                <span className={styles.milestoneIcon}>s</span>
                                {mileStone.principle}
                            </p>
                        </div>
                    );
                })}
                {emptyLines}
            </div>
        </div>
    );
};

const Milestone = ({ id, label, principle, idPrefix }: MilestoneProps) => {
    const idText = `${idPrefix}${id}`;
    return (
        <div className={styles.container}>
            {id >= 0 && (
                <>
                    <KDHTMLCustomCheckbox
                        containerClass={sheetStyles.checkboxContainer}
                        id={idText}
                        name={idText}
                    />
                    <p className={styles.regular}>{label}</p>
                    <p className={styles.medium}>
                        <span className={styles.icon}>s</span>
                        {principle}
                    </p>
                </>
            )}
        </div>
    );
};

const MileStoneLayoutHorizontal = ({
    milestones,
    idPrefix,
    title,
}: SheetMileStoneProps) => {
    const renderMilestones = (milestones: Milestone[]) =>
        milestones.map((mileStone) => (
            <Milestone key={mileStone.id} idPrefix={idPrefix} {...mileStone} />
        ));

    return (
        <div className={styles.horizontalLayout}>
            <div className={styles.eventLabel}>
                <p className={styles.header}>{title}</p>
                <span className={styles.helper}>
                    Trigger these story events when the milestone condition is
                    met.
                </span>
            </div>

            <div className={styles.line}>
                {renderMilestones(milestones.slice(0, 3))}
            </div>
            <div className={styles.horizontalSeparator} />

            <div className={styles.line}>
                {renderMilestones(milestones.slice(3))}
            </div>
        </div>
    );
};

const SheetMileStones = ({
    milestones,
    idPrefix,
    layout,
    emptyLines,
    title = 'Milestone Story Events',
}: SheetMileStoneProps) => {
    if (layout === 'vertical') {
        return (
            <MileStoneLayoutVertical
                title={title}
                milestones={milestones}
                idPrefix={idPrefix}
                emptyLines={emptyLines}
            />
        );
    } else {
        return (
            <MileStoneLayoutHorizontal
                title={title}
                milestones={milestones}
                idPrefix={idPrefix}
            />
        );
    }
};

export default SheetMileStones;
