import { CoreSettlementSheetModel } from "../../models/settlements/CoreSettlementSheetModel";
import CoreSettlementSheetState from "../../models/stateFragments/sheets/CoreSettlementSheetState";
import BaseMapper from "../BaseMapper";

export default class CoreSettlementSheetMapper extends BaseMapper<CoreSettlementSheetModel, CoreSettlementSheetState>{
    public mapToState(from: CoreSettlementSheetModel): CoreSettlementSheetState {
        const state : CoreSettlementSheetState = {
            values : new Map<string,string>(),
            checkmarks : new Map<string,boolean>()
        }

        state.values?.set("id", from.id!.toString());
        
        this.setExpansionCheckmarksFromString(state.checkmarks, "expansion_", from.expansion_ids!, 18);
        
        this.setText(state.values,"description", from.description);
        this.setText(state.values,"settlement_name", from.settlement_name);
        this.setText(state.values,"survival_limit", from.survival_limit.toString());
        this.setBooleanCountToMap(state.checkmarks, "death_", from.death_count,38,0);
        this.setBooleanCountToMap(state.checkmarks, "time_check_", from.lantern_year ,36, 0);
        this.setTextSubArrayToMap(state.values, "time_", from.timeline_events);
        this.setBooleanIntSubArrayToMap(state.checkmarks, "mile_", from.milestones);
        this.setTextSubArrayToMap(state.values, "innovation_", from.innovations);
        this.setBooleanIntSubArrayToMap(state.checkmarks, "settlement_check_", from.settlement_checks);
        this.setTextSubArrayToMap(state.values, "settlement_",from.settlements);
        this.setBooleanIntSubArrayToMap(state.checkmarks, "principle_check_", from.principle_checks);
        this.setTextSubArrayToMap(state.values, "principle_", from.principles);
        state.checkmarks?.set("quarry_check_0", this.intToBoolean(from.quarry_check_0));
        state.checkmarks?.set("quarry_check_1", this.intToBoolean(from.quarry_check_1));
        this.setTextSubArrayToMap(state.values, "quarry_", from.quarries);

        this.setTextSubArrayToMap(state.values, "resource_", from.resources);
        this.setTextSubArrayToMap(state.values, "gear_", from.gears);
        this.setBooleanIntSubArrayToMap(state.checkmarks, "nemesis_check_", from.nemesis_checks);

        this.setText(state.values,"nemesis_0",from.nemesis_0);
        this.setText(state.values,"nemesis_1",from.nemesis_1);
        state.values?.set("research", from.research.toString());

        this.setTextSubArrayToMap(state.values,"volume_",from.volumes);
        this.setTextSubArrayToMap(state.values,"note_",from.notes);

        this.setText(state.values,"population",from.population.toString());

        this.setBooleanCountToMap(state.checkmarks, "set_check_", 20, from.lost_settlements, 0);

        this.setText(state.values,"from_year","0");
        this.setText(state.values,"to_year","36");

        this.setText(state.values,"last_updated", from.last_updated);

        this.setText(state.values,'settlement_type', from.settlement_type.toString());
        return state;
    }
    public mapFromState(from: CoreSettlementSheetState): CoreSettlementSheetModel {
        let model : CoreSettlementSheetModel = {
            id : parseInt(this.getValue(from.values,"id")),
            expansion_ids : this.getExpansionString(from.checkmarks, "expansion_"),
            settlement_name : this.getValue(from.values, "settlement_name"),
            survival_limit : parseInt(this.getValue(from.values, "survival_limit")),
            death_count : this.getBoolCount(from.checkmarks,"death_",true),
            lantern_year : this.getBoolCount(from.checkmarks, "time_check_", true),
            timeline_events : this.getTextSubArrayPrefix(from.values, "time_"),
            milestones : this.getBooleanSubArrayPrefix(from.checkmarks, "mile_"),
            innovations : this.getTextSubArrayPrefix(from.values, "innovation_"),
            settlement_checks : this.getBooleanSubArrayPrefix(from.checkmarks, "settlement_check_"),
            settlements : this.getTextSubArray(from.values, (key,value)=>{return key.startsWith("settlement_") && !key.startsWith("settlement_name")}),
            principle_checks : this.getBooleanSubArrayPrefix(from.checkmarks, "principle_check_"),
            principles : this.getTextSubArrayPrefix(from.values, "principle_"),
            quarry_check_0 : this.getCheckmarkValueInt(from.checkmarks, "quarry_check_0"),
            quarry_check_1 : this.getCheckmarkValueInt(from.checkmarks, "quarry_check_1"),
            quarries : this.getTextSubArrayPrefix(from.values, "quarry_"),

            resources : this.getTextSubArrayPrefix(from.values, "resource_"),
            gears : this.getTextSubArrayPrefix(from.values, "gear_"),
            nemesis_checks : this.getBooleanSubArrayPrefix(from.checkmarks, "nemesis_check_"),
            nemesis_0 : this.getValue(from.values, "nemesis_0"),
            nemesis_1 : this.getValue(from.values, "nemesis_1"),
            research : parseInt(this.getValue(from.values,"research")),

            volumes:this.getTextSubArrayPrefix(from.values, "volume_"),
            notes : this.getTextSubArrayPrefix(from.values, "note_"),

            population : parseInt(this.getValue(from.values, "population")),
            lost_settlements : this.getBoolCount(from.checkmarks, "set_check_", true),
            settlement_type : this.getValueInt(from.values, 'settlement_type'),
            description : this.getValue(from.values, "description"),
            last_updated : this.getValue(from.values, "last_updated")
        };
        return model;
    }

}