import React, { useContext } from 'react';
import classes from '../../../utils/classes';
import KDHTMLInput, { KDHTMLInputProps } from './KDHtmlInput.component';
import { StateValueWrapperContext } from '../../../modules/record-sheets-module/contexts/StateValueWrapperContext';

interface KDHTMLBoldableProps extends KDHTMLInputProps {
    boldclass: string;
}

export default function KDHtmlBoldable(props: KDHTMLBoldableProps) {

    const {id} =props;

    const context = useContext(StateValueWrapperContext);
    
    const boldId = `${props.id}_bold`;

    let isBold = context?.getCheckboxValue(boldId);

    if(!isBold){
        isBold = false;
    }

    const onRightClick = (event: React.MouseEvent<HTMLLabelElement>) => {
        event.preventDefault();
        context?.setCheckboxValue(boldId, !isBold);
    };

    let className = props.className;

    if (isBold) {
        className = classes(className, props.boldclass);
    }

    

    return (
        <label key={`label_${id}`} className={className} onContextMenu={onRightClick} >
            <KDHTMLInput
                type="checkbox"
                id={id}
                name={id}
                {...props}
                className={undefined}
                data-bold={boldId}
            />
            <span className="checkmark"></span>
        </label>
    );
}
