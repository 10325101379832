import KDHTMLTextArea from "../../../../../../components/general/kd-html-input/KDHTMLTextArea.component";
import KDHTMLInput from "../../../../../../components/general/kd-html-input/KDHtmlInput.component";
import renderFor from "../../../../../../utils/renderFor";
import { StateValueWrapperProvider } from "../../../../contexts/StateValueWrapperContext";
import StateValueWrapper from "../../../../models/stateFragments/StateValueWrapper";

interface SurvivorInfoProps {
    stateValueWrapper: StateValueWrapper;
    lanternYearChanged: (year: number) => void;
    isVisible: boolean;
}

export default function SurvivorInfo(props: SurvivorInfoProps) {
    const isVisible = props.isVisible;

    const lanternYearChanged = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        event.preventDefault();
        const { value } = event.target;
        props.lanternYearChanged(parseInt(value));
    };

    return (
        <StateValueWrapperProvider value={props.stateValueWrapper}>
            <div
                id="survivor_info_top"
                style={{ display: isVisible ? undefined : 'none' }}
            >
                <div className="info_row">
                    <div className="year_field">
                        <span className="info-label">Year: </span>
                        <select
                            name="survivor_year"
                            id="survivor_year"
                            onChange={lanternYearChanged}
                            value={props.stateValueWrapper.getInputValue(
                                'lantern_year'
                            )}
                        >
                            {renderFor(1, 37, (index) => {
                                return (
                                    <option key={index} value={index}>
                                        {index}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>

                <div className="gear_entries">
                    <div className="gear_entry_row">
                        <div className="gear_entry_column">
                            <span>Gear 1: </span>
                            <KDHTMLInput type="text" id="gear_entry_0" />
                        </div>
                        <div className="gear_entry_column">
                            <span>Gear 2: </span>
                            <KDHTMLInput type="text" id="gear_entry_1" />
                        </div>
                        <div className="gear_entry_column">
                            <span>Gear 3: </span>
                            <KDHTMLInput type="text" id="gear_entry_2" />
                        </div>
                    </div>
                    <div className="gear_entry_row">
                        <div className="gear_entry_column">
                            <span>Gear 4: </span>
                            <KDHTMLInput type="text" id="gear_entry_3" />
                        </div>
                        <div className="gear_entry_column">
                            <span>Gear 5: </span>
                            <KDHTMLInput type="text" id="gear_entry_4" />
                        </div>
                        <div className="gear_entry_column">
                            <span>Gear 6: </span>
                            <KDHTMLInput type="text" id="gear_entry_5" />
                        </div>
                    </div>
                    <div className="gear_entry_row">
                        <div className="gear_entry_column">
                            <span>Gear 7: </span>
                            <KDHTMLInput type="text" id="gear_entry_6" />
                        </div>
                        <div className="gear_entry_column">
                            <span>Gear 8: </span>
                            <KDHTMLInput type="text" id="gear_entry_7" />
                        </div>
                        <div className="gear_entry_column">
                            <span>Gear 9: </span>
                            <KDHTMLInput type="text" id="gear_entry_8" />
                        </div>
                    </div>
                </div>

                <div id="notes_field">
                    <label htmlFor="notes">Notes</label>
                    <KDHTMLTextArea id="notes" name="notes"></KDHTMLTextArea>
                </div>
            </div>
        </StateValueWrapperProvider>
    );
}
