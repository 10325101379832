import styles from './sheet-lost-settlements.module.scss';
import sheetStyles from '../styles/sheet-styles.module.scss';
import KDHTMLProgress from '../../../../../../components/general/kd-html-input/KDHtmlProgress.component';
import KDHTMLCustomCheckbox from '../../../../../../components/general/kd-html-input/KDHtmlCustomCheckbox';

interface SheetLostSettlementsProps {
    idPrefix: string;
}

const SheetLostSettlement = ({ idPrefix }: SheetLostSettlementsProps) => {

    const boldSet = new Set<number>();
    boldSet.add(4);
    boldSet.add(9);
    boldSet.add(14);
    boldSet.add(19);
    return (
        <div className={styles.lostSettlementsContainer}>
            <div className={styles.header}>
                <p className={styles.headerTitle}>Lost Settlements</p>
            </div>
            <div className={styles.horizontalLine}></div>
            <div className={styles.lostSettlementsInnerContainer}>
                <KDHTMLProgress
                    count={20}
                    prefix={idPrefix}
                    item={(index, change, id) => {
                        return (
                            <KDHTMLCustomCheckbox
                                key={index}
                                containerClass={sheetStyles.checkboxContainer}
                                id={id}
                                onChange={(e) => {
                                    change(index, e);
                                }}
                                isBold={boldSet.has(index)}
                            />
                        );
                    }}
                />
            </div>
        </div>
    );
};

export default SheetLostSettlement;
