import { useEffect, useState } from 'react';
import renderFor from '../../../../../../utils/renderFor';
import styles from './survivor-epoch.module.scss';

export interface SurvivorEpochProps {
    onLanternYearChanged: (year: number) => void;
    availableYears: number[];
    currentYear: number;

    neighbours: number[];
    openSurvivor: (id: number) => void;
    id: number;
}

export default function SurvivorEpoch(props: SurvivorEpochProps) {
    const [newEpoch, setNewEpoch] = useState(0);
    function updateEpochValue() {
        for (let i = 0; i < 36; i++) {
            if (props.availableYears.indexOf(i + 1) === -1) {
                setNewEpoch(i + 1);
                break;
            }
        }
    }
    useEffect(() => {
        updateEpochValue();
    }, []);
    useEffect(() => {
        updateEpochValue();
    }, [props.currentYear]);
    useEffect(() => {
        updateEpochValue();
    }, [props.availableYears]);

    let totalCount = 0;

    const items = renderFor(0, 36, (index) => {
        if (props.availableYears.indexOf(index + 1) !== -1) {
            return undefined;
        }
        totalCount++;
        return <option value={index + 1}>{index + 1}</option>;
    });

    const getPreviousButtonDisable = () => {
        if (props.neighbours.length === 0) return true;
        if (props.neighbours[props.neighbours.length - 1] === props.id)
            return true;
        return false;
    };

    const getNextButtonDisable = () => {
        if (props.neighbours.length === 0) return true;
        if (props.neighbours[0] === props.id) return true;
        return false;
    };

    const previousButtonClick = (event: React.MouseEvent<HTMLInputElement>) => {
        event.preventDefault();
        props.openSurvivor(props.neighbours[props.neighbours.length - 1]);
    };

    const nextButtonClick = (event: React.MouseEvent<HTMLInputElement>) => {
        event.preventDefault();
        props.openSurvivor(props.neighbours[0]);
    };

    return (
        <div className={styles.generalContainer}>
            <div className={styles.titleContainer}>
                <p>Lantern Year</p>
                <p className={styles.year}>{props.currentYear}</p>
            </div>
            <div className={styles.epochSeparator}></div>
            <div className={styles.epochList}>
                <p className={styles.title}>Survivor's Epochs</p>
                <div className={styles.epochListInner}>
                    <div className={styles.epochInnerContainer}>
                        {props.availableYears
                            // .filter((x) => x !== props.currentYear)
                            .map((year) => {
                                return (
                                    <EpochListItem
                                        year={year}
                                        select={props.onLanternYearChanged}
                                    />
                                );
                            })}
                    </div>
                </div>
            </div>
            <div className={styles.epochSeparator} />
            <div className={styles.lanternYearChange}>
                <p>Create a new Epoch at the selected lantern year</p>
                <div className={styles.yearChangeContainer}>
                    <select
                        disabled={totalCount === 0}
                        value={newEpoch}
                        onChange={(e) => {
                            e.preventDefault();
                            setNewEpoch(parseInt(e.target.value));
                        }}
                    >
                        {items}
                    </select>
                    <button
                        disabled={totalCount === 0}
                        type={'button'}
                        onClick={(e) => {
                            e.preventDefault();
                            props.onLanternYearChanged(newEpoch);
                        }}
                    >
                        Create
                    </button>
                </div>
            </div>
            <div className={styles.navButtonContainer}>
                <input
                    type="button"
                    value="<< Previous"
                    id="previous-survivor"
                    disabled={getPreviousButtonDisable()}
                    onClick={previousButtonClick}
                />
                <input
                    type="button"
                    value="Next >>"
                    id="next-survivor"
                    disabled={getNextButtonDisable()}
                    onClick={nextButtonClick}
                />
            </div>
        </div>
    );
}

function EpochListItem({
    year,
    select,
}: {
    year: number;
    select: (year: number) => void;
}) {
    return (
        <button
            type="button"
            onClick={(e) => {
                e.preventDefault();
                select(year);
            }}
        >{`Lantern Year ${year}`}</button>
    );
}
