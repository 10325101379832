import styles from './sheet-death-count.module.scss';
import sheetStyles from '../styles/sheet-styles.module.scss';
import classes from '../../../../../../utils/classes';
import { DeathIcon } from '../../../../../../components/icons';
import KDHTMLProgress from '../../../../../../components/general/kd-html-input/KDHtmlProgress.component';
import KDHTMLCustomCheckbox from '../../../../../../components/general/kd-html-input/KDHtmlCustomCheckbox';

interface SheetDeathCountProps {
    layout?: 'default' | 'ark';
}
export const SheetDeathCount = ({
    layout = 'default',
}: SheetDeathCountProps) => {
    return (
        <div className={classes(styles.deathCounterContainer, styles[layout])}>
            <div className={styles.deathCountLabel}>
                <DeathIcon />
                <span>Death Count</span>
            </div>
            <div className={styles.deathCountLine} />

            <div
                className={styles.deathCountCheckboxWrapper}
                style={{
                    gridTemplateColumns:
                        layout === 'default'
                            ? 'repeat(19, max-content)'
                            : 'repeat(28, max-content)',
                }}
            >
                <KDHTMLProgress
                    count={layout === 'default' ? 38 : 28 * 2}
                    prefix="death_"
                    item={(index, change, id) => {
                        return (
                            <KDHTMLCustomCheckbox
                                isBold={layout === 'default' && index === 0}
                                containerClass={classes(
                                    sheetStyles.checkboxContainer
                                )}
                                key={id}
                                id={id}
                                name={id}
                                onChange={(e) => {
                                    change(index, e);
                                }}
                            />
                        );
                    }}
                />
            </div>
        </div>
    );
};
