import { inject, injectable } from 'inversify';
import IShopifyAddToCartService from '../../services/IShopifyAddToCartService';
import type { IWindowMessage } from '../../services/IWindowMessage';
import type ISelectedPledgeItemsService from '../../services/ISelectedPledgeItemsService';
import type IDiscountService from '../../services/IDiscountService';

@injectable()
export default class ShopifyAddToCartService
    implements IShopifyAddToCartService
{
    @inject('IWindowMessage')
    private readonly windowMessage!: IWindowMessage;

    @inject('ISelectedPledgeItemsService')
    private readonly selectedPledgeItemsService!: ISelectedPledgeItemsService;

    @inject('IDiscountService')
    private readonly discountService!: IDiscountService;

    constructor() {
        const interval = setInterval(() => {
            if (!!this.windowMessage) {
                clearInterval(interval);
                this.windowMessage.Handler.on((m) => {
                    console.log(m);
                    if (!m) {
                        return;
                    }
                    const data = m.data;
                    const action = data.action;
                    const type = data.type;
                    if (!!action && !!type) {
                        if (type !== 'shopify') {
                            return;
                        }

                        if (action === 'getPledges') {
                            this.discountService
                                .getDiscountCodes()
                                .then((dsc) => {
                                    if (!!m.source) {
                                        m.source.postMessage(
                                            {
                                                type: 'shopify',
                                                action: 'responsePledges',
                                                pledges:
                                                    this.selectedPledgeItemsService.getPledges(),
                                                discounts: dsc,
                                            },
                                            { targetOrigin: '*' }
                                        );
                                    }
                                });
                        }
                    }
                });
            }
        }, 100);
    }

    proceed(): void {
        window.open('https://shop.kingdomdeath.com/#proceedPledges');
    }
}
