interface SurvivorPageProps{
    page : number,
    isActive : boolean,
    pageChange : (page:number) => void
}

export default function SurvivorPage(
   props: SurvivorPageProps
) {
    if (props.isActive) {
        return (
            <a
                href="_self"
                className="active"
                style={{ pointerEvents: 'none', cursor: 'pointer' }}
            >
                {props.page}
            </a>
        )
    }
    return (
        <a
            href="_self"
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
                e.preventDefault()
                props.pageChange(props.page)
            }}
        >
            {props.page}
        </a>
    )
}
