import React, { useState } from 'react';
import styles from '../survivor-list.module.scss';
import classes from '../../../../../../utils/classes';
import conditionalClass from '../../../../../../utils/conditional-class';

interface SurvivorListEntryProps {
    isDead: boolean;
    survivor_name: string;
    gender: number;
    notes: string;
    id: number;
    lanternYear:number;
    openSurvivor: (id: number) => void;
}

export function SurvivorListEntryComponent(props: SurvivorListEntryProps) {
    const [hovered, setHovered] = useState(false);

    const getGender = (gender: number) => {
        if (gender === 1) return 'F';
        if (gender === 2) return '-';
        return 'M';
    };

    const buttonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        props.openSurvivor(props.id);
    };

    const mouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        setHovered(true);
    };

    const mouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        setHovered(false);
    };

    return (
        <div
            className={classes(
                styles.survivor_row,
                conditionalClass(props.isDead, styles.dead, undefined)
            )}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
        >
            <button
                onClick={buttonClick}
                className={styles.view_survivor_button}
                style={{ visibility: hovered ? 'visible' : 'hidden' }}
            />
            <span className={styles.survivor_name}>{`${props.survivor_name} (${props.lanternYear})`}</span>
            <span className={styles.survivor_gender}>
                {getGender(props.gender)}
            </span>
            <span className={styles.survivor_notes}>{props.notes}</span>
        </div>
    );
}
