import { useInjection } from 'inversify-react';
import IUIService from '../../../../../services/IUIService';
import { useEffect, useState } from 'react';
import { IAuthService } from '../../../../../services/IAuthService';
import IAuthManagementRESTClient from '../../../../../rest-clients/IAuthManagementRESTClient';
import { useNavigate } from 'react-router';

export default function ShopifyAuthorizeCode() {
    const uiService = useInjection<IUIService>('IUIService');
    const authService = useInjection<IAuthService>('IAuthService')
    const authManagementRESTClient = useInjection<IAuthManagementRESTClient>('IAuthManagementRESTClient')
    const navigate = useNavigate();
    
    const [message, setMessage] = useState<string | null>('Please do not close this page. It will be automatically closed.');

    useEffect(() => {
        uiService.showLoading();
        const urlSearchString = window.location.search;
        const params = new URLSearchParams(urlSearchString);

        const localCode = params.get('code');

        if(!localCode)
            return;

        authService.getToken().then(x=>{
            if(!x){
                uiService.hideLoading();
                setMessage('Failed to link Shopify account');
                return;
            }

            authManagementRESTClient.createLinkedAccount("shopify", {
                code : localCode
            }).then(value=>{
                uiService.hideLoading();

                if(!!value){
                    uiService.showSuccessNotification('Succesfully linked account.')
                    navigate('/account#account')
                }
                else{
                    uiService.showErrorNotification('Failed to link accounts.')
                }
            })
        })
    }, []);

    useEffect(() => {}, [message]);

    return (
        <div>
            {message}
        </div>
    );
}
