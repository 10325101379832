import classes from '../../utils/classes';
import LoginPanelHeader from '../login-panel-header/login-panel-header.component';
import './auth-panel-dialog-wrapper.styles.scss'

interface AuthPanelDialogWrapperProps {
    popupClass?: (string|undefined)[] | undefined;
    children: React.ReactNode | undefined;
    popupId? : string
}

export default function AuthPanelDialogWrapper(props: AuthPanelDialogWrapperProps) {
    const classLayedOut = props.popupClass?props.popupClass:[''];
    return (
        <div id="overlay-auth-wrapper">
            <div
                id={props.popupId}
                className={classes(
                    ...classLayedOut,
                    'auth-popup'
                )}
            >
                <div className="middle-container">
                    <LoginPanelHeader />
                    {props.children}
                </div>
            </div>
        </div>
    );
}
