import { useRef, useState } from 'react';
import SettlementHeader from './header/settlement-header.component';
import SettlementInfo from './settlement-info/settlement-info.component';
import CoreSettlementSheet from './sheets/core-settlement-sheet.component';
import { useInjection } from 'inversify-react';
import ArkSettlementSheet from './sheets/ark-settlement-sheet.component';
import { useReactToPrint } from 'react-to-print';
import POTStarSettlementSheet from './sheets/potstart-settlement-sheet.component';
import POTSunSettlementSheet from './sheets/potsun-settlement-sheet.component';
import CoreSettlementSheetState from '../../models/stateFragments/sheets/CoreSettlementSheetState';
import { SheetSide } from '../../../../models/SheetSide';
import IRecordSheetService from '../../services/IRecordSheetService';
import IUIService from '../../../../services/IUIService';
import { SettlementType } from '../../models/settlements/SettlementType';
import StateValueWrapper from '../../models/stateFragments/StateValueWrapper';

interface SettlementSheetContainerProps {
    currentValues: CoreSettlementSheetState;
    delete: () => void;
    updateValues: (values: CoreSettlementSheetState) => void;
    save: (values: CoreSettlementSheetState) => void;
    sheetSide: SheetSide;
    flipSheetSide: (newSide: SheetSide) => void;
    refresh: (forceRefresh: boolean) => void;
    saveEnabled: boolean;
    openSurvivor: (id: number) => void;
}

export default function SettlementSheetContainerDefault(
    props: SettlementSheetContainerProps
) {
    const dodService = useInjection<IRecordSheetService>('IRecordSheetService');

    const uiService = useInjection<IUIService>('IUIService');

    const [infoVisible, setInfoVisible] = useState(false);
    const [showPrintBtn, setShowPrintBtn] = useState(true);

    const printRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => printRef.current as any,
        pageStyle: '@page { size: 900px 1135px;}',
    });

    const { currentValues, updateValues } = props;

    const renderSettlementSheet = () => {
        const settlementType = parseInt(
            currentValues.values!.get('settlement_type')!
        );

        if (settlementType === SettlementType.Ark) {
            return renderArkSettlementSheet();
        }

        if (settlementType === SettlementType.Core) {
            return renderCoreSettlementSheet();
        }
        if (settlementType === SettlementType.POTStar) {
            return renderPotStarSettlementSheet();
        }

        if (settlementType === SettlementType.POTSun) {
            return renderPotSunSettlementSheet();
        }
    };

    const renderArkSettlementSheet = () => {
        const stateValueWrapper = new StateValueWrapper(
            currentValues,
            updateValues
        );

        return (
            <ArkSettlementSheet
                setShowPrintBtn={setShowPrintBtn}
                stateValueWrapper={stateValueWrapper}
                openSurvivor={props.openSurvivor}
                sheetSide={props.sheetSide}
                createSurvivor={onCreateSurvivor}
            />
        );
    };

    const renderCoreSettlementSheet = () => {
        const stateValueWrapper = new StateValueWrapper(
            currentValues,
            updateValues
        );
        return (
            <CoreSettlementSheet
                stateValueWrapper={stateValueWrapper}
                sheetSide={props.sheetSide}
                getInputValue={getInputValue}
                getInputValueWithDefault={getInputValueWithDefault}
                getCheckboxValue={getCheckboxValue}
                setCheckboxValue={setCheckboxValue}
                setCheckboxBulk={setCheckboxBulk}
                setInputValue={setInputValue}
                openSurvivor={props.openSurvivor}
                createSurvivor={onCreateSurvivor}
            />
        );
    };

    const renderPotStarSettlementSheet = () => {
        const stateValueWrapper = new StateValueWrapper(
            currentValues,
            updateValues
        );
        return (
            <POTStarSettlementSheet
                stateValueWrapper={stateValueWrapper}
                sheetSide={props.sheetSide}
                getInputValue={getInputValue}
                getInputValueWithDefault={getInputValueWithDefault}
                getCheckboxValue={getCheckboxValue}
                setCheckboxValue={setCheckboxValue}
                setCheckboxBulk={setCheckboxBulk}
                setInputValue={setInputValue}
                openSurvivor={props.openSurvivor}
                createSurvivor={onCreateSurvivor}
            />
        );
    };

    const renderPotSunSettlementSheet = () => {
        const stateValueWrapper = new StateValueWrapper(
            currentValues,
            updateValues
        );
        return (
            <POTSunSettlementSheet
                stateValueWrapper={stateValueWrapper}
                sheetSide={props.sheetSide}
                getInputValue={getInputValue}
                getInputValueWithDefault={getInputValueWithDefault}
                getCheckboxValue={getCheckboxValue}
                setCheckboxValue={setCheckboxValue}
                setCheckboxBulk={setCheckboxBulk}
                setInputValue={setInputValue}
                openSurvivor={props.openSurvivor}
                createSurvivor={onCreateSurvivor}
            />
        );
    };

    const onCreateSurvivor = async (name: string) => {
        const id = parseInt(getInputValue('id'));
        if (id === -1) return;
        const result = await dodService.createSurvivor(
            id,
            name,
            parseInt(currentValues.values!.get('settlement_type')!)
        );
        if (result) {
            props.refresh(false);
        } else {
            uiService.showErrorNotification('Failed to create a survivor');
        }
    };

    const getInputValue = (id: string): string => {
        return getInputValueWithDefault(id, '');
    };

    const getInputValueWithDefault = (
        id: string,
        defaultValue: string
    ): string => {
        const { values } = props.currentValues;
        if (values === null) return '';
        if (!values.has(id)) {
            values.set(id, defaultValue);
        }

        const value = values.get(id);
        if (value) return value;

        return '';
    };

    const getCheckboxValue = (id: string) => {
        const { checkmarks } = props.currentValues;
        if (checkmarks === null) return false;
        if (!checkmarks.has(id)) {
            checkmarks.set(id, false);
        }

        const value = checkmarks.get(id);
        if (value) return value;

        return false;
    };

    const setInputValue = (id: string, value: string) => {
        const { values } = props.currentValues;
        values?.set(id, value);
        updateCurrentValues(props.currentValues);
    };

    const setCheckboxValue = (id: string, value: boolean) => {
        const { checkmarks } = props.currentValues;
        checkmarks?.set(id, value);
        updateCurrentValues(props.currentValues);
    };

    const setCheckboxBulk = (checkmarkValues: Map<string, boolean>) => {
        const { checkmarks } = props.currentValues;

        checkmarkValues.forEach((value, key, map) => {
            checkmarks?.set(key, value);
        });

        updateCurrentValues(props.currentValues);
    };

    const updateCurrentValues = (newValues: CoreSettlementSheetState) => {
        props.updateValues(newValues);
    };

    const flipSheet = (newSide: number) => {
        props.flipSheetSide(newSide);
    };

    const save = () => {
        props.save(props.currentValues);
    };

    const renderInfo = () => {
        return (
            <SettlementInfo
                getCheckboxValue={getCheckboxValue}
                getInputValue={getInputValue}
                getInputValueWithDefault={getInputValueWithDefault}
                setCheckboxValue={setCheckboxValue}
                setInputValue={setInputValue}
                isVisible={infoVisible}
            />
        );
    };

    return (
        <form id="settlement-form" style={{ display: 'block' }}>
            <div
                style={{
                    textAlign: 'center',
                    width: '100%',
                }}
            >
                <h3>Settlement Record Sheet</h3>
                <SettlementHeader
                    showPrintBtn={true}
                    sheetId={parseInt(
                        currentValues.values!.get('settlement_type')!
                    )}
                    onPrintSettlement={handlePrint}
                    onDeleteSettlement={props.delete}
                    currentSide={props.sheetSide}
                    onFlipSettlement={flipSheet}
                    onInfoSettlement={() => {
                        setInfoVisible(!infoVisible);
                    }}
                    onSaveSettlement={save}
                    onRefreshSettlement={() => props.refresh(true)}
                    saveEnabled={props.saveEnabled}
                />
                {renderInfo()}
                <div ref={printRef}>{renderSettlementSheet()}</div>
            </div>
        </form>
    );
}
