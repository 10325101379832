import { injectable } from "inversify";
import INavigationDataService from "../INavigationDataService";
import navigationData from '../../navigation.json'

@injectable()
export default class NavigationDataService implements INavigationDataService{
    private components : RouteComponent[]
    private labels : RouteLabel[]
    private routeToComponentMap: Map<string, RouteComponent>
    
    public constructor(){
        this.components = []
        this.labels = []
        this.routeToComponentMap = new Map<string,RouteComponent>();

        const data = navigationData.data;

        data.forEach(datum=>{
            const component = {
                component : datum.component,
                route : datum.link
            };
            this.components.push(component)

            this.routeToComponentMap.set(
                datum.link,
                component
            )
            
            //@ts-ignore
            if(!!datum.hideInMenu){
                return;
            }
            this.labels.push({
                default : datum.default,
                level: datum.level,
                route: datum.link,
                title: datum.title??"",
                permanentTooltip : datum.permanentTooltip
            })
        })
    }

    getRouteComponent(link: string) : RouteComponent|undefined {
        if(this.routeToComponentMap.has(link)){
            return this.routeToComponentMap.get(link);
        }
        return undefined;
    }

    getRoutesComponents() : RouteComponent[]{
        return this.components;
    }

    getRouteLabels() : RouteLabel[]{
        return this.labels;
    }


}

export interface RouteComponent{
    route : string,
    component: string
}

export interface RouteLabel{
    route: string,
    default : boolean,
    level: number,
    title: string,
    permanentTooltip? : string | null;
}

