import KDHTMLInput from '../../../../../../components/general/kd-html-input/KDHtmlInput.component';
import renderFor from '../../../../../../utils/renderFor';
import styles from './sheet-notes.module.scss';

interface ISheetNoteProps {
    width?: string;
    lineCount?: number;
    cssClass? : string;
}

const SheetNotes = ({ width, lineCount, cssClass }: ISheetNoteProps) => {
    return (
        <div className={styles.notesContainer}>
            <div className={styles.header}>
                <p className={styles.headerTitle}>Notes</p>
            </div>

            <div className={styles.notesInnerContainer}>
                {renderFor(0, !!lineCount ? lineCount : 11, (index) => {
                    const id = `note_${index}`;
                    return (
                        <div
                            style={{ width: width }}
                            className={styles.horizontalLine}
                            key={index}
                        >
                            <KDHTMLInput
                                className={styles.noteInput}
                                type="text"
                                id={id}
                                name={id}
                                key={id}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default SheetNotes;
