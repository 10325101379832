import { resolve } from 'inversify-react'
import React from 'react'
import ExpansionList from '../../../../../models/expansions/ExpansionList';
import type IRecordSheetService from '../../../services/IRecordSheetService';
import ExpansionEntry from '../../../../../models/expansions/ExpansionEntry';

interface SettlementInfoProps {
    isVisible: boolean,
    getInputValue : (id:string)=>string,
    getInputValueWithDefault : (id:string, defaultValue : string)=>string,
    getCheckboxValue : (id:string)=>boolean,
    setInputValue : (id:string, value:string)=>void,
    setCheckboxValue : (id:string, value:boolean)=>void
}

interface SettlementInfoState{
    expansions : ExpansionList
}

export default class SettlementInfo extends React.Component<SettlementInfoProps, SettlementInfoState> {
    
    @resolve("IRecordSheetService")
    private readonly recordSheetService! : IRecordSheetService;
    
    constructor(props:SettlementInfoProps){
        super(props);
        this.state = {
            expansions : {
                expansions :[
                    {id:1, name:"Core Game"},
                    {id:2, name:"Lion God"},
                    {id:3, name:"Lonely Tree"},
                    {id:4, name: "Slenderman"},
                    {id:5, name:"Spidicules"},
                    {id:6, name:"Sunstalker"},
                    {id:7, name:"Manhunter"},
                    {id:8, name:"Lion Knight"},
                    {id:9, name:"Gorm"},
                    {id:10, name:"Flower Knight"},
                    {id:11, name:"Dung Beetle Knight"},
                    {id:12, name:"Dragon King"},
                    {id:13, name:"Green Knight Armor"},
                    {id:14, name:"Black Knight"},
                    {id:15, name:"Screaming God"},
                    {id:16, name:"White Gigalion"},
                    {id:17, name:"Gambler's Chest"}
                ]
            }
        }
    }

    checkmarkChanged = (event : React.ChangeEvent<HTMLInputElement>) =>{
        const {id, checked} = event.target;
        this.props.setCheckboxValue(id, checked);
    }

    inputChanged = (event : React.ChangeEvent<HTMLTextAreaElement>)=>{
        const {id, value} = event.target;
        this.props.setInputValue(id, value);
    }

    expansionEntry = (expansion: ExpansionEntry) => {
        const expansionId = `expansion_${expansion.id}`
        const expansionName = expansion.name
        return (
            <div key={`div_${expansionId}`} className="expansion_entry">
                <input type="checkbox" id={expansionId} checked={this.props.getCheckboxValue(expansionId)} onChange={this.checkmarkChanged}/>
                <label htmlFor={expansionId}>{expansionName}</label>
            </div>
        )
    }

    render(){
        const {isVisible} = this.props;
        const {expansions} = this.state;
        return (
            <div
                id="settlement_info"
                style={{ display: isVisible ? undefined : 'none' }}
            >
                <div id="expansions_field">
                    <label htmlFor="expansion_ids">Expansions</label>
                    <div id="expansion_ids">
                        {expansions === undefined
                            ? null
                            : expansions.expansions.map((expansion) => {
                                  return this.expansionEntry(expansion)
                              })}
                    </div>
                </div>
                <div id="description_field">
                    <label htmlFor="description">Description</label>
                    <textarea id="description" name="description" onChange={this.inputChanged} value={this.props.getInputValue("description")}></textarea>
                </div>
            </div>
        )
    }

   
}
