import { SVGProps } from 'react';

export const TimeLineIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox='0 0 170 350' width={7} height={12} {...props}>
        <path
            data-name="Color Fill 1"
            d="M0 0h170v40h-20v90h-10v10h-10v10h-10v10h-10v10h-10v10h10v10h10v10h10v20h20v90h20v40H0v-40h20v-90h10v-10h10v-10h20v-20h10v-20H60v-10H40v-10H30v-10H20V40H0V0Zm80 100v10h10v-10H80Zm0 160v10H70v10H60v20h10v10h30v-10h10v-20h-10v-10H90v-10H80Z"
            style={{
                fillRule: 'evenodd',
            }}
        />
    </svg>
);
