import styles from './customer-account.module.scss';
import modalStyles from '../modal/customer-modal.module.scss';
import CustomerModal from '../modal/customer-modal.component';
import { useInjection } from 'inversify-react';
import { IAuthService } from '../../../services/IAuthService';
import { useRef, useState } from 'react';
import IUIService from '../../../services/IUIService';
import { IAuthGateRESTClient } from '../../../rest-clients/IAuthGateRESTClient';
import classes from '../../../utils/classes';
import DownloadLauncherButton from '../../download-launcher-button/download-launcher-button.component';
import { useSearchParams } from 'react-router-dom';
import AccountLinking from './account-linking/account-linking.component';
import AdminOnly from '../../admin-only/AdminOnly.component';
import data from '../../../data/deployTime.json';
import LogOut from '../../../components/log-out/log-out.component';

export default function CustomerAccount() {
    const authService = useInjection<IAuthService>('IAuthService');
    const uiService = useInjection<IUIService>('IUIService');
    const authGateRESTClient = useInjection<IAuthGateRESTClient>(
        'IAuthGateRESTClient'
    );

    const [searchParams] = useSearchParams();

    const from = searchParams.get('from');

    const [iss, setISS] = useState('example@example.com');

    const [changingEmail, setChangingEmail] = useState('');

    const [editingEmail, setEditingEmail] = useState(false);

    const textBoxRef = useRef<HTMLInputElement | null>(null);

    authService.getISS().then((x) => {
        if (!!x) setISS(x);
    });
    const emailPanel = (
        <div className={classes(modalStyles.row, styles.accountRow)}>
            <div className={modalStyles.label}>Email</div>
            {!editingEmail && (
                <div className={modalStyles.content}>
                    <div className={styles.email}>{iss}</div>
                    <div
                        className={classes(modalStyles.last, styles.pswButton)}
                    >
                        <a
                            href="#change-email"
                            onClick={(e) => {
                                e.preventDefault();
                                setEditingEmail(true);
                                setChangingEmail(iss);
                                const interval = window.setInterval(() => {
                                    if (!!textBoxRef.current) {
                                        textBoxRef.current.select();
                                        window.clearInterval(interval);
                                    }
                                }, 100);
                            }}
                        >
                            Change Email
                        </a>
                    </div>
                </div>
            )}

            {!!editingEmail && (
                <div className={modalStyles.content}>
                    <div className={styles.email}>
                        <input
                            ref={textBoxRef}
                            type="text"
                            value={changingEmail}
                            onChange={(e) => {
                                e.preventDefault();
                                setChangingEmail(e.target.value);
                            }}
                        />
                    </div>
                    <div
                        className={classes(modalStyles.last, styles.pswButton)}
                    >
                        <a
                            href="#change-email"
                            onClick={(e) => {
                                e.preventDefault();
                                changeEmailClicked();
                            }}
                        >
                            Submit Email
                        </a>
                    </div>
                </div>
            )}
        </div>
    );

    async function changePasswordClicked() {
        uiService.showLoading();
        const message = await authGateRESTClient.reset(iss, null);
        uiService.hideLoading();

        if (message === null) {
            uiService.showSuccessNotification(
                'Reset instructions sent to your email!'
            );
        } else {
            uiService.showErrorNotification(message.value);
        }
    }

    async function changeEmailClicked() {
        setEditingEmail(false);
        const response = await uiService.showConfirmationDialog(
            `Are you sure you want to change your email to: ${changingEmail}`
        );

        if (!response) {
            return;
        }

        uiService.showLoading();
        const message = await authService.requestChangeEmail(changingEmail);

        if (message === null) {
            uiService.showSuccessNotification(
                'Request for email change submitted successfuly. Please check your email!'
            );
        } else {
            uiService.showErrorNotification(message.value);
        }

        uiService.hideLoading();
    }

    const passwordPanel = (
        <div className={classes(modalStyles.row, styles.passwordRow)}>
            <div className={modalStyles.label}>Password</div>
            <div className={modalStyles.content}>
                <div className={styles.password}>***************</div>
                <div className={classes(modalStyles.last, styles.pswButton)}>
                    <a
                        href="#change"
                        onClick={(e) => {
                            e.preventDefault();
                            changePasswordClicked();
                        }}
                    >
                        Change Password
                    </a>
                </div>
            </div>
        </div>
    );

    const logoutPanel = (
        <div className={classes(modalStyles.row, styles.passwordRow)}>
            <div className={modalStyles.label}></div>
            <div className={modalStyles.content}>
                <div className={styles.password}></div>
                <div className={classes(modalStyles.last, styles.pswButton)}>
                    <LogOut style={{ fontWeight: 'bold' }} />
                </div>
            </div>
        </div>
    );

    return (
        <div>
            {!!from && from === 'qrCode' ? (
                <>
                    <div className={modalStyles.header}>
                        <h3>QR Code</h3>
                    </div>
                    <CustomerModal>QR Code Activated</CustomerModal>
                </>
            ) : (
                ''
            )}
            <div className={classes(modalStyles.header, modalStyles.center)}>
                <h3>Account of Death</h3>
            </div>
            <div
                style={{ fontSize: '16px', marginTop: '20px' }}
                className={classes(modalStyles.header, modalStyles.center)}
            >
                Welcome to Account of Death!
            </div>
            <div
                style={{ fontSize: '16px', marginTop: '20px', marginBottom: '40px' }}
                className={classes(modalStyles.header, modalStyles.center)}
            >
                From here you can access your Kingdom Death: Simulator
                purchases, campaigns and if <br></br> granted access, use the
                beta Dark Treasury to manage your Kickstarter rewards and
                preorders.
            </div>
            <CustomerModal>
                <div className={styles.shopAccountStyles}>
                   <p>Looking for your Kingdom Death Shop Account?</p> 
                    <a style={{fontWeight:'bold'}} href="https://shop.kingdomdeath.com/account">
                        Login Here
                    </a>
                </div>
            </CustomerModal>
            <CustomerModal>
                <div className={modalStyles.rowContainer}>
                    {emailPanel}
                    {passwordPanel}
                    {logoutPanel}
                </div>
            </CustomerModal>
            
            <AccountLinking />

            <AdminOnly>
                <CustomerModal header="Deploy Time">{data.date}</CustomerModal>
            </AdminOnly>

            <DownloadLauncherButton />
        </div>
    );
}
