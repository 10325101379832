import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store'

interface ZoomedState {
    zoomed : boolean,
    path : string | null,
    sizeClass : string
}

const initialState: ZoomedState = {
    zoomed : false,
    path : '',
    sizeClass : 'small'
}

export interface ZoomPayload{
    path : string | null,
    sizeClass : string
}

export const zoomSlice = createSlice({
    name: 'zoom',
    initialState,
    reducers: {
        zoom: (state, action: PayloadAction<ZoomPayload>) => {
            state.zoomed = true;
            state.path = action.payload.path;
            state.sizeClass = action.payload.sizeClass;
        },
        hideZoom : (state, action : PayloadAction)=>{
            state.zoomed = false;
        }
    },
})

export const { zoom, hideZoom } = zoomSlice.actions

export const selectZoom = (state: RootState) => state.zoom

export default zoomSlice.reducer
