import styles from './customer-purchases.module.scss';
import modalStyles from '../modal/customer-modal.module.scss';
import CustomerModal from '../modal/customer-modal.component';
import ActivateKeyModal from '../activate-key-modal/activate-key-modal.component';
import classes from '../../../utils/classes';
import React from 'react';
import ProductKeyTitle from '../../../models/trans/ProductKeyNameModel';
import { resolve } from 'inversify-react';
import type ITransService from '../../../services/ITransService';
import type IUIService from '../../../services/IUIService';
import { CustomerCPPagesProps } from '../../../pages/customer-cp-page/CustomerCPPage';
import DownloadLauncherButton from 'components/download-launcher-button/download-launcher-button.component';

interface CustomerPurchasesState {
    productKeys: ProductKeyTitle[];
    products: string[];
}

export default class CustomerPurchases extends React.Component<
    CustomerCPPagesProps,
    CustomerPurchasesState
> {
    @resolve('ITransService')
    private transService!: ITransService;
    @resolve('IUIService')
    private uiService!: IUIService;

    constructor(props: CustomerCPPagesProps) {
        super(props);
        this.state = {
            productKeys: [],
            products: [],
        };
    }

    componentDidMount(): void {
        this.getData();
    }

    getData = async () => {
        this.uiService.showLoading();
        const productKeys = await this.transService.getProductKeys();
        const products = await this.transService.getActiveProducts();
        this.uiService.hideLoading();
        if (!!productKeys) {
            this.setState({
                ...this.state,
                productKeys: productKeys,
            });
        } else {
            this.uiService.showErrorNotification(
                'Failed to retrieve product keys!'
            );
        }

        if (!!products) {
            this.setState({
                ...this.state,
                products: products,
            });
        } else {
            this.uiService.showErrorNotification(
                'Failed to retrieve products!'
            );
        }
    };

    copyShareLinkButton = (key?: string) => {
        const copy = (
            <div>
                <button
                    className={styles.copyButton}
                    onClick={(e) => {
                        e.preventDefault();
                        const targetLink = `https://client.kingdomdeath.com/account?invite=${key}`;
                        navigator.clipboard.writeText(targetLink);
                        this.uiService.showSuccessNotification('Successfuly copied link to clipboard!');
                    }}
                >
                    Invite Friend
                </button>
            </div>
        );
        return copy;
    };

    copyButton = (key?: string) => {
        const copy = (
            <div>
                <button
                    className={styles.copyButton}
                    onClick={(e) => {
                        e.preventDefault();
                        navigator.clipboard.writeText(key!);
                    }}
                >
                    Copy
                </button>
            </div>
        );
        return copy;
    };

    giftCodeDisplay = (product: string, productKey: string, index: number) => {
        return (
            <div
                className={classes(modalStyles.row, styles.purchaseRow)}
                key={index}
            >
                <div className={modalStyles.label}>{product}</div>
                <div className={modalStyles.content}>
                    <div className={styles.key}>
                        <p>{productKey}</p>
                    </div>
                    <div className={modalStyles.last}>
                        {this.copyShareLinkButton(productKey)}
                        {this.copyButton(productKey)}
                    </div>
                </div>
            </div>
        );
    };

    purchaseDisplay = (product: string, index: number) => {
        let title = product;
        if (!product) title = 'Unknown!';
        return (
            <div
                className={classes(modalStyles.row, styles.purchaseRow)}
                key={index}
            >
                <div className={modalStyles.label}>{title}</div>
            </div>
        );
    };

    render() {
        const { productKeys, products } = this.state;
        const giftCodeList = productKeys.map((productKey, i) => {
            const { product_key, title } = productKey;
            return this.giftCodeDisplay(title, product_key, i);
        });

        const purchaseList = products.map((product, i) => {
            return this.purchaseDisplay(product, i);
        });

        const giftCodeDisplay =
            productKeys.length > 0 ? (
                giftCodeList
            ) : (
                <div className={classes(modalStyles.row, styles.purchaseRow)}>
                    <div className={modalStyles.label}>No gift codes!</div>
                </div>
            );

        const purchaseDisplay =
            products.length > 0 ? (
                purchaseList
            ) : (
                <div className={classes(modalStyles.row, styles.purchaseRow)}>
                    <div className={modalStyles.label}>No purchases!</div>
                </div>
            );
        return (
            <div>
                <div className={modalStyles.header}>
                    <h3>Purchase History</h3>
                </div>
                <ActivateKeyModal
                    productKeyValue={this.props.productKey}
                    refresh={() => this.getData()}
                />
                <CustomerModal header="Active Products">
                    <div className={modalStyles.rowContainer}>
                        {purchaseDisplay}
                    </div>
                </CustomerModal>
                <CustomerModal header="Gift Codes">
                    <div className={modalStyles.rowContainer}>
                        {giftCodeDisplay}
                    </div>
                </CustomerModal>
            </div>
        );
    }
}
