import styles from './ark-fourth-page.module.scss';
import baseStyles from '../styles/core-settlement.module.scss';
import { renderForEach } from '../../../../../../utils/renderFor';
import KDHTMLCustomCheckbox from '../../../../../../components/general/kd-html-input/KDHtmlCustomCheckbox';
import KDHTMLInput from '../../../../../../components/general/kd-html-input/KDHtmlInput.component';
import SheetParagraph from '../../../elements/sheets/sheet-paragraphs/sheet-paragraph.component';
import classes from '../../../../../../utils/classes';
import KDHTMLProgress from '../../../../../../components/general/kd-html-input/KDHtmlProgress.component';

interface CollectiveCognitionProps {
    cognitionRewards: string[];
}
const CollectiveCognition = ({
    cognitionRewards,
}: CollectiveCognitionProps) => {
    return (
        <div className={styles.cognitionContainer}>
            <div className={styles.header}>
                <div className={styles.headerTitle} style={{marginBottom: '-1px'}}>
                    Collective Cognition Rewards
                </div>
                <div className={styles.horizontalLine}></div>
            </div>
            {renderForEach(cognitionRewards, (reward, i) => (
                <div className={styles.reward}>
                    <KDHTMLCustomCheckbox
                        id={`rewards_checks_${i}`}
                        containerClass={baseStyles.checkboxContainer}
                    />
                    <span>{reward}</span>
                </div>
            ))}
        </div>
    );
};

const CollectiveCognitionSheetTitle = () => {
    return (
        <div className={styles.sheetTitleContainer}>
            <div className={styles.cognitionBox}>
                <KDHTMLInput
                    type="number"
                    className={styles.cognitionInput}
                    id="collective_cognition"
                    name="collective_cognition"
                    min="0"
                    max="99"
                />
            </div>
            <div className={styles.sheetTitleInnerContainer}>
                <div className={styles.sheetlabel}>
                    <span>Collective Cognition</span>
                    {/* <KDHTMLInput
                        type="text"
                        className={styles.settlementNameInput}
                        id="settlement_name"
                        name="settlement_name"
                    /> */}
                </div>
                <div className={styles.horizontalLine} />
                <p className={styles.helperText}>
                    The settlement's total CC is based on its victories.
                </p>
            </div>
        </div>
    );
};

const SettlementVictories = () => {
    const quarries = [
        {
            prologue: ['prologue_'],
            lvl1: ['lvl1_'],
            lvl2: ['lvl2_', 'lvl2_'],
            lvl3: ['lvl3_', 'lvl3_', 'lvl3_'],
            startFrom: 0,
        },
        {
            prologue: [],
            lvl1: ['lvl1_'],
            lvl2: ['lvl2_', 'lvl2_'],
            lvl3: ['lvl3_', 'lvl3_', 'lvl3_'],
            startFrom: 7,
        },
        {
            prologue: [],
            lvl1: ['lvl1_'],
            lvl2: ['lvl2_', 'lvl2_'],
            lvl3: ['lvl3_', 'lvl3_', 'lvl3_'],
            startFrom: 13,
        },
        {
            prologue: [],
            lvl1: ['lvl1_'],
            lvl2: ['lvl2_', 'lvl2_'],
            lvl3: ['lvl3_', 'lvl3_', 'lvl3_'],
            startFrom: 19,
        },
        {
            prologue: [],
            lvl1: ['lvl1_'],
            lvl2: ['lvl2_', 'lvl2_'],
            lvl3: ['lvl3_', 'lvl3_', 'lvl3_'],
            startFrom: 25,
        },
        {
            prologue: [],
            lvl1: ['lvl1_'],
            lvl2: ['lvl2_', 'lvl2_'],
            lvl3: ['lvl3_', 'lvl3_', 'lvl3_'],
            startFrom: 31,
        },
        {
            prologue: [],
            lvl1: ['lvl1_'],
            lvl2: ['lvl2_', 'lvl2_'],
            lvl3: ['lvl3_', 'lvl3_', 'lvl3_'],
            startFrom: 37,
        },
    ];
    const nemesis = [
        {
            lvl1: ['lvl1_'],
            lvl2: ['lvl2_'],
            lvl3: ['lvl3_'],
        },
        {
            lvl1: ['lvl1_'],
            lvl2: ['lvl2_'],
            lvl3: ['lvl3_'],
        },
        {
            lvl1: ['lvl1_'],
            lvl2: ['lvl2_'],
            lvl3: ['lvl3_'],
        },
        {
            lvl1: ['lvl1_'],
            lvl2: ['lvl2_'],
            lvl3: ['lvl3_'],
        },
    ];

    return (
        <div className={styles.settlementVictoriesContainer}>
            <div className={styles.settlementVictoriesTitle}>
                <div className={styles.settlementTitle}>
                    Settlement Victories
                </div>
                <div className={styles.settlementSubtitle}>
                    Write the name of each monster in your campaign. After a
                    victorious showdown, fill a box for the defeated monster's
                    level and update your total collective cognition
                </div>
            </div>

            <div className={styles.settlementQuarries}>
                <div className={styles.header}>
                    <div className={styles.headerInnerContainer}>
                        <div className={styles.headerTitle}>Quarry</div>
                        <div className={styles.columnLabels}>
                            <div>
                                Prologue<span>1 CC</span>
                            </div>
                            <div>
                                Lvl 1<span>1 CC</span>
                            </div>
                            <div>
                                Lvl 2<span>2 CC</span>
                            </div>
                            <div>
                                Lvl 3<span>3 CC</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.horizontalLine}></div>
                    <div className={styles.quarryContainer}>
                        <SheetParagraph
                            linesPerColumn={7}
                            totalLines={7}
                            lineHeight={15}
                            idPrefix="victory_quarry_"
                            helperText=""
                        />
                        <div className={styles.quarryRowContainer}>
                            {renderForEach(quarries, (quarry, quarryIndex) => (
                                <div
                                    key={quarryIndex}
                                    className={styles.quarryRow}
                                    id={quarryIndex.toString()}
                                >
                                    <div className={styles.quarryRowItem}>
                                        {quarry.prologue.map((prologue, i) => (
                                            <KDHTMLCustomCheckbox
                                                key={i}
                                                containerClass={classes(
                                                    baseStyles.checkboxContainer,
                                                    styles.quarryCheckBox
                                                )}
                                                id={`victory_quarry_check_${
                                                    quarry.startFrom + i
                                                }`}
                                            />
                                        ))}
                                    </div>
                                    <div className={styles.quarryRowItem}>
                                        {quarry.lvl1.map((lvl1, i) => (
                                            <KDHTMLCustomCheckbox
                                                key={i}
                                                containerClass={classes(
                                                    baseStyles.checkboxContainer,
                                                    styles.quarryCheckBox
                                                )}
                                                id={`victory_quarry_check_${
                                                    quarry.startFrom +
                                                    quarry.prologue.length +
                                                    i
                                                }`}
                                            />
                                        ))}
                                    </div>
                                    <div className={styles.quarryRowItem}>
                                        {quarry.lvl2.map((lvl2, i) => (
                                            <KDHTMLCustomCheckbox
                                                key={i}
                                                containerClass={classes(
                                                    baseStyles.checkboxContainer,
                                                    styles.quarryCheckBox
                                                )}
                                                id={`victory_quarry_check_${
                                                    quarry.startFrom +
                                                    quarry.prologue.length +
                                                    quarry.lvl1.length +
                                                    i
                                                }`}
                                            />
                                        ))}
                                    </div>
                                    <div className={styles.quarryRowItem}>
                                        {quarry.lvl3.map((lvl3, i) => (
                                            <KDHTMLCustomCheckbox
                                                key={i}
                                                containerClass={classes(
                                                    baseStyles.checkboxContainer,
                                                    styles.quarryCheckBox
                                                )}
                                                id={`victory_quarry_check_${
                                                    quarry.startFrom +
                                                    quarry.prologue.length +
                                                    quarry.lvl1.length +
                                                    quarry.lvl2.length +
                                                    i
                                                }`}
                                            />
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.nemesisContainer}>
                <div className={styles.header}>
                    <div className={styles.headerInnerContainer}>
                        <div className={styles.headerTitle}>Nemesis</div>
                        <div className={styles.columnLabels}>
                            <div>
                                Lvl 1<span>3 CC</span>
                            </div>
                            <div>
                                Lvl 2<span>3 CC</span>
                            </div>
                            <div>
                                Lvl 3<span>3 CC</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.horizontalLine}></div>
                    <div className={styles.quarryContainer}>
                        <SheetParagraph
                            linesPerColumn={4}
                            totalLines={4}
                            lineHeight={15}
                            idPrefix="victory_nemesis_"
                            helperText=""
                        />
                        <div className={styles.quarryRowContainer}>
                            {renderForEach(nemesis, (quarry, quarryIndex) => (
                                <div
                                    key={quarryIndex}
                                    className={styles.quarryRow}
                                >
                                    <div className={styles.quarryRowItem}>
                                        {quarry.lvl1.map((lvl1, i) => (
                                            <KDHTMLCustomCheckbox
                                                key={i}
                                                containerClass={classes(
                                                    baseStyles.checkboxContainer,
                                                    styles.quarryCheckBox
                                                )}
                                                id={`victory_nemesis_checks_${
                                                    quarryIndex * 3 + i
                                                }`}
                                            />
                                        ))}
                                    </div>
                                    <div className={styles.quarryRowItem}>
                                        {quarry.lvl2.map((lvl2, i) => (
                                            <KDHTMLCustomCheckbox
                                                key={i}
                                                containerClass={classes(
                                                    baseStyles.checkboxContainer,
                                                    styles.quarryCheckBox
                                                )}
                                                id={`victory_nemesis_checks_${
                                                    quarryIndex * 3 + i + 1
                                                }`}
                                            />
                                        ))}
                                    </div>
                                    <div className={styles.quarryRowItem}>
                                        {quarry.lvl3.map((lvl3, i) => (
                                            <KDHTMLCustomCheckbox
                                                key={i}
                                                containerClass={classes(
                                                    baseStyles.checkboxContainer,
                                                    styles.quarryCheckBox
                                                )}
                                                id={`victory_nemesis_checks_${
                                                    quarryIndex * 3 + i + 2
                                                }`}
                                            />
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const BoneEaterLevels = () => {
    const levels = {
        id: 'bone_eater_level_',
        text: 'Bone Eaters',
        entries: ['Lvl 1', 'Lvl 2', 'Lvl 3', 'Lvl 4'],
    };

    return (
        <div className={styles.boneEatersContainer}>
            <div>
                <span className={styles.title}>Bone Eaters</span>
            </div>
            <div className={styles.horizontalLine}></div>
            <div className={styles.boneEaterRow}>
                <p>{levels.text}</p>
                <div className={styles.boneEaterLevels}>
                    <KDHTMLProgress
                        count={4}
                        prefix={levels.id}
                        item={(index, change, id) => {
                            return (
                                <div className={styles.boneEaterLevel}>
                                    <KDHTMLCustomCheckbox
                                        containerClass={classes(
                                            styles.checkboxContainer
                                        )}
                                        key={id}
                                        id={id}
                                        name={id}
                                        onChange={(e) => {
                                            change(index, e);
                                        }}
                                    />
                                    <p>{levels.entries[index]}</p>
                                </div>
                            );
                        }}
                    />
                </div>
            </div>
            <div className={styles.narrowHorizontalLine}></div>
        </div>
    );
};

export default function ArkFourthPage() {
    const cognitionRewards = [
        '1 - Facets of Power',
        '2 - Pleasing Plating',
        '5 - Comprehensive Construction',
        '6 - Crimson Crocodile Cuisine',
        '8 - Communal Larder',
        '13 - Sated Enlightenment',
        '16 - Smog Singer Cuisine',
        '21 - Metabolic Improvements',
        '26 - Phoenix Cuisine',
        '30 - Shared Illumination',
        '36 - King Cuisine',
        '46 - Culinary Ingenuity',
    ];
    return (
        <div className={classes(baseStyles.pageContainer, styles.ark)}>
            <div className={baseStyles.innerContainer}>
                <div className={styles.upperHalf}>
                    <div>
                        <CollectiveCognitionSheetTitle />
                        <SettlementVictories />
                    </div>
                    <div>
                        <CollectiveCognition
                            cognitionRewards={cognitionRewards}
                        />
                        <BoneEaterLevels />
                    </div>
                </div>
                <div>
                    <div className={styles.philosophiesContainer}>
                        <div className={styles.header}>
                            <div className={styles.headerTitle}>
                                Philosophies
                            </div>
                            <div className={styles.horizontalLine}></div>
                        </div>
                        <SheetParagraph
                            idPrefix="philosophy_"
                            linesPerColumn={3}
                            totalLines={15}
                            lineHeight={22}
                            gap={15}
                            helperText=""
                        />
                    </div>
                    <div className={styles.knowledgeContainer}>
                        <div className={styles.header}>
                            <div className={styles.headerTitle}>Knowledge</div>
                            <div className={styles.horizontalLine}></div>
                        </div>
                        <SheetParagraph
                            idPrefix="knowledge_"
                            linesPerColumn={20}
                            totalLines={80}
                            lineHeight={22}
                            gap={15}
                            helperText=""
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
