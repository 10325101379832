import KDHTMLInput from '../../../../../../components/general/kd-html-input/KDHtmlInput.component';
import renderFor from '../../../../../../utils/renderFor';
import styles from './sheet-innovations.module.scss';

interface SheetInnovationsProps {
    idPrefix: string;
    firstLineLabel?: string;
}

const SheetInnovations = ({
    idPrefix,
    firstLineLabel,
}: SheetInnovationsProps) => {
    const renderedInnovations = renderFor(0, 21, (index) => {
        const id = `${idPrefix}${index}`;
        return (
            <div key={index} className={styles.innovationsHorizontalLine}>
                <KDHTMLInput
                    type="text"
                    key={id}
                    id={id}
                    name={id}
                    className={styles.innovationInput}
                />
            </div>
        );
    });

    return (
        <div className={styles.innovationsContainer}>
            <div className={styles.innovationsHeader}>
                <p className={styles.innovationsHeaderTitle}>Innovations</p>
                <p className={styles.innovationsHelper}>
                    The settlement's innovations (including weapon masteries).
                </p>
            </div>

            <div className={styles.innovationsInnerContainer}>
                <div className={styles.innovationsHorizontalLine}>
                    <span className={styles.firstLineLabel}>
                        {firstLineLabel}
                    </span>
                </div>

                {renderedInnovations}
            </div>
        </div>
    );
};

export default SheetInnovations;
