import { inject, injectable } from 'inversify';
import IRecordSheetService from '../IRecordSheetService';
import type { IRecordSheetRESTClient } from '../../rest-clients/IRecordSheetRESTClient';
import BaseSettlementSheetModel from '../../models/settlements/BaseSettlementSheetModel';
import { SettlementType } from '../../models/settlements/SettlementType';
import SettlementList from '../../models/settlements/SettlementList';
import { CoreSettlementSheetModel } from '../../models/settlements/CoreSettlementSheetModel';
import { ErrorMessage } from '../../../../models/ErrorMessage';
import SurvivorList from '../../models/survivors/SurvivorList';
import CoreSurvivor from '../../models/survivors/CoreSurvivor';
import { SurvivorType } from '../../models/survivors/SurvivorType';
import BaseSurvivor from '../../models/survivors/BaseSurvivor';

@injectable()
export default class RecordSheetService implements IRecordSheetService {
    @inject('IRecordSheetRESTClient')
    private recordSheetRESTClient!: IRecordSheetRESTClient;

    async getSettlementSheet(
        id: number
    ): Promise<BaseSettlementSheetModel | null> {
        return await this.recordSheetRESTClient.getSettlementSheet(id);
    }

    async createNewSettlement(
        name: string,
        type: SettlementType
    ): Promise<number | null> {
        return await this.recordSheetRESTClient.createNewSettlement(name, type);
    }

    async deleteSettlement(id: number): Promise<boolean> {
        return await this.recordSheetRESTClient.deleteSettlement(id);
    }

    async getSettlementList(page: number): Promise<SettlementList | null> {
        return await this.recordSheetRESTClient.getSettlementList(page);
    }

    async updateSettlementSheet(
        sheetModel: CoreSettlementSheetModel
    ): Promise<ErrorMessage> {
        return await this.recordSheetRESTClient.updateSettlementSheet(sheetModel);
    }

    async getSurvivorList(
        sheetId: number,
        page: number,
        from: number,
        to: number,
        itemsPerPage : number
    ): Promise<SurvivorList | null> {
        return await this.recordSheetRESTClient.getSurvivorList(
            sheetId,
            page,
            from,
            to,
            itemsPerPage
        );
    }

    async getSurvivorSheet(
        survivorId: number
    ): Promise<CoreSurvivor | null> {
        const coreSurvivor = await this.recordSheetRESTClient.getSurvivorSheet(
            survivorId
        );
        return coreSurvivor;
    }

    async createSurvivor(
        settlementId: number,
        survivorName: string,
        type: SurvivorType
    ): Promise<boolean> {
        return await this.recordSheetRESTClient.createSurvivor(
            settlementId,
            survivorName,
            type
        );
    }

    async deleteSurvivor(survivorId: number): Promise<boolean> {
        return await this.recordSheetRESTClient.deleteSurvivor(survivorId);
    }

    async updateSurvivor(survivor: BaseSurvivor): Promise<ErrorMessage> {
        return await this.recordSheetRESTClient.updateSurvivor(survivor);
    }

    async getSurvivorNeighbours(
        settlementId: number,
        survivorId: number,
        fromYear: number,
        toYear: number
    ): Promise<Array<number>> {
        return await this.recordSheetRESTClient.getSurvivorNeighbours(
            settlementId,
            survivorId,
            fromYear,
            toYear
        );
    }
}
