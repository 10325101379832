import React, { useState, useEffect } from 'react';
import { useInjection } from 'inversify-react';
import styles from './survivor-list.module.scss';
import thirdPageStyles from '../sheets/ark-settlement/ark-third-page.module.scss';
import baseStyles from '../sheets/styles/core-settlement.module.scss';

import SurvivorPagination from './survivor-pagination/survivor-pagination.component';
import { SurvivorListEntryComponent } from './survivor-list-entry/survivor-list-entry.component';
import SurvivorListArkEntryComponent from './survivor-list-entry/survivor-list-entry-ark.component';
import { SettlementType } from '../../../models/settlements/SettlementType';
import IRecordSheetService from '../../../services/IRecordSheetService';
import SurvivorList from '../../../models/survivors/SurvivorList';
import renderFor, { renderForEach } from '../../../../../utils/renderFor';
import classes from '../../../../../utils/classes';
import { TimeLineIcon } from '../../../../../components/icons';
import IRefreshHandlerService from '../../../services/IRefreshHandlerService';

interface SurvivorListProps {
    fromYear: number;
    toYear: number;
    sheetId: number;
    openSurvivor: (survivorId: number) => void;
    newSurvivorCount: number;
    cssModule?: { readonly [key: string]: string };
    settlementType: SettlementType;
}

export const SurvivorListComponent: React.FC<SurvivorListProps> = (props) => {
    const { fromYear, toYear, sheetId, newSurvivorCount, settlementType } =
        props;

    const dodService = useInjection<IRecordSheetService>('IRecordSheetService');
    const refreshHandlerService = useInjection<IRefreshHandlerService>('IRefreshHandlerService')

    const [page, setPage] = useState<number>(1);
    const [survivorList, setSurvivorList] = useState<SurvivorList | null>(null);

    const getData = async () => {
        const list = await dodService.getSurvivorList(
            sheetId,
            page,
            fromYear,
            toYear,
            settlementType===SettlementType.Ark?40:24
        );
        if (list !== null) {
            setSurvivorList(list);
        }
    };

    useEffect(() => {
        getData();
        refreshHandlerService.registerRefreshCallback(()=>{
            getData();
        })
    }, [fromYear, toYear, sheetId, page, newSurvivorCount]);

    console.log('survivorList', survivorList);
    if (survivorList === null) return null;

    const isArk = settlementType === SettlementType.Ark;

    if (!isArk) {
        return (
            <SurvivorListDefault
                {...props}
                page={page}
                setPage={setPage}
                survivorList={survivorList}
            />
        );
    }

    return (
        <SurvivorListArk
            {...props}
            page={page}
            setPage={setPage}
            survivorList={survivorList}
        />
    );
};

interface SurvivorListDefaultProps extends SurvivorListProps {
    survivorList: SurvivorList;
    page: number;
    setPage: (page: number) => void;
}

export function SurvivorListDefault({
    openSurvivor,
    survivorList,
    page,
    setPage,
}: SurvivorListDefaultProps) {
    const tableHeader = [
        {
            label: 'Name',
            style: styles.name,
        },
        { label: 'Gender', style: styles.gender },
        { label: 'Notes', style: styles.notes },
    ];

    const columns = [styles.first, styles.second];
    return (
        <div className={styles.survivorTable}>
            <div className={styles.survivorTableHeader}>
                {renderForEach(tableHeader, (element) => {
                    return (
                        <p
                            key={element.label}
                            className={classes(styles.label, element.style)}
                        >
                            {element.label}
                        </p>
                    );
                })}
            </div>

            <div className={styles.survivorTableInnerContainer}>
                <div
                    className={classes(
                        styles.survivorTableRowSeparator,
                        styles.first
                    )}
                ></div>
                {renderFor(0, 25, (index) => {
                    const survivor = survivorList.survivors[index];
                    return (
                        <div
                            key={index}
                            className={styles.survivorTableRowSeparator}
                        >
                            {survivor && (
                                <SurvivorListEntryComponent
                                    key={survivor.id}
                                    id={survivor.id}
                                    gender={survivor.survivor_sex}
                                    survivor_name={survivor.survivor_name}
                                    isDead={survivor.dead === 1}
                                    notes={survivor.notes}
                                    lanternYear={survivor.lantern_year}
                                    openSurvivor={openSurvivor}
                                />
                            )}
                        </div>
                    );
                })}
            </div>

            {renderForEach(columns, (element, index) => {
                return (
                    <div
                        key={index}
                        className={classes(
                            styles.surivorTableColumnSeparator,
                            element
                        )}
                    />
                );
            })}

            <SurvivorPagination
                page={page}
                updatePage={setPage}
                maxCount={survivorList?.max_results ?? 0}
            />
        </div>
    );
}

interface SurvivorListArkProps extends SurvivorListProps {
    survivorList: SurvivorList;
    page: number;
    setPage: (page: number) => void;
}

const SurvivorListArk = ({
    openSurvivor,
    survivorList,
    page,
    setPage,
}: SurvivorListArkProps) => {
    return (
        <div className={thirdPageStyles.survivorTable}>
            <div className={thirdPageStyles.header}>
                <div className={thirdPageStyles.survivorHeader}>
                    <div className={thirdPageStyles.survivorTitle}>
                        <TimeLineIcon />
                        <span className={thirdPageStyles.timeLineLabel}>
                            Survivors
                        </span>
                    </div>
                    <div className={thirdPageStyles.columnLabels}>
                        <span>Name</span>
                        <span>Sex</span>
                        <span>Hunt XP</span>
                        <span>Philosophy</span>
                        <span>Rank</span>
                        <span>Notes</span>
                        <span>Retired</span>
                        <span>Dead</span>
                    </div>
                    <div className={thirdPageStyles.horizontalLine}></div>
                </div>
            </div>

            <div className={thirdPageStyles.survivorBody}>
                {renderFor(0, 40, (i) => {
                    const data = survivorList.survivors[i]?survivorList.survivors[i]:undefined;
                    return <SurvivorListArkEntryComponent key={i} data={data} openSurvivor={openSurvivor}/>
                })}
            </div>

            <SurvivorPagination
                page={page}
                updatePage={setPage}
                maxCount={survivorList?.max_results ?? 0}
                itemsPerPage={40}
            />
        </div>
    );
};
