import { useInjection } from 'inversify-react';
import JWTAccessToken from '../../models/JWTAccessToken';
import { IAuthService } from '../../services/IAuthService';
import styles from './login-required-panel.module.scss';

export default function LoginRequiredPanel() {
    const authService = useInjection<IAuthService>('IAuthService');
    const link = 'https://auth.kingdomdeath.com/login';

    function navigateClick() {
        let newLink = link;

        if (!process.env.REACT_APP_LOCAL) {
            newLink += '?referred=true';
        }

        const openedWindow = window.open(newLink, '_blank');
        if (openedWindow) {
            window.addEventListener('message', (x) => {
                const { data } = x;
                if (!data) return;

                const { key } = data;
                if (key !== 'detailed') {
                    return;
                }

                const { value } = data;

                authService.setToken(value as JWTAccessToken);
            });
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <div className={styles.middleContainer}>
                    <span>Authentication required to access this page.</span>
                    <br />
                    <a
                        onClick={(e) => {
                            e.preventDefault();
                            navigateClick();
                        }}
                        href={link}
                    >
                        Login here!
                    </a>
                </div>
            </div>
        </div>
    );
}
