import { inject, injectable } from "inversify";
import DiscountRequestItem from "models/treasury/discount/DiscountRequestItem";
import DiscountRequestPayload from "models/treasury/discount/DiscountRequestPayload";
import type { IAuthService } from "services/IAuthService";
import IDiscountService from "services/IDiscountService";
import type ISelectedPledgeItemsService from "services/ISelectedPledgeItemsService";
import type ITreasuryService from "services/ITreasuryService";

@injectable()
export default class DiscountService implements IDiscountService{

    @inject('IAuthService')
    private readonly authService! : IAuthService;
    
    @inject('ITreasuryService')
    private readonly treasuryService! : ITreasuryService;

    @inject('ISelectedPledgeItemsService')
    private readonly selectedPledgeItemsService! : ISelectedPledgeItemsService;

    async getDiscountCodes(): Promise<string[]> {
        const iss = await this.authService.getISS();

        if(!iss)
            return [];

        const pledges = this.selectedPledgeItemsService.getPledges();

        if(pledges.length<=0)
            return [];

        const discount : DiscountRequestItem[] = pledges.map(x=>({
            variant_id : x.variantId,
            quantity : x.quantity
        }))

        const discountPayload : DiscountRequestPayload = {
            items : discount,
            wod_email : iss
        } 

        return await this.treasuryService.getDiscounts(discountPayload);
    }

}